import { Component } from "@angular/core";
import { CalendarDateFormatter } from "angular-calendar";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { CustomDateFormatter } from "../../../shared/components/calendar/custom-date-formatter.provider";
import { EditBookingDialogComponent } from "../dialog/edit-booking-dialog/edit-booking-dialog.component";
import { getDefaultCalendarSettings } from "@frontend/src/app/utils/calendar.util";

@Component({
	selector: "app-reservation-calendar",
	templateUrl: "./reservation-calendar.component.html",
	styleUrls: [
		"./reservation-calendar.component.scss",
		"../../../shared/components/calendar/calendar-styles.scss"
	],
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatter,
		},
	]
})
export class ReservationCalendarComponent {

	public refreshSubject: Subject<any> = new Subject();

	public calendarSettings = getDefaultCalendarSettings(2);

	constructor(private matDialog: MatDialog) {

	}

	public onCalendarEventClick = (event: any) => {
		this.matDialog.open(EditBookingDialogComponent, {
			data: {
				id: Number(event.event.id)
			}
		})
			.afterClosed()
			.subscribe((result) => {
				if (result)
					this.refreshSubject.next(true);
			});
	};
}
