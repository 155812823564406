import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
	ErrorStateMatcher,
	MAT_DATE_LOCALE,
	MatNativeDateModule,
	ShowOnDirtyErrorStateMatcher
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MAT_DIALOG_SCROLL_STRATEGY, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
	exports: [
		MatSnackBarModule,
		MatStepperModule,
		MatPaginatorModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressBarModule,
		MatGridListModule,
		MatTabsModule,
		MatMenuModule,
		MatSortModule,
		MatRadioModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatSortModule,
		MatTableModule,
		MatIconModule,
		MatSelectModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatDividerModule,
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "fr-CM" },
		{ provide: MatDialogRef, useValue: null },
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{
			provide: MAT_DIALOG_SCROLL_STRATEGY,
			useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
			deps: [ScrollStrategyOptions]
		},
		{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
	]
})
export class AppMaterialModule {
}