import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LogementApiResponse, UserProfileResponse } from "@app/model";
import { Router } from "@angular/router";
import { DEFAULT_ROOM_COVER_IMAGE } from "../../../../utils/file.utils";

@Component({
	selector: "app-room-card",
	templateUrl: "./room-card.component.html",
	styleUrl: "./room-card.component.scss"
})
export class RoomCardComponent {

    @Input({ required: true })
	public room!: LogementApiResponse;

    @Input({ required: true })
    public currentUser!: UserProfileResponse;

    @Output()
    public editEventEmitter: EventEmitter<LogementApiResponse> =
    		new EventEmitter<LogementApiResponse>();

    @Output()
    public deactivateEventEmitter: EventEmitter<LogementApiResponse> =
    		new EventEmitter<LogementApiResponse>();

    @Output()
    public deleteEventEmitter: EventEmitter<LogementApiResponse> =
    		new EventEmitter<LogementApiResponse>();

    public readonly DEFAULT_ROOM_COVER_IMAGE = DEFAULT_ROOM_COVER_IMAGE;

    public constructor(private router: Router) {
    }

    public onEmitEditEvent() {
    	this.editEventEmitter.emit(this.room);
    }

    public onEmitDeactivateEvent() {
    	this.deactivateEventEmitter.emit(this.room);
    }

    public onEmitDeleteEvent() {
    	this.deleteEventEmitter.emit(this.room);
    }

    public gotoDetail() {
    	this.router.navigate(["/feature/rooms", this.room.logementId]);
    }

    public getBase64PictureData(): string {
    	// Convertissez la liste de nombres en une chaîne de données Base64
    	const base64Image: string = String.fromCharCode.apply(null, this.room.coverPicture.data);

    	// Formatez l'URL sûre avec la chaîne de données Base64
    	return `data:image/jpeg;base64,${btoa(base64Image)}`;
    }
}
