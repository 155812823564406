import { Component, OnInit } from "@angular/core";
import { RoomService } from "../../../../services/room.service";
import { LogementApiResponse, LogementTypeEnum, PageableModel, UserProfileResponse } from "@app/model";
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { NewRoomDialogComponent } from "../dialog/new-room-dialog/new-room-dialog.component";
import { DialogService } from "../../../../services/dialog.service";
import { AuthService } from "../../../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LevelService } from "../../../../services/level.service";
import { LevelResponse } from "../../../../model/level.response";


@Component({
	selector: "app-rooms",
	templateUrl: "./rooms.component.html",
	styleUrl: "./rooms.component.scss"
})
export class RoomsComponent implements OnInit {
	get isLoading(): boolean {
		return this._isLoading;
	}

	get levels(): LevelResponse[] {
		return this._levels;
	}

	get currentUser(): UserProfileResponse {
		return this._currentUser;
	}

	public roomPage: PageableModel<LogementApiResponse>;

	public currentPage = 0;
	public pageSize: number = 12;
	private _currentUser: UserProfileResponse;
	public selectedLevel: number;
	public selectedType: LogementTypeEnum;
	public code: string;
	private _levels: LevelResponse[];
	private _isLoading: boolean;

	constructor(private logementService: RoomService,
				private authService: AuthService,
				private levelService: LevelService,
				private matDialog: MatDialog,
				private dialogService: DialogService,
				private activatedRoute: ActivatedRoute,
				private router: Router) {
	}

	ngOnInit(): void {
		this.authService.getCurrentUserFromApi().subscribe(_user => this._currentUser = _user);
		this.levelService.getLevelsSubs().subscribe(_levels => this._levels = _levels);

		this.activatedRoute.queryParamMap.subscribe(param => {
			this._isLoading = true;
			this.currentPage = Number(param.get("page")) || 0;
			this.pageSize = Number(param.get("size")) || 12;

			this.selectedType = (param.get("type") as LogementTypeEnum);
			this.selectedLevel = +param.get("level_id");
			this.code = param.get("code");

			this.logementService.getPageableRooms(this.currentPage, this.pageSize, this.selectedType, this.selectedLevel, this.code)
				.subscribe({
					next: (_data) => {
						this.roomPage = _data;
						this._isLoading = false;
					},
					error: () => this._isLoading = false
				});
		});
	}

	public handlePageEvent($event: PageEvent) {
		if ($event.pageIndex !== this.currentPage) {
			this.goToPage($event.pageIndex);
		} else {
			const queryParams = {
				...this.activatedRoute.snapshot.queryParams,
				size: $event.pageSize,
				page: 0
			};
			this.router.navigate([], { queryParams });
		}
	}

	public onOpenNewRoomDialog() {
		this.matDialog.open(NewRoomDialogComponent, {
			width: "600px"
		}).afterClosed().subscribe((data: LogementApiResponse) => {
			if (data) {
				this.goToPage(0);
			}
		});
	}

	public onOpenRoomEditDialog(room: LogementApiResponse) {
		this.matDialog.open(NewRoomDialogComponent, {
			data: room,
			width: "600px"
		}).afterClosed().subscribe((data: LogementApiResponse) => {
			if (data) {
				this.roomPage.content = this.roomPage.content
					.map(item => item.logementId === data.logementId ? data : item);
			}
		});
	}

	public onChangeRoomState($event: LogementApiResponse) {
		throw new Error("Not implemented yet " + $event.code);
	}

	public onDeleteRoom($event: LogementApiResponse) {
		this.dialogService.openConfirmDialog("Voulez vous vraiment supprimer logement N° " + $event.code)
			.afterClosed().subscribe((status: boolean) => {
			if (status) {
				this.goToPage(0);
			}
		});
	}

	public onSearch() {
		this.goToPage(0);
	}

	private formatQueryParams() {
		const queryParams = { ...this.activatedRoute.snapshot.queryParams };
		queryParams["page"] = this.currentPage;
		if (this.selectedLevel)
			queryParams["level_id"] = this.selectedLevel;
		else
			delete queryParams["level_id"];

		if (this.selectedType)
			queryParams["type"] = this.selectedType;
		else
			delete queryParams["type"];

		if (this.code)
			queryParams["code"] = this.code;
		else
			delete queryParams["code"];

		this.router.navigate([], { queryParams });
	}

	private goToPage(page: number) {
		this.currentPage = page;
		this.formatQueryParams();
	}
}
