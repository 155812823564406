<form #myForm="ngForm" [formGroup]="formGroup" mat-dialog-content class="g-3">

    <div class="row row-gap-2">
        <ng-container *ngFor="let control of formData.formControls">

            <div [class]="control.class"
                 *ngIf="['text', 'email', 'password', 'number'].includes(control.type) && control.isVisible">
                <mat-form-field appearance="outline">
                    <mat-label> {{ control.label }}</mat-label>
                    <input matInput [type]="control.type"
                           [placeholder]="control?.placeHolder" [formControlName]="control.controlName">

                    <mat-hint class="text-danger"
                              *ngIf="formGroup.get(control.controlName)?.invalid && formGroup.get(control.controlName)?.touched || myForm.submitted">
                        {{ getValidationMessage(control) }}
                    </mat-hint>
                </mat-form-field>
            </div>

            <div [class]="control.class"
                 *ngIf="control.type === ControlType.DATE">
                <mat-form-field appearance="outline">
                    <mat-label>{{ control.label }}</mat-label>
                    <input matInput [placeholder]="control?.placeHolder"
                           [formControlName]="control.controlName"
                           [matDatepicker]="datePicker">
                    <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-hint class="text-danger"
                              *ngIf="formGroup.get(control.controlName)?.invalid && formGroup.get(control.controlName)?.touched || myForm.submitted">
                        {{ getValidationMessage(control) }}
                    </mat-hint>
                </mat-form-field>
            </div>

            <div *ngIf="control.type === ControlType.SELECT && control.isVisible"
                 [class]="control.class">
                <mat-form-field appearance="outline">
                    <mat-label> {{ control.label }}</mat-label>

                    <mat-select [formControlName]="control.controlName">
                        <ng-container *ngFor="let option of control.options">
                            <mat-option [value]="option.id"> {{ option.value }}</mat-option>
                        </ng-container>
                    </mat-select>

                    <mat-hint class="text-danger"
                              *ngIf="formGroup.get(control.controlName)?.invalid && formGroup.get(control.controlName)?.touched || myForm.submitted">
                        {{ getValidationMessage(control) }}
                    </mat-hint>
                </mat-form-field>
            </div>

            <div *ngIf="control.type === ControlType.RADIO && control.isVisible" [class]="control.class">
                <label class="form-label">{{ control.label }}</label>

                <ng-container *ngFor="let row of control.options">
                    <div class="form-check">
                        <input [formControlName]="control.controlName" type="radio"
                               class="form-check-input"
                               [id]="control.controlName"
                               [value]="row.id">
                        <label class="form-check-label"
                               [for]="control.controlName"> {{ row }}</label>
                    </div>
                </ng-container>
                <small class="text-danger"
                       *ngIf="formGroup.get(control.controlName)?.invalid && formGroup.get(control.controlName)?.touched || myForm.submitted">
                    {{ getValidationMessage(control) }}
                </small>
            </div>

            <div *ngIf="control.type === ControlType.CHECKED && control.isVisible" [class]="control.class">
                <mat-checkbox [formControlName]="control.controlName"> {{ control.label }}</mat-checkbox>
            </div>

        </ng-container>
    </div>

</form>

<div mat-dialog-actions class="form-actions" align="center">
    <button mat-raised-button color="warn" type="button" (click)="onReset()">
        <mat-icon>restart_alt</mat-icon>
        {{ formData.resetBtnTitle }}
    </button>
    <button mat-raised-button type="submit" class="save-btn"
            (click)="myForm.onSubmit($event); onSubmit()">
        <mat-icon>save</mat-icon>
        {{ formData.saveBtnTitle }}
    </button>
</div>