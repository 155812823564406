import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@frontend/src/environments/environment";
import { ReservationFilter } from "@app/model";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})


export class ExportService {

	private readonly API_URL = environment.backendUrl;

	constructor(private httpClient: HttpClient) { }

	public export(filter: ReservationFilter): Observable<Blob> {
		return this.httpClient.get(`${this.API_URL}/api/v1/export/activity-synthesis`, {
			params: {
				...filter,
				start: filter.start.toDateString(),
				end: filter.end.toDateString()
			},
			responseType: "blob",
			observe: "body"
		});
	}
}
