import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HostingService } from "../../../../../services/hosting.service";
import { HostingResponseModel } from "@app/model";

@Component({
	selector: "app-reservation-detail",
	templateUrl: "./reservation-detail.component.html",
	styleUrl: "./reservation-detail.component.scss"
})
export class ReservationDetailComponent implements OnInit {
	public reservation: HostingResponseModel;

	constructor(private hostingService: HostingService,
				private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		const hostingId = this.activatedRoute.snapshot.paramMap.get("id");

		if (hostingId) {
			this.hostingService.getHostingById(+hostingId).subscribe({
				next: (reservation)=> {
					this.reservation = reservation;
				}
			});
		}
	}
}
