<div class="title-container">
    <h3><strong>{{ tableTitle }}</strong></h3>
    <button mat-button class="primary" (click)="handleExport()" *ngIf="reservations?.length">
        <mat-icon>print</mat-icon>
        Exporter
    </button>
</div>

<div class="overflow-hidden card table-nowrap table-card mt-1">

    <div class="table-responsive">
        <table class="table mb-0">
            <thead class="small text-uppercase bg-body text-muted">
            <tr>
                <th>N°</th>
                <th>Client</th>
                <th>Hébergement</th>
                <th>Période</th>
                <th>Montant</th>
                <th class="text-end">Action</th>
            </tr>
            </thead>
            <tbody>

            <tr class="align-middle" *ngFor="let item of reservations; let index = index">
                <td>{{ index + 1 }}</td>

                <td>{{ nameFormatter(item.customer.firstName, item.customer.lastName) | Textwrap: textWrapSize }}</td>

                <td>{{ item.occupationMode === "SIESTE" ? 'Sieste' : 'Nuitée' }} - {{ item.logement.code }}</td>

                <td>{{ item.beginingDate | date: 'dd/MM/yyyy' }} Au {{ item.endingDate | date: 'dd/MM/yyyy' }}</td>

                <td>
                    <span class="d-inline-block align-middle"> {{ item.totalAmount | number }}</span>
                </td>

                <td class="text-end">
                    <mat-icon class="primary-mat-button" [routerLink]="'/reservation/'+item.hostingId">print</mat-icon>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>