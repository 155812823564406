<div class="container-fluid mt-3">
    <div class="row align-items-center">
        <div class="col-md-3">
            <div class="mb-3">
                <h5 class="card-title">Liste des chambres
                    <span class="text-muted fw-normal ms-2">({{ roomPage?.totalRows }})</span>
                </h5>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row" >

                <div class="col-md-4">
                    <div class="form-group">
                        <input class="form-control"
                               [(ngModel)]="code"
                               placeholder="Nom / Code" />
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <select class="form-select" [(ngModel)]="selectedType">
                            <option value="" selected> -- Tout -- </option>
                            <option value="CHAMBRE">Chambre</option>
                            <option value="SUITE">SUITE</option>
                            <option value="SALLE_DE_FETE">Salle de fête</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-3" *ngIf="levels">
                    <div class="form-group">
                        <select [(ngModel)]="selectedLevel" class="form-select">
                            <option> -- Sélectionner -- </option>
                            <option [value]="item.id" *ngFor="let item of levels">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <button mat-icon-button class="primary-color"
                        (click)="onSearch()" type="button">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>

        <div class="col-md-3"
             *ngIf="currentUser?.roles?.includes('ADMIN') || currentUser?.roles?.includes('SUPERADMIN')">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2">
                <div>
                    <a (click)="onOpenNewRoomDialog()" class="btn add-btn d-flex justify-items-center text-white">
                        <mat-icon class="me-1">add</mat-icon>
                        Ajouter
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="roomPage?.content?.length">
        <div class="row mt-2">
            <div class="col-xl-4 col-sm-6" *ngFor="let room of roomPage.content">
                <app-room-card [room]="room"
                               [currentUser]="currentUser"
                               (editEventEmitter)="onOpenRoomEditDialog($event)"
                               (deactivateEventEmitter)="onChangeRoomState($event)"
                               (deleteEventEmitter)="onDeleteRoom($event)">
                </app-room-card>
            </div>
        </div>
        <div class="row g-0 align-items-center">
            <div class="col-sm-6">
                <div>
                    <p class="mb-sm-0">Affichage de {{ roomPage.page + 1 }} à {{ roomPage.content.length }}
                        sur {{ roomPage.totalRows }}</p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="float-sm-end">
                    <mat-paginator [length]="roomPage.totalRows"
                                   [pageSize]="pageSize"
                                   [pageSizeOptions]="[12, 24, 36, 48]"
                                   (page)="handlePageEvent($event)"
                                   [showFirstLastButtons]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="loader-container" *ngIf="isLoading">
    <app-spinner size="10em"></app-spinner>
</div>
