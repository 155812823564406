import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthenticationGuard {

	constructor(private authService: AuthService, private router: Router) {

	}

	async canActivate(routeSnapShot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return await this.isAuthenticated(routeSnapShot);
	}

	private async isAuthenticated(routeSnapShot: ActivatedRouteSnapshot): Promise<boolean> {
		if (!this.authService.isAuthenticated()) {
			this.router.navigate(["/login"]);
			return false;
		}

		const requiredRoles = routeSnapShot.data["roles"];

		if (!Array.isArray(requiredRoles) || requiredRoles.length === 0)
			return true;

		const user = this.authService.currentUser || await firstValueFrom(this.authService.getCurrentUserFromApi());
		return !!requiredRoles.find((role) => user.roles.includes(role));
	}

}