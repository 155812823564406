<div class="card">
    <a (click)="$event.preventDefault()">
        <div class="price primary"> {{ room.defaultPrice | number }} / Nuitée</div>
        <div class="image"
             [ngStyle]="{'background-image': 'url(' + DEFAULT_ROOM_COVER_IMAGE + ')'}">
        </div>

        <!--        <div class="image"-->
        <!--             [ngStyle]="{'background-image': room.coverPicture?.data?.length ? 'url(' + getBase64PictureData() + ')' : 'url(' + DEFAULT_ROOM_COVER_IMAGE + ')'}">-->
        <!--        </div>-->

        <div class="description">
            <div class="row">
                <div class="col-sm-3">
                    <h4> {{ room.code }} </h4>
                </div>
                <div class="col-sm-9">
                    <ul>
                        <li>
                            <mat-icon>person</mat-icon>
                            <p>{{ room.numberOfPersons }} Personne{{ room.numberOfPersons > 1 ? 's' : '' }}</p>
                        </li>
                        <li>
                            <mat-icon class="material-symbols-outlined">
                                {{ room.wifiAvailable ? 'wifi' : 'wifi_off' }}
                            </mat-icon>
                            <p> WI-FI Gratuit </p>
                        </li>
                        <li>
                            <mat-icon>soup_kitchen</mat-icon>
                            <p>Cuisine</p>
                        </li>
                        <li>
                            <mat-icon>bathtub</mat-icon>
                            <p>Salle de bain</p>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="actions cursor-pointer">
            <mat-icon class="clickable" color=accent
                      (click)="onEmitEditEvent()"
                      *ngIf="currentUser?.roles?.includes('ADMIN') || currentUser?.roles?.includes('SUPERADMIN')">
                edit
            </mat-icon>

            <mat-icon class="clickable" color="accent"
                      (click)="onEmitDeactivateEvent()"
                      *ngIf="currentUser?.roles?.includes('SUPERADMIN')">
                {{ room.isActive ? 'hide_source' : 'check' }}
            </mat-icon>

            <mat-icon class="clickable" color="warn"
                      (click)="onEmitDeleteEvent()"
                      *ngIf="currentUser?.roles?.includes('SUPERADMIN')">
                delete
            </mat-icon>

            <mat-icon class="clickable" color="accent"
                      (click)="gotoDetail()">visibility
            </mat-icon>
        </div>
    </a>
</div>