import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "../interceptor/token.inteceptor";
import { AuthService } from "../services/auth.service";
import { TextwrapPipe } from "@frontend/src/app/pipes/textwrap.pipe";


@NgModule({
	declarations: [TextwrapPipe],
	imports: [
		HttpClientModule
	],
	providers: [
		AuthService,
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		// {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
	],
	exports: [
		TextwrapPipe
	]
})
export class CoreModule {
}
