<div class="dialog-title">
    <div class="title">
        <h1 mat-dialog-title>Details de la reservation</h1>
    </div>

    <div class="close">
        <button [mat-dialog-close]="false" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content class="content">
    <div class="row">
        <label class="key">Montant ht</label>
        <label class="value">{{ computeResponseModel.amount | number }}</label>
    </div>

    <div class="row">
        <label class="key">Taux Tva</label>
        <label class="value">{{ computeResponseModel.vtaRate }} %</label>
    </div>

    <div class="row">
        <label class="key">Montant tva</label>
        <label class="value">{{ computeResponseModel.vtaAmount | number }}</label>
    </div>

    <div class="row">
        <label class="key">Taux de réduction</label>
        <label class="value">{{ computeResponseModel.discountRate }} %</label>
    </div>

    <div class="row">
        <label class="key">Montant de réduction</label>
        <label class="value">{{ computeResponseModel.discountAmount | number : '0.00' }}</label>
    </div>

    <div class="row">
        <label class="key">Montant ttc</label>
        <label class="value">{{ computeResponseModel.totalAmount | number }}</label>
    </div>
</div>

<div mat-dialog-actions class="form-actions" align="center">
    <button mat-raised-button color="warn" [mat-dialog-close]="false">
        <mat-icon>cancel</mat-icon>
        Annuler
    </button>

    <button mat-raised-button color="primary" [mat-dialog-close]="true">
        <mat-icon>task_alt</mat-icon>
        Valider
    </button>
</div>