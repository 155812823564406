import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { RoomService } from "../../../../services/room.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LogementApiResponse, RoomTypeEnum } from "@app/model";
import * as moment from "moment/moment";

@Component({
	selector: "app-select-free-room-dialog",
	templateUrl: "./select-free-room-dialog.component.html",
	styleUrl: "./select-free-room-dialog.component.scss"
})
export class SelectFreeRoomDialogComponent {

	public startDateControl!: FormControl<string>;
	public endDateControl!: FormControl<string>;

	public loadgingType: FormControl = new FormControl<RoomTypeEnum>(RoomTypeEnum.CHAMBRE, [Validators.required]);

	public readonly LoadgingTypeEnum = RoomTypeEnum;

	public logements: LogementApiResponse[] = [];

	constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    beginingDate: string,
                    endingDate: string,
                    mode: "SIESTE" | "NORMAL"
                },
                private roomService: RoomService,
                private matSnackBar: MatSnackBar) {
		this.startDateControl = new FormControl<string>(this.data.beginingDate, [Validators.required]);
		this.endDateControl = new FormControl<string>(this.data.endingDate, [Validators.required]);
	}

	public search() {
		if (!this.isDateValid()) {
			this.notifyError("Les dates sont incorrectes");
			return;
		}
		this.roomService.getAllFreesLogement(this.startDateControl.value, this.endDateControl.value, this.loadgingType.value).subscribe({
			next: (_logements) => {
				this.logements = _logements;
			},
			error: (error: any) => {
				this.matSnackBar.open(error.error.message, undefined, { duration: 2500, panelClass: "snack-bar-error" });
			}
		});
	}

	public isDateValid(): boolean {
		return moment(this.endDateControl.value).isAfter(this.startDateControl.value);
	}

	public onResetData() {
		this.logements = [];
		if (!this.isDateValid())
			this.notifyError("Les dates sont incorrectes");
	}

	private notifyError(errorMessage: string, duration?: number) {
		this.matSnackBar.open(errorMessage, undefined, {
			panelClass: "snack-bar-error",
			duration: duration || 5000
		});
	}

	public onClearData() {
		this.logements = [];
	}
}
