<h1 mat-dialog-title>Connexion super administrateur</h1>
<div mat-dialog-content>
    <ul class="list-group">
        <li *ngFor="let hotel of hotels, let index = index" [mat-dialog-close]="hotel.hotelId" class="list-group-item d-flex justify-content-between align-items-center">
            {{hotel.name}}
            <span class="badge bg-primary rounded-pill">{{ index+1 }}</span>
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>

