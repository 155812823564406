import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginResponse } from "../../../model/login.response";
import { MatDialog } from "@angular/material/dialog";
import { storeDataSession } from "../../../utils/storage.util";
import { Observable, of } from "rxjs";
import { DataState } from "../../../enum/datastate.enum";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
	ResetPasswordDialogComponent
} from "@frontend/src/app/components/auth/dialog/reset-password-dialog/reset-password-dialog.component";
import {
	SelectHotelDialogComponent
} from "@frontend/src/app/components/auth/dialog/select-hotel-dialog/select-hotel-dialog.component";
import { LoginState } from "@frontend/src/app/interface/login.state";
import { AuthService } from "@frontend/src/app/services/auth.service";
import { environment } from "@frontend/src/environments/environment";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit {

	public loginState$: Observable<LoginState> = of({ dataState: DataState.LOADED });
	readonly DataState = DataState;

	public loginForm!: FormGroup;
	private hotelId?: number;

	constructor(private formBuilder: FormBuilder,
                private dialog: MatDialog,
                private authService: AuthService,
                private router: Router,
                private matSnackBar: MatSnackBar) {
	}

	ngOnInit(): void {
		if (this.authService.isAuthenticated())
			this.router.navigate(["/feature"]);
		else
			this.router.navigate(["/login"]);
		this.initForm();
	}

	private initForm() {
		this.loginForm = this.formBuilder.group({
			email: [{ value: "", disabled: false }, [Validators.required, Validators.email]],
			password: [{ value: "", disabled: false }, [Validators.required]]
		});
	}

	public onSubmit() {
		this.authService.login({ ...this.loginForm.value, hotelId: this.hotelId })
			.subscribe({
				next: (response: LoginResponse) => {
					switch (response.type) {
						case "USER_AUTH": {
							storeDataSession(environment.hotelId, this.hotelId);
							this.router.navigateByUrl("feature");
							break;
						}
						case "SUPER_ADMIN_CHOOSE":
							this.dialog.open(SelectHotelDialogComponent, {
								disableClose: true,
								maxHeight: "70vh",
								data: response.hotels
							}).afterClosed()
								.subscribe((selectHotelId: number) => {
									if (!selectHotelId)
										return;
									this.hotelId = selectHotelId;
									this.onSubmit();
								});
							break;
					}
				},
				error: (error) => {
					this.matSnackBar.open(error.error.message, undefined, {
						duration: 3000,
						panelClass: "snack-bar-error"
					});
				}
			});
	}

	public onResetPasswordClick() {
		this.dialog.open(ResetPasswordDialogComponent, { width: "400px" });
	}
}
