<div class="dialog-title">
    <div class="title">
        <h1 mat-dialog-title>DETAIL DE LA RESERVATIONS</h1>
    </div>

    <div class="close">
        <button [mat-dialog-close]="true" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="dialog-container" *ngIf="booking">
    <div class="title" *ngIf="currentUser">
        <div>{{ currentUser?.currentHotel?.name }}</div>
        <div>{{ currentUser?.currentHotel?.name }}</div>
        <div>{{ currentUser?.currentHotel?.name }}</div>
        <div>{{ currentUser?.currentHotel?.name }}</div>
    </div>

    <mat-divider></mat-divider>

    <div class="booking-row">Détails de la reservation</div>
    <div class="booking-row">
        <div class="detail">
            <div class="label">Du</div>
            <div class="value">{{ booking.beginingDate | date : 'dd/MM/yyyy hh:mm' }}</div>
        </div>

        <div class="detail">
            <div class="label">Au</div>
            <div class="value">{{ booking.endingDate | date : 'dd/MM/yyyy hh:mm' }}</div>
        </div>

        <div class="detail">
            <div class="label">Durée</div>
            <div class="value" *ngIf="booking.occupationMode === 'NORMAL'; else siesteTemplate">
                {{booking.numberOfDays}} Nuitée{{(booking.numberOfDays > 1) ? 's' : '' }}
            </div>

            <ng-template #siesteTemplate>
                <div class="value">
                   {{booking.numberOfDays}} Sieste de ({{ durationInHours() + ' Heures' }})
                </div>
            </ng-template>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="booking-row">Reservé par</div>
    <div class="booking-row">
        <div class="booker">
            <div class="row">
                <div class="col-md-6">{{ booking.customer.lastName + " " + booking.customer.firstName }}</div>
                <div class="col-md-2">Status</div>
                <div class="col-md-2">{{ booking.status }}</div>
            </div>
            <div class="row">
                <div class="col-md-6">{{ booking.customer.email }}</div>
                <div class="col-md-2">Booking#</div>
                <div class="col-md-2">{{ booking.status }}</div>
            </div>

            <div class="row">
                <div class="col-md-6">{{ booking.customer.contact }}</div>
                <div class="col-md-2">Booking#</div>
                <div class="col-md-2">{{ booking.status }}</div>
            </div>
        </div>
    </div>

    <div class="booking-row">Details</div>
    <mat-divider></mat-divider>

    <div class="booking-row">
        <div class="booker">
            <div class="row">
                <div class="col-md-3">Montant ht</div>
                <div class="col-md-3">{{ booking.amount | number }}</div>
                <div class="col-md-3">Réduction %</div>
                <div class="col-md-3">{{ booking.discountRate }}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Montant Réduction</div>
                <div class="col-md-3">{{ booking.discountAmount }}</div>
                <div class="col-md-3">Tva %</div>
                <div class="col-md-3">{{ booking.vtaRate }}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Montant TVA</div>
                <div class="col-md-3">{{ booking.vtaRate }}</div>
                <div class="col-md-3">Total %</div>
                <div class="col-md-3">{{ booking.totalAmount | number }}</div>
            </div>
        </div>
    </div>

    <mat-divider></mat-divider>
</mat-dialog-content>

<div mat-dialog-actions align="center" *ngIf="booking">
    <button mat-raised-button color="primary"
            *ngIf="canValidate()"
            (click)="onValidateBooking()">
        <mat-icon>task_alt</mat-icon>
        Confirmer
    </button>

    <button mat-raised-button color="warn"
            *ngIf="canCancel()"
            (click)="onCancelBooking()">
        <mat-icon>cancel</mat-icon>
        Annuler
    </button>

    <button mat-raised-button color="warn"
            *ngIf="canDelete() && this.currentUser.roles.includes('SUPERADMIN')"
            (click)="onDeleteReservation()">
        <mat-icon>delete</mat-icon>
        Supprimer
    </button>

    <button mat-raised-button color="primary"
            (click)="goToReservationDetail()">
        <mat-icon>print</mat-icon>
        Imprimer
    </button>
</div>
