import { HostingResponseModel } from "@app/model";
import * as moment from "moment";
import { FULL_DATE_PATTERN_FOR_MOMENT } from "@app/util";

export function groupCharDataByDate(data: HostingResponseModel[]): { dates: string[], amounts: number[] } {

	const formatDate = (date: string | Date) =>  moment(new Date(date)).format(FULL_DATE_PATTERN_FOR_MOMENT);

	const dateMap: { [key: string]: number } = {};
	data.forEach(item => {
		const formattedDate = formatDate(item.confirmationDate);
		if (!dateMap[formattedDate]) {
			dateMap[formattedDate] = 0;
		}
		dateMap[formattedDate] += item.totalAmount;
	});

	const dates = Object.keys(dateMap).sort((a, b) => {
		return new Date(a).getTime() - new Date(b).getTime();
	});

	// Map the sorted dates to their corresponding amounts
	const amounts = dates.map(date => dateMap[date]);

	return { dates, amounts };
}

export function generateDashboardColors(numColors: number): string[] {
	const colors: string[] = [];

	for (let i = 0; i < numColors; i++) {
		const hue = i * (360 / (1 + Math.random() * 3) + numColors); // Spread the hues evenly
		const saturation = 75; // Fixed saturation to keep colors vibrant
		const lightness = 50; // Fixed lightness to keep colors vibrant

		const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
		colors.push(color);
	}

	return colors;
}
