<div class="spinner-container">
    <div id="spinner" [ngStyle]="{ width: size, height: size }">
        <svg viewBox="0 0 100 100">
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="0" stdDeviation="1.5"
                                  flood-color="#D9D9D9"/>
                </filter>
            </defs>
            <circle id="spin" cx="50" cy="50" r="45" stroke-width="40"/>
        </svg>
    </div>
</div>
