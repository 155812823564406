<div class="row text-center">

    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-primary"
                 (click)="setView(CalendarViewEnum.Month)"
                 [class.active]="currentView === CalendarViewEnum.Month"
                 [class.disabled]="currentView === CalendarViewEnum.Month">
                Mois
            </div>
            <div class="btn btn-primary"
                 (click)="setView(CalendarViewEnum.Week)"
                 [class.active]="currentView === CalendarViewEnum.Week"
                 [class.disabled]="currentView === CalendarViewEnum.Week">
                Semaine
            </div>
            <div class="btn btn-primary"
                 (click)="setView(CalendarViewEnum.Day)"
                 [class.active]="currentView === CalendarViewEnum.Day"
                 [class.disabled]="currentView === CalendarViewEnum.Day">
                Jour
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <h2 class="current-date-view">{{ viewDate | calendarDate:(currentView + 'ViewTitle'):'fr' }}</h2>
    </div>

    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-primary previous"
                 mwlCalendarPreviousView
                 [view]="currentView"
                 [(viewDate)]="viewDate"
                 (viewDateChange)="closeOpenMonthViewDay()">
                <mat-icon>skip_previous</mat-icon>
                Précédent
            </div>

            <ng-container [ngSwitch]="currentView">
                <div class="btn now" mwlCalendarToday [(viewDate)]="viewDate"
                     *ngSwitchCase="CalendarViewEnum.Week"
                     [ngClass]="{ 'disabled' : isLoading }">
                    Cette semaine
                </div>
                <div class="btn now" mwlCalendarToday [(viewDate)]="viewDate"
                     *ngSwitchCase="CalendarViewEnum.Day"
                     [ngClass]="{ 'disabled': isLoading }">
                    Aujourd'hui
                </div>
                <div class="btn now" mwlCalendarToday [(viewDate)]="viewDate"
                     *ngSwitchCase="CalendarViewEnum.Month"
                     [ngClass]="{ 'disabled': isLoading }">
                    Ce mois
                </div>
            </ng-container>

            <div class="btn btn-primary next"
                 mwlCalendarNextView
                 [view]="currentView"
                 [(viewDate)]="viewDate"
                 (viewDateChange)="closeOpenMonthViewDay()">
                Suivant
                <mat-icon>skip_next</mat-icon>
            </div>
        </div>
    </div>
</div>
