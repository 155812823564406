import { Component, OnInit } from "@angular/core";
import { HotelResponse, UserProfileResponse } from "@app/model";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { NgForm } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RoleModel } from "@frontend/src/app/model/role.model";
import { HotelService } from "@frontend/src/app/services/hotel.service";
import { AuthService } from "@frontend/src/app/services/auth.service";
import { UserService } from "@frontend/src/app/services/user.service";
import { getPictureUrlFromByteArray, DEFAULT_USER_PROFILE_PICTURE } from "@frontend/src/app/utils/file.utils";

@Component({
	selector: "app-user-details",
	templateUrl: "./user-details.component.html",
	styleUrl: "./user-details.component.scss"
})
export class UserDetailsComponent implements OnInit {

	public picture!: SafeUrl;

	public userProfile: UserProfileResponse;

	private isLoadingSubject = new BehaviorSubject<boolean>(false);
	public isLoading$ = this.isLoadingSubject.asObservable();

	private isShowLogSubject = new BehaviorSubject<boolean>(false);
	public showLogs$ = this.isShowLogSubject.asObservable();

	public roles: RoleModel[];
	public hotels: HotelResponse[];

	public readonly DEFAULT_USER_PROFILE_PICTURE = DEFAULT_USER_PROFILE_PICTURE;

	constructor(private authService: AuthService,
                private userService: UserService,
                private hotelService: HotelService,
                private domSanitizer: DomSanitizer,
                private matSnackBar: MatSnackBar) {
	}

	async ngOnInit(): Promise<void> {
		this.authService.getCurrentUserFromApi().subscribe({
			next: (response) => {
				this.userProfile = response;
				if (response.profileImage?.data?.length) {
					this.picture = getPictureUrlFromByteArray(response.profileImage.data, this.domSanitizer);
				}
			},
			error: () => {
				this.isLoadingSubject.next(false);
			}
		});


		[this.roles, this.hotels] = await Promise.all([
			firstValueFrom(this.userService.getRoleSubs()),
			firstValueFrom(this.hotelService.getHotelsSub())
		]);
	}

	public updateProfile(profileForm: NgForm) {
		this.isLoadingSubject.next(true);
		this.userService.update$(this.userProfile.userId, profileForm.value)
			.subscribe({
				next: (_response) => {
					this.userProfile = _response;
					this.isLoadingSubject.next(false);
					this.showSuccessMessage("Profil utilisateur mis à jour");
				},
				error: (err) => {
					this.isLoadingSubject.next(false);
					this.showError(err.error.message);
				}
			});
	}

	public updatePassword(passwordForm: NgForm) {
		this.isLoadingSubject.next(true);
		this.authService.updatePassword(passwordForm.value).subscribe({
			next: () => {
				this.isLoadingSubject.next(false);
				this.showSuccessMessage("Mot de passe mis à jour");
				passwordForm.resetForm();
			},
			error: (err) => {
				this.isLoadingSubject.next(false);
				this.showError(err.error.message);
				passwordForm.resetForm(passwordForm.value);
			}
		});
	}

	public uploadProfilePicture(image: File): void {
		this.isLoadingSubject.next(true);
		this.picture = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(image));
		if (image) {
			this.userService.updateProfileImage$(this.userProfile.userId, image).subscribe({
				next: (image) => {
					this.userProfile.profileImage = image;
					this.isLoadingSubject.next(false);
				},
				error: () => {
					this.showError("Erreur de téléchargement");
					this.isLoadingSubject.next(false);
				}
			});
		}
	}

	public toggleMfa(): void {
		this.isLoadingSubject.next(true);
	}

	public toggleLogs(): void {
		this.isShowLogSubject.next(!this.isShowLogSubject.value);
	}

	private showError(errorMessage: string, duration?: number) {
		this.matSnackBar.open(errorMessage, undefined, {
			duration: duration || 3000,
			panelClass: "snack-bar-error"
		});
	}

	private showSuccessMessage(message: string, duration?: number) {
		this.matSnackBar.open(message, undefined, {
			duration: duration || 3000,
			panelClass: "snack-bar-success"
		});
	}
}
