import { Component, Input } from "@angular/core";

@Component({
	selector: "app-spinner",
	templateUrl: "./spinner.component.html",
	styleUrl: "./spinner.component.css"
})
export class SpinnerComponent {
    @Input() size = "50px";
}
