import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";


@NgModule({
	declarations: [  ],
	imports: [ RouterModule, CommonModule, FormsModule, ReactiveFormsModule],
	exports: [ RouterModule, CommonModule, FormsModule, ReactiveFormsModule ]
})
export class SharedModule { }