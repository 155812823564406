import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormControlEnum, IForm, IFormControl, ValidatorType } from "@frontend/src/app/model/dynamic-form/dynamic.form";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "app-dynamic-form",
	templateUrl: "./dynamic-form.component.html",
	styleUrl: "./dynamic-form.component.scss"
})
export class DynamicFormComponent implements OnInit {
    @Input({}) formData!: IForm;

    private fb = inject(FormBuilder);

    public formGroup: FormGroup = this.fb.group({}, { updateOn: "submit" });

    public readonly ControlType = FormControlEnum;

    @Output()
    public submitEvent = new EventEmitter<FormGroup["getRawValue"]>();

    ngOnInit(): void {
    	this.initForm();
    }

    public getValidationMessage(control: IFormControl): string {
    	const myFormControl = this.formGroup?.get(control.controlName);
    	let errorMessage = "";
    	control.validators?.forEach((val) => {
    		if (myFormControl.hasError(val.name as string))
    			errorMessage = val.message;
    	});
    	return errorMessage;
    }

    public onSubmit() {
    	if (!this.formGroup.invalid) {
    		this.submitEvent.emit(this.formGroup.getRawValue());
    	}
    }

    public onReset() {

    }

    private initForm() : void {
    	if (this.formData.formControls) {
    		const formGroup: any = {};

    		this.formData.formControls.forEach((control: IFormControl) => {
    			const controlValidators: Validators[] = [];

    			if (control.validators) {
    				control.validators.forEach(v => {
    					if (v.name === ValidatorType.REQUIRED)
    						controlValidators.push(Validators.required);

    					if (v.name === ValidatorType.MAX_LENGTH)
    						controlValidators.push(Validators.maxLength(v.maxLength));

    					if (v.name === ValidatorType.MIN_LENGTH)
    						controlValidators.push(Validators.maxLength(v.minLength));

    					if (v.name === ValidatorType.EMAIL)
    						controlValidators.push(Validators.email);

    					if (v.name === ValidatorType.PATTERN)
    						controlValidators.push(Validators.pattern(v.pattern));
    				});
    			}

    			formGroup[control.controlName] = [control.value || null, controlValidators];
    		});
    		this.formGroup = this.fb.group(formGroup);
    	}
    }
}
