<ng-container *ngIf="userProfile">
    <section>
        <div class="container">
            <nav aria-label="breadcrumb" style="margin-top: 8px;">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/feature']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a [routerLink]="['/feature/users']">Utilisateurs</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {{ userProfile.firstName }} {{ userProfile.lastName }}
                    </li>
                </ol>
            </nav>
            <div class="bg-white shadow rounded-lg d-block d-sm-flex" style="border-radius: 8px;">
                <div class="profile-tab-nav border-right">
                    <div class="p-4">
                        <div class="img-circle text-center mb-3">
                                <span style="position: relative">
                                    <img *ngIf="picture; else noPicture" [src]="picture" alt="user-image"
                                         class="shadow"/>
                                    <ng-template #noPicture>
                                        <img [src]="DEFAULT_USER_PROFILE_PICTURE"
                                             alt="loading" class="shadow"/>
                                    </ng-template>
                                    <mat-icon class="right-position"
                                              (click)="fileInput.click()">photo_camera
                                    </mat-icon>
                                </span>
                        </div>
                        <h4 class="text-center"> {{ userProfile.firstName }} {{ userProfile.lastName }}</h4>
                        <span class="">Dépuis {{ userProfile.createdAt | date : 'MMMM d, y' }}</span>
                    </div>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                         aria-orientation="vertical">
                        <a class="nav-link active d-flex align-items-center" id="profile" data-bs-toggle="pill"
                           data-bs-target="#profile-tab"
                           aria-selected="true" aria-controls="profile" role="tab">
                            <mat-icon style="margin-right: 12px;">account_circle</mat-icon>
                            Profile
                        </a>
                        <a class="nav-link d-flex align-items-center" id="password"
                           data-bs-toggle="pill"
                           data-bs-target="#password-tab"
                           aria-selected="true" aria-controls="password" role="tab">
                            <mat-icon style="margin-right: 12px;">key</mat-icon>
                            Mot de passe
                        </a>
                        <a class="nav-link d-flex align-items-center" id="security"
                           data-bs-toggle="pill"
                           data-bs-target="#security-tab"
                           aria-selected="true" aria-controls="security" role="tab">
                            <mat-icon style="margin-right: 12px;">shield</mat-icon>
                            Authorization
                        </a>

                        <a class="nav-link d-flex align-items-center" id="notification" data-bs-toggle="pill"
                           data-bs-target="#notification-tab" aria-selected="true" aria-controls="notification"
                           role="tab">
                            <mat-icon style="margin-right: 12px;">lock</mat-icon>
                            Authentication
                        </a>
                    </div>
                </div>
                <div class="tab-content p-4 p-md-5" id="v-pills-tabContent" style="border-left: 1px solid #d4d0d0;">
                    <div class="tab-pane fade show active" id="profile-tab" role="tabpanel"
                         aria-labelledby="profile" style="min-height: 510px;">
                        <h3 class="mb-4">Profile</h3>
                        <hr>
                        <form #profileForm="ngForm"
                              (ngSubmit)="profileForm.form.valid && updateProfile(profileForm); profileForm.resetForm()"
                              novalidate>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nom</label>
                                        <input type="text" name="lastName"
                                               [ngModel]="userProfile.lastName"
                                               [disabled]="(isLoading$ | async)"
                                               #lastName="ngModel"
                                               class="form-control"
                                               required
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && lastName.errors }"/>

                                        <div *ngIf="profileForm.submitted && lastName.errors"
                                             class="invalid-feedback">

                                            <div *ngIf="lastName.errors['required']">
                                                Le nom est obligatoire
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Prénom</label>
                                        <input type="text" name="firstName"
                                               [ngModel]="userProfile.firstName"
                                               [disabled]="(isLoading$ | async)"
                                               #firstName="ngModel"
                                               class="form-control"
                                               required
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && firstName.errors }"/>
                                        <div *ngIf="profileForm.submitted && firstName.errors"
                                             class="invalid-feedback">

                                            <div *ngIf="firstName.errors['required']">
                                                Le prénom est obligatoire
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" name="email" id="email"
                                               email
                                               [ngModel]="userProfile.email"
                                               [disabled]="(!userProfile.roles?.includes('ADMIN') || !userProfile.roles?.includes('SUPERADMIN')) || (isLoading$ | async)"
                                               #email="ngModel"
                                               class="form-control"
                                               required
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && email.errors }"/>
                                        <div *ngIf="profileForm.submitted && email.errors" class="invalid-feedback">
                                            <div *ngIf="email.errors['required']">L'email est obligatoire</div>
                                            <div *ngIf="email.errors['email']">L'email est invalide</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>N° de Télephone</label>
                                        <input type="text" name="phoneNumber"
                                               [ngModel]="userProfile?.phoneNumber"
                                               [disabled]="(isLoading$ | async)"
                                               #phoneNumber="ngModel"
                                               maxlength="16"
                                               class="form-control"
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && phoneNumber.errors }"/>
                                        <div *ngIf="profileForm.submitted && phoneNumber.errors" class="invalid-feedback">
                                            <div *ngIf="email.errors['maxlength']">Le contact ne doit pas être > 16 caractères</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Addresse</label>
                                        <input type="text" name="address"
                                               [ngModel]="userProfile?.address"
                                               [disabled]="(isLoading$ | async)"
                                               class="form-control"/>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Titre</label>
                                        <input type="text" name="title"
                                               [ngModel]="userProfile?.title"
                                               [disabled]="(!userProfile.roles?.includes('ADMIN') || !userProfile.roles?.includes('SUPERADMIN')) ||(isLoading$ | async)"
                                               #title="ngModel"
                                               class="form-control"
                                               required
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && title.errors }">
                                        <div *ngIf="profileForm.submitted && title.errors" class="invalid-feedback">
                                            <div *ngIf="email.errors['required']">Le titre est obligatoire</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="color" name="color"
                                               [ngModel]="userProfile.color ?? '#1e90ff'"
                                               required
                                               #color="ngModel"
                                               [ngClass]="{ 'is-invalid' : profileForm.submitted && color.errors }"/>
                                        <div *ngIf="profileForm.submitted && color.errors" class="invalid-feedback">
                                            <div *ngIf="email.errors['required']">La couleur est obligatoire</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Vue du calendrier</label>
                                        <select class="form-select"
                                                name="calendarDefaultView"
                                                [ngModel]="userProfile.calendarDefaultView"
                                                required
                                                #calendarDefaultView="ngModel"
                                                [ngClass]="{ 'is-invalid' : profileForm.submitted && calendarDefaultView.errors }">
                                            <option value="day">Jour</option>
                                            <option value="week">Semaine</option>
                                            <option value="month">Mois</option>
                                        </select>
                                        <div *ngIf="profileForm.submitted && calendarDefaultView.errors" class="invalid-feedback">
                                            <div *ngIf="email.errors['required']">La vue du calendrier est obligatoire</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" class="btn"
                                        [disabled]="(isLoading$ | async)">
                                        <span *ngIf="isLoading$ | async" class="spinner-border spinner-border-sm"
                                              role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                                    <span *ngIf="isLoading$ | async">Loading...</span>
                                    <span *ngIf="!(isLoading$ | async)">Enregistrer</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="password-tab" role="tabpanel"
                         style="min-height: 510px;">
                        <h3 class="mb-4">Mot de passe</h3>
                        <hr>
                        <form #passwordForm="ngForm"
                              (ngSubmit)="passwordForm.form.valid && updatePassword(passwordForm)"
                              novalidate
                              [fidoInterWorkspaceMatchPassword]="['newPassword', 'newPasswordConfirm']">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mot de passe actuel</label>
                                        <input type="password" name="currentPassword" ngModel
                                               [disabled]="(isLoading$ | async)"
                                               #currentPassword="ngModel"
                                               class="form-control"
                                               required
                                               [ngClass]="{ 'is-invalid' : passwordForm.submitted && currentPassword.errors }" />

                                        <div *ngIf="passwordForm.submitted && currentPassword.errors"
                                             class="invalid-feedback">
                                            <div *ngIf="currentPassword.errors['required']">Mot de passe obligatoire</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Nouveau mot de passe</label>
                                        <input type="password" name="newPassword" ngModel
                                               #newPassword="ngModel"
                                               [disabled]="(isLoading$ | async)"
                                               class="form-control"
                                               minlength="6"
                                               required
                                               [ngClass]="{ 'is-invalid' : passwordForm.submitted && newPassword.errors }">

                                        <div *ngIf="passwordForm.submitted && newPassword.errors"
                                             class="invalid-feedback">
                                            <div *ngIf="newPassword.errors['required']">Mot de passe obligatoire</div>
                                            <div *ngIf="newPassword.errors['minlength']">
                                                Le mot de passe doit avoir au moins 6 caractères
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Confirmation</label>
                                        <input type="password" name="newPasswordConfirm" ngModel
                                               #newPasswordConfirm="ngModel"
                                               [disabled]="(isLoading$ | async)"
                                               class="form-control"
                                               minlength="6"
                                               required
                                               [ngClass]="{ 'is-invalid' : passwordForm.submitted && newPasswordConfirm.errors }">

                                        <div *ngIf="passwordForm.submitted && newPasswordConfirm.errors"
                                             class="invalid-feedback">

                                            <div *ngIf="newPasswordConfirm.errors['required']">
                                                Confirm Password est obligatoire
                                            </div>
                                            <div *ngIf="newPasswordConfirm.errors['matching']">
                                                Les deux mot de passe ne sont pas identiques
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary"
                                    [disabled]="(isLoading$ | async)">
                                    <span *ngIf="isLoading$ | async" class="spinner-border spinner-border-sm"
                                          role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                                <span *ngIf="isLoading$ | async">Loading...</span>
                                <span *ngIf="!(isLoading$ | async)">Enregistrer</span>
                            </button>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="security-tab" role="tabpanel" aria-labelledby="security"
                         style="min-height: 510px;">
                        <h3 class="mb-4">Permissions</h3>
                        <hr>
                        <form>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="margin-bottom: 5px;">Role Permissions</label>
                                        <input type="text"
                                               *ngFor="let permission of userProfile?.roles"
                                               [value]="permission"
                                               [disabled]="true"
                                               class="form-control" style="margin-bottom: 5px;">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="margin-bottom: 5px;">Hotels</label>
                                        <input type="text"
                                               *ngFor="let hotel of userProfile?.hotels"
                                               [value]="hotel.name"
                                               [disabled]="true"
                                               class="form-control" style="margin-bottom: 5px;">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="notification-tab" role="tabpanel" aria-labelledby="notification"
                         style="min-height: 510px;">
                        <h3 class="mb-4">Paramètres d'authentification</h3>
                        <p>Ces paramètres permettent de garder votre compte mieux sécurisé.</p>
                        <div class="list-group mb-5 shadow">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-2">Multi-Factor Authentication</strong>
                                        <span *ngIf="userProfile?.usingMfa"
                                              class="badge bg-success mx-3">Enabled</span>
                                        <span *ngIf="!userProfile?.usingMfa"
                                              class="badge bg-warning mx-3">Disabled</span>
                                        <p class="text-muted mb-0">Set up Multi-factor Authentication (MFA) help
                                            keep your account more secure</p>
                                    </div>
                                    <div class="col-auto">
                                        <button (click)="toggleMfa()"
                                                [disabled]="(isLoading$ | async)"
                                                class="btn btn-primary btn-sm">
                                            {{ userProfile?.usingMfa ? 'Désactiver' : 'Activer' }}
                                            <span *ngIf="(isLoading$ | async)"
                                                  class="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true" style="margin-right: 5px;"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-2">Activity Logs</strong>
                                        <p class="text-muted mb-0">Show the last activities on your account</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" (change)="toggleLogs()"
                                                   [disabled]="(isLoading$ | async)"
                                                   [checked]="(showLogs$ | async)"
                                                   class="custom-control-input" id="activityLog">
                                            <span class="custom-control-label"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<!-- profile image change form-->
<form enctype="multipart/form-data" style="display:none;">
    <input type="file" #fileInput (change)="uploadProfilePicture(fileInput.files[0])" name="image" id="image"
           placeholder="file" ngModel accept="image/*"/>
</form>

