<ng-container *ngIf="hotel">
    <section>
        <div class="container mt-3">
            <div class="bg-white shadow rounded-lg d-block d-sm-flex" style="border-radius: 8px;">
                <div class="profile-tab-nav border-right">
                    <div class="p-4">
                        <div class="img-circle text-center mb-3">
                                <span style="position: relative">
                                  <img height="50" width="50"
                                       src="https://upload.wikimedia.org/wikipedia/commons/6/67/User_Avatar.png"
                                       alt="Loading" class="shadow"/>
                                    <mat-icon class="right-position"
                                              (click)="fileInput.click()">photo_camera</mat-icon>
                                </span>
                        </div>
                        <h4 class="text-center"> {{ hotel.name }} </h4>
                    </div>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                         aria-orientation="vertical">
                        <a class="nav-link active d-flex align-items-center gap-2" id="profile" data-bs-toggle="pill"
                           data-bs-target="#general-info-tab"
                           aria-selected="true" aria-controls="profile" role="tab">
                            <mat-icon>home</mat-icon>
                            Informations Générales
                        </a>

                        <a class="nav-link d-flex align-items-center gap-2" id="password" data-bs-toggle="pill"
                           data-bs-target="#finance-data-tab"
                           aria-selected="true" aria-controls="password" role="tab">
                            <mat-icon>price_check</mat-icon>
                            Paramétrages Financiers
                        </a>
                    </div>
                </div>
                <div class="tab-content p-4 p-md-3" id="v-pills-tabContent" style="border-left: 1px solid #d4d0d0;">
                    <div class="tab-pane fade show active" id="general-info-tab" role="tabpanel"
                         aria-labelledby="profile" style="min-height: 510px;">
                        <h3 class="mb-4">Informations Générales</h3>

                        <div class="card">
                            <div class="card-body">
                                <form #hotelForm="ngForm" (ngSubmit)="onUpdateData(hotelForm)">
                                    <div class="mb-3">
                                        <label class="small mb-1" for="name">Nom de l'organisation</label>
                                        <input class="form-control" id="name" type="text" name="name"
                                               placeholder="Nom de l'organisation"
                                               [ngModel]="hotel.name">
                                    </div>

                                    <div class="row gx-3 mb-3">
                                        <div class="col-md-6">
                                            <label class="small mb-1" for="contact">N° de Téléphone</label>
                                            <input class="form-control" id="contact" type="tel" name="contact"
                                                   placeholder="Entrer le N° de téléphone" [ngModel]="hotel.contact">
                                        </div>

                                        <div class="col-md-6">
                                            <label class="small mb-1" for="city">Ville</label>
                                            <input class="form-control" id="city" type="text" name="city"
                                                   placeholder="Entrer la ville" [ngModel]="hotel.city">
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="small mb-1" for="email">Addresse Email</label>
                                        <input class="form-control" id="email" type="email" name="email"
                                               placeholder="Addresse email" [ngModel]="hotel?.email">
                                    </div>

                                    <div class="mb-3">
                                        <label class="small mb-1" for="website">Site web</label>
                                        <input class="form-control" id="website" type="url" name="website"
                                               placeholder="Entrer le site web" [ngModel]="hotel?.website">
                                    </div>

                                    <div class="mb-3">
                                        <label class="small mb-1" for="address">Addresse</label>
                                        <input class="form-control" id="address" type="text" name="address"
                                               placeholder="Entrer l'addresse" [ngModel]="hotel?.address">
                                    </div>

                                    <button class="btn primary d-flex align-items-center gap-2"
                                            type="submit"
                                            *ngIf="currentUser?.roles?.includes('SUPERADMIN')">
                                        <mat-icon>save</mat-icon>
                                        Enregistrer
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade show" id="finance-data-tab" role="tabpanel"
                         aria-labelledby="profile" style="min-height: 510px;">
                        <h3 class="mb-4">Informations Financières</h3>

                        <div class="card">
                            <div class="card-body">
                                <form #hotelUpdateForm="ngForm" (ngSubmit)="onUpdateData(hotelUpdateForm)">

                                    <div class="row gx-3 mb-3">
                                        <div class="col-md-6">
                                            <label class="small mb-1" for="vta-rate">Taux de TVA</label>
                                            <input class="form-control" id="vta-rate" type="number"
                                                   name="defaultVtaRate"
                                                   placeholder="Entrer le taux tva"
                                                   [ngModel]="hotel.defaultVtaRate">
                                        </div>

                                        <div class="col-md-6">
                                            <label class="small mb-1" for="discount-rate">Taux de reduction</label>
                                            <input class="form-control" id="discount-rate" type="number"
                                                   name="defaultDiscountRate"
                                                   placeholder="Entrer le taux de réduction"
                                                   [ngModel]="hotel.defaultDiscountRate">
                                        </div>
                                    </div>

                                    <div class="row gx-3 mb-3">
                                        <div class="col-md-6">
                                            <label class="small mb-1" for="discount-amount">Montant de réduction</label>
                                            <input class="form-control" id="discount-amount" type="number"
                                                   name="defaultDiscountAmount"
                                                   placeholder="Enter le montant de réduction"
                                                   [ngModel]="hotel.defaultDiscountAmount">
                                        </div>
                                    </div>

                                    <div class="row gx-3 mb-3">
                                        <div class="col-md-6">
                                            <label class="small mb-1" for="nap-amount">Montant de sieste</label>
                                            <input class="form-control" id="nap-amount" type="number"
                                                   name="defaultNapAmount"
                                                   placeholder="Montant de la sieste"
                                                   [ngModel]="hotel.defaultNapAmount">
                                        </div>

                                        <div class="col-md-6">
                                            <label class="small mb-1" for="nap-time">Nb d'heure de sieste </label>
                                            <input class="form-control" id="nap-time" type="number"
                                                   name="defaultNapTime"
                                                   placeholder="Heure de sieste" [ngModel]="hotel.defaultNapTime">
                                        </div>
                                    </div>

                                    <button class="btn d-flex align-items-center gap-2 primary"
                                            type="submit"
                                            *ngIf="currentUser?.roles?.includes('SUPERADMIN')">
                                        <mat-icon>save</mat-icon>
                                        Enregistrer
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<form enctype="multipart/form-data" style="display:none;">
    <input type="file" #fileInput name="image" id="image"
           placeholder="file" ngModel accept="image/*"/>
</form>
