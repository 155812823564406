<div class="dialog-title">
    <div class="title" *ngIf="!data; else titleTemple">
        <h1 mat-dialog-title> Créer une chambre </h1>
    </div>

    <ng-template #titleTemple>
        <div class="title">
            <h1 mat-dialog-title> Modifier la chambre </h1>
        </div>
    </ng-template>

    <div class="close">
        <button [mat-dialog-close]="false" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<form *ngIf="roomForm" [formGroup]="roomForm">
    <div mat-dialog-content>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select formControlName="typeLogement">
                    <mat-option> -- Sélectionner --</mat-option>
                    <mat-option value="CHAMBRE">Chambre</mat-option>
                    <mat-option value="SUITE">SUITE</mat-option>
                    <mat-option value="SALLE_DE_FETE">Salle de fête</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Niveau</mat-label>
                <mat-select formControlName="niveauId">
                    <mat-option> -- Sélectionner --</mat-option>
                    <mat-option *ngFor="let level of levels"
                                [value]="level.id">
                        {{ level.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Nom</mat-label>
                <input matInput id="name" [type]="'text'"
                       placeholder="Entrer le nom" formControlName="name">

                <mat-hint class="text-danger"
                          *ngIf="f?.name?.touched && !f?.name?.valid">
                    Veuillez saisir un nom correct
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Code</mat-label>
                    <input matInput id="code" [type]="'text'"
                           placeholder="Entrer le code code" formControlName="code">

                    <mat-hint class="text-danger"
                              *ngIf="f.code?.touched && !f.code?.valid">
                        Veuillez saisir un code correct
                    </mat-hint>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Price</mat-label>
                    <input matInput id="defaultPrice" type="number"
                           placeholder="Enter Price" formControlName="defaultPrice">
                    <mat-hint class="text-danger"
                              *ngIf="f.defaultPrice?.touched && !f.defaultPrice?.valid">
                        Veuillez saisir un prix correct
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Montant sieste</mat-label>
                    <input matInput id="nap-amount" [type]="'number'"
                           placeholder="Montant de sieste" formControlName="napAmount">

                    <mat-hint class="text-danger"
                              *ngIf="f.napAmount?.touched && !f.napAmount?.valid">
                        Veuillez saisir un code correct
                    </mat-hint>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nb heure de sieste</mat-label>
                    <input matInput id="nap-time" [type]="'number'"
                           placeholder="Heure de sieste" formControlName="napTime">
                    <mat-hint class="text-danger"
                              *ngIf="f.napTime?.touched && !f.napTime?.valid">
                        Veuillez saisir un prix correct
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput id="description"
                          placeholder="Enter description" formControlName="description"></textarea>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-checkbox formControlName="wifiAvailable">Wifi Disponible ?</mat-checkbox>
            </div>

            <div class="col-md-6">
                <mat-checkbox formControlName="bathroomAvailable">Douche disponible ?</mat-checkbox>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <mat-checkbox formControlName="kitchenAvailable">Cuisine Disponible ?</mat-checkbox>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre de personnes</mat-label>
                    <input matInput type="number"
                           placeholder="Nombre de personne" formControlName="numberOfPersons">
                    <mat-hint class="text-danger"
                              *ngIf="f.numberOfPersons?.touched && !f.numberOfPersons?.valid">
                        Veuillez saisir un prix correct
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="form-actions" align="center">
        <button mat-raised-button color="warn" type="reset" (click)="resetForm()">
            <mat-icon>restart_alt</mat-icon>
            Réinitialiser
        </button>

        <button mat-raised-button class="save-btn"
                [disabled]="roomForm.invalid"
                (click)="onSaveRoom()">
            <mat-icon>save</mat-icon>
            Enregistrer
        </button>
    </div>
</form>