import { Component, Inject, OnInit } from "@angular/core";
import { NewRoomBaseForm, NewRoomForm } from "../../../../../forms/room.form";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HotelResponse, LogementApiResponse, LogementTypeEnum, Nullable } from "@app/model";
import { MetaFormGroup } from "../../../../../utils/form.utils";
import { FormControl, Validators } from "@angular/forms";
import { LevelService } from "../../../../../services/level.service";
import { LevelResponse } from "../../../../../model/level.response";
import { RoomService } from "../../../../../services/room.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LogementRequest } from "../../../../../model/logement.request";
import { HotelService } from "../../../../../services/hotel.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-new-room-dialog",
    templateUrl: "./new-room-dialog.component.html",
    styleUrl: "./new-room-dialog.component.scss"
})
export class NewRoomDialogComponent implements OnInit {

    public roomForm!: NewRoomForm;
    public levels: LevelResponse[] = [];
    private _currentHotel!: HotelResponse;

    public constructor(@Inject(MAT_DIALOG_DATA) public data: LogementApiResponse,
                       private levelService: LevelService,
                       private roomService: RoomService,
                       private hotelService: HotelService,
                       private matDialogRef: MatDialogRef<NewRoomDialogComponent>,
                       private matSnackBar: MatSnackBar) {
    }

    async ngOnInit(): Promise<void> {
        this._currentHotel = await firstValueFrom(this.hotelService.getSessionHotel());
        this.levelService.getLevelsSubs()
            .subscribe(_response => this.levels = _response);

        if (this.data) {
            this.initForm(this.data);
        } else
            this.initForm();
    }

    private initForm(room?: LogementApiResponse) {
        this.roomForm = new MetaFormGroup<NewRoomBaseForm, {
            id?: Nullable<number>;
            savedValue: LogementApiResponse;
            isEdit: boolean;
            isCreated: boolean;
            isLoading: boolean
        }>(
            {
                niveauId: new FormControl<number>(Number(room?.niveau?.id), {
                    nonNullable: true,
                    validators: Validators.required
                }),
                name: new FormControl<string>(room?.name, {nonNullable: true, validators: Validators.required}),
                code: new FormControl<string>(room?.code, {nonNullable: true, validators: Validators.required}),
                description: new FormControl<string>(room?.description || "Ras", {
                    nonNullable: true,
                }),
                defaultPrice: new FormControl<number>(Number(room?.defaultPrice) || 0, {
                    nonNullable: true,
                    validators: [Validators.required, Validators.min(1)]
                }),
                typeLogement: new FormControl(room?.typeLogement || LogementTypeEnum.CHAMBRE, {
                    nonNullable: true,
                    validators: Validators.required
                }),
                wifiAvailable: new FormControl<boolean>(room?.wifiAvailable ?? true, {
                    nonNullable: true,
                    validators: Validators.required
                }),
                kitchenAvailable: new FormControl<boolean>(room?.kitchenAvailable ?? false, {
                    nonNullable: true,
                    validators: Validators.required
                }),
                bathroomAvailable: new FormControl<boolean>(room?.bathroomAvailable ?? true, {
                    nonNullable: true,
                    validators: Validators.required
                }),
                numberOfPersons: new FormControl<number>(Number(room?.numberOfPersons) || Number(1), {
                    nonNullable: true,
                    validators: [Validators.min(1), Validators.required]
                }),
                napAmount: new FormControl<number>(Number(room?.napAmount) || Number(this._currentHotel.defaultNapAmount) || Number(5000), {
                    nonNullable: true,
                    validators: [Validators.min(1), Validators.required]
                }),
                napTime: new FormControl<number>(Number(room?.napTime) || Number(this._currentHotel.defaultNapTime) || 2, {
                    nonNullable: true,
                    validators: [Validators.min(1), Validators.required]
                })
            },
            {
                id: room?.logementId,
                isEdit: !room,
                isCreated: !!room,
                isLoading: false,
                savedValue: room
            }
        );
    }

    get f() {
        return this.roomForm.controls;
    }

    public resetForm() {
        this.roomForm.reset(this.data);
    }

    public onSaveRoom() {
        this.f.napAmount.setValue(Number(this.f.napAmount.value))
        this.f.napTime.setValue(Number(this.f.napTime.value))
        this.f.defaultPrice.setValue(Number(this.f.defaultPrice.value))
        this.f.numberOfPersons.setValue(Number(this.f.numberOfPersons.value))

        if (!this.data) {
            this.roomService.saveLogement(this.roomForm.getRawValue()).subscribe({
                next: (data) => {
                    this.matSnackBar.open("Operation effectuée avec succès.", undefined, {
                        panelClass: "snack-bar-success",
                        duration: 3000
                    });
                    this.matDialogRef.close(data);
                },
                error: (error) => {
                    this.matSnackBar.open(error.message, "Ok", {
                        panelClass: "snack-bar-error",
                        duration: 3000
                    });
                }
            });
        } else {
            this.roomService.updateLogement(this.data.logementId, this.roomForm.getRawValue() as LogementRequest).subscribe({
                next: (data) => {
                    this.matSnackBar.open("Operation effectuée avec succès.", "Ok", {
                        panelClass: "snack-bar-success",
                        duration: 3000
                    });
                    this.matDialogRef.close(data);
                },
                error: (error) => {
                    this.matSnackBar.open(error.message, "Ok", {
                        panelClass: "snack-bar-error",
                        duration: 3000
                    });
                }
            });
        }
    }
}
