import { CalendarEvent } from "angular-calendar";

export interface CalendarDataModel extends CalendarEvent{
    id: number,
    title: string,
    start: Date,
    end: Date,
    duration: number,
    label: string,
    printDate: string,
    cssClass?: string,
    allDay: boolean,
    color: {
        primary: string,
        secondary: string,
    }
    customer: {
        customerId: number,
        firstName: string,
        lastName: string,
        contact: string
    },
    logement: {
        logementId: number,
        name: string,
        code: string,
        niveau?: {
            id: number,
            name: string,
            code: string
        }
    }
}

export interface CalendarSettings {
    hourSegments: number,
    hourSegmentHeight: number,
    dayStartHour: number,
    dayEndHour: number,
    weekStartsOn: number,
    locale: "fr",
    tooltipDelay: number,
    excludeDays: [0, 7]
}

export const staticPlanningCalendarSettings = {
	hourSegments: 1,
	hourSegmentHeight: 35,
	dayStartHour: 4,
	dayEndHour: 22,
	weekStartsOn: 1,
	locale: "fr",
	tooltipDelay: 300,
	excludeDays: [0, 7]
};

export enum CalendarView {
    Week = "week",
    Month = "month",
    Day = "day"
}

