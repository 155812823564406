import { HostingResponseModel } from "@app/model";

export function extractDistinctCustomer(customers: HostingResponseModel["customer"][]):HostingResponseModel["customer"][] {
	const distinctCustomers: Set<string> = new Set();

	customers.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
		.forEach(item => {
			distinctCustomers.add(JSON.stringify(item));
		});

	return [...distinctCustomers].map(customer => JSON.parse(customer));
}