import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { FeatureRoutingModule } from "./feature-routing.module";
import { FeatureComponent } from "./feature.component";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AppMaterialModule } from "../../app-material.module";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RestaurationComponent } from "./pages/restauration/restauration.component";
import { ReservationComponent } from "./pages/reservation/reservation.component";
import { EditBookingDialogComponent } from "./pages/dialog/edit-booking-dialog/edit-booking-dialog.component";
import { CustomersComponent } from "./pages/customers/customers.component";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { ComputeHostingComponent } from "./pages/dialog/compute-hosting/compute-hosting.component";
import { ReservationDetailComponent } from "./pages/reservation/reservation-detail/reservation-detail.component";
import { NewCustomerDialogComponent } from "./pages/dialog/new-customer-dialog/new-customer-dialog.component";
import { RoomsComponent } from "./pages/rooms/rooms.component";
import { NewRoomDialogComponent } from "./pages/dialog/new-room-dialog/new-room-dialog.component";
import { RoomDetailComponent } from "./pages/room-detail/room-detail.component";
import { HotelConfigurationComponent } from "./pages/hotel-configuration/hotel-configuration.component";
import { UsersComponent } from "./pages/users/users.component";
import { NewUserDialogComponent } from "./pages/dialog/new-user-dialog/new-user-dialog.component";
import { UserDetailsComponent } from "./pages/users/user-details/user-details.component";
import { MatListModule } from "@angular/material/list";
import { PlanningComponent } from "@frontend/src/app/components/feature/pages/planning/planning.component";
import {
	ReservationCalendarComponent
} from "@frontend/src/app/components/feature/pages/reservation-calendar/reservation-calendar.component";
import {
	CustomerTableComponent
} from "@frontend/src/app/components/feature/pages/components/customer-table/customer-table.component";
import {
	ReservationTableComponent
} from "@frontend/src/app/components/feature/pages/components/reservation-table/reservation-table.component";
import { NgxEchartsDirective } from "ngx-echarts";
import { CoreModule } from "@frontend/src/app/core/core.module";


@NgModule({
	declarations: [
		FeatureComponent,
		DashboardComponent,
		RestaurationComponent,
		ReservationComponent,
		EditBookingDialogComponent,
		CustomersComponent,
		ComputeHostingComponent,
		ReservationDetailComponent,
		NewCustomerDialogComponent,
		RoomsComponent,
		NewRoomDialogComponent,
		RoomDetailComponent,
		HotelConfigurationComponent,
		UsersComponent,
		NewUserDialogComponent,
		UserDetailsComponent,
		PlanningComponent,
		ReservationCalendarComponent,
		CustomerTableComponent,
		ReservationTableComponent
	],
	imports: [
		CommonModule,
		FeatureRoutingModule,
		SharedModule,
		AppMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMatDatetimePickerModule,
		NgOptimizedImage,
		MatListModule,
		NgxEchartsDirective,
		CoreModule
	],
	providers: [

	]
})
export class FeatureModule {
}
