<ng-container>
    <app-calendar-header (currentViewChange)="onChangeView($event)"
                         (closeMonthView)="closeOpenMonthViewDay()"
                         [(viewDate)]="viewDate"
                         [(currentView)]="currentView"
                         [isLoading]="isLoading">
    </app-calendar-header>

    <br/>

    <div [ngSwitch]="currentView">
        <mwl-calendar-month-view
                *ngSwitchCase="CalendarViewEnum.Month"
                (beforeViewRender)="beforeViewRender($event)"
                [viewDate]="viewDate"
                [events]="calendarReservations"
                [refresh]="refreshSubject"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="onCalendarEventClick?.($event)"
                [tooltipTemplate]="customTooltip">
        </mwl-calendar-month-view>

        <mwl-calendar-week-view
                *ngSwitchCase="CalendarViewEnum.Week"
                (beforeViewRender)="beforeViewRender($event)"
                [hourSegments]="calendarSettings.hourSegments"
                [hourSegmentHeight]="calendarSettings.hourSegmentHeight"
                [dayStartHour]="calendarSettings.dayStartHour"
                [dayEndHour]="calendarSettings.dayEndHour"
                [viewDate]="viewDate"
                [events]="calendarReservations"
                [refresh]="refreshSubject"
                [weekStartsOn]="calendarSettings.weekStartsOn"
                [locale]="calendarSettings.locale"
                [tooltipDelay]="calendarSettings.tooltipDelay"
                (eventClicked)="onCalendarEventClick?.($event)"
                [tooltipTemplate]="customTooltip"
                (dayHeaderClicked)="onDayClicked($event)">
        </mwl-calendar-week-view>

        <mwl-calendar-day-view
                *ngSwitchCase="CalendarViewEnum.Day"
                (beforeViewRender)="beforeViewRender($event)"
                [hourSegments]="calendarSettings.hourSegments"
                [hourSegmentHeight]="calendarSettings.hourSegmentHeight"
                [dayStartHour]="calendarSettings.dayStartHour"
                [dayEndHour]="calendarSettings.dayEndHour"
                [viewDate]="viewDate"
                [events]="calendarReservations"
                [refresh]="refreshSubject"
                [locale]="calendarSettings.locale"
                [tooltipDelay]="calendarSettings.tooltipDelay"
                (eventClicked)="onCalendarEventClick?.($event)"
                [tooltipTemplate]="customTooltip">
        </mwl-calendar-day-view>
    </div>
</ng-container>

<ng-template #customTooltip let-event="event">
    <div class="custom-tooltip">
        <div>Client : {{ event.label }}</div>
        <div>Contact : {{ event["customer"]["contact"]}}</div>
        <div>Chambre N° : {{ event["logement"]["code"]}}</div>
        <div>Date : {{ event["printDate"] }}</div>
        <div>Durée : {{ millisecondsToHoursSeparatorMinutes(event.duration, "h") }}</div>
    </div>
</ng-template>

<div *ngIf="isLoading" class="loader">
    <app-spinner size="75px"></app-spinner>
</div>
