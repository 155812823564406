import { Component, Inject, OnInit } from "@angular/core";
import { CustomerResponse, GenreEnum, Nullable } from "@app/model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomerService } from "../../../../../services/customer.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerRequest } from "../../../../../model/customer.request";
import { NewCustomerForm } from "../../../../../forms/customer.form";
import { FormControlEnum, IForm, ValidatorType } from "@frontend/src/app/model/dynamic-form/dynamic.form";
import { FormGroup } from "@angular/forms";

@Component({
	selector: "app-new-customer-dialog",
	templateUrl: "./new-customer-dialog.component.html",
	styleUrl: "./new-customer-dialog.component.scss"
})
export class NewCustomerDialogComponent implements OnInit {

	public customerForm!: NewCustomerForm;
	public form: IForm;

	constructor(@Inject(MAT_DIALOG_DATA) public data: CustomerResponse,
                private customerService: CustomerService,
                private matSnackBar: MatSnackBar,
                private dialogRef: MatDialogRef<NewCustomerDialogComponent>) {
	}

	ngOnInit(): void {
		if (this.data)
			this.initForm(this.data);
		else
			this.initForm();
	}

	public initForm(customer?: Nullable<CustomerResponse>) {
		this.initDynamicForm(customer);
	}

	get f() {
		return this.customerForm.controls;
	}

	public onSaveCustomer(formData: FormGroup["getRawValue"]): void {
		if (!this.data)
			this.customerService.createCustomer(formData as any as CustomerRequest)
				.subscribe({
					next: (customer) => {
						this.showSuccessMessage("Client enregistré avec succès");
						this.dialogRef.close(customer);
					},
					error: (err) => {
						this.matSnackBar.open(err.error.message, undefined, {
							duration: 3000,
							panelClass: "snack-bar-error"
						});
					}
				});
		else
			this.updateCustomer(formData);
	}

	private updateCustomer(formData: FormGroup["getRawValue"]): void {
		this.customerService.updateCustomer(this.data.customerId, formData as any as CustomerRequest)
			.subscribe({
				next: (customer) => {
					this.showSuccessMessage("Client modifié");
					this.dialogRef.close(customer);
				},
				error: (err) => {
					this.matSnackBar.open(err.error.message, undefined, {
						duration: 3000,
						panelClass: "snack-bar-error"
					});
				}
			});
	}

	public onResetForm(): void {
		this.customerForm.reset();
	}

	private showSuccessMessage(message: string): void {
		this.matSnackBar.open(message, "Ok", {
			panelClass: "snack-bar-success",
			duration: 3000
		});
	}

	private initDynamicForm(customerResponse?: Nullable<CustomerResponse>) {
		this.form = {
			formTitle: "",
			resetBtnTitle: "Réinitialiser",
			saveBtnTitle: "Enregistrer",
			formControls: [
				{
					controlName: "genre",
					type:  FormControlEnum.SELECT,
					label: "Genre",
					placeHolder: "Genre",
					value: customerResponse?.genre,
					options: [
						{ id: GenreEnum.MALE, value: "Mr" },
						{ id: GenreEnum.FEMALE, value: "Mme" },
					],
					class: "col-md-12",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "Veuillez sélectionner le genre",
							required: true
						}
					],
					isVisible: true
				},
				{
					controlName: "lastName",
					type:  FormControlEnum.TEXT,
					label: "Nom (s)",
					placeHolder: "Entrez le nom",
					value: customerResponse?.lastName,
					class: "col-md-12",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "Veuillez saisir le nom"
						}
					],
					isVisible: true
				},
				{
					controlName: "firstName",
					type:  FormControlEnum.TEXT,
					label: "Prénom (s)",
					placeHolder: "Entrez le prénom",
					value: customerResponse?.firstName,
					class: "col-md-12",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "Veuillez saisir le prénom"
						}
					],
					isVisible: true
				},
				{
					controlName: "email",
					type:  FormControlEnum.EMAIL,
					label: "Email",
					placeHolder: "Entrer l'email",
					value: customerResponse?.email,
					class: "col-md-12",
					validators: [
						{
							name: ValidatorType.EMAIL,
							message: "Veuillez saisir un email correct",
						}
					],
					isVisible: false
				},
				{
					controlName: "cni",
					type:  FormControlEnum.TEXT,
					label: "Numéro de CNI",
					placeHolder: "Entrer le CNI",
					value: customerResponse?.cni,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.MAX_LENGTH,
							message: "Le N° ne doit dépasser 21 caractères",
							maxLength: 21
						},
						{
							name: ValidatorType.REQUIRED,
							message: "Le N° de CNI est obligatoire",
						}
					],
					isVisible: true
				},
				{
					controlName: "dateOfIssue",
					type:  FormControlEnum.DATE,
					label: "Date de délivrance",
					placeHolder: "Date de délivrance",
					value: customerResponse?.dateOfIssue,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "La date de délivrance est obligatoire",
						}
					],
					isVisible: true
				},
				{
					controlName: "contact",
					type:  FormControlEnum.TEXT,
					label: "Contact",
					placeHolder: "Contact",
					value: customerResponse?.contact || "+237 ",
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: " Veuillez saisir le contact",
						}
					],
					isVisible: true
				},
				{
					controlName: "city",
					type:  FormControlEnum.TEXT,
					label: "Ville de provenance",
					placeHolder: "Ville de provenance",
					value: customerResponse?.city,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "La ville de provenance est obligatoire",
						}
					],
					isVisible: true
				},
				{
					controlName: "birthDate",
					type:  FormControlEnum.DATE,
					label: "Date de naissance",
					placeHolder: "Date de naissance",
					value: customerResponse?.birthDate,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "La date de naissance est obligatoire",
						}
					],
					isVisible: true
				},
				{
					controlName: "birthPlace",
					type:  FormControlEnum.TEXT,
					label: "Lieu de naissance",
					placeHolder: "Lieu de naissance",
					value: customerResponse?.birthPlace,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "Le lieu de naissance est obligatoire",
						}
					],
					isVisible: true,
				},
				{
					controlName: "profession",
					type:  FormControlEnum.TEXT,
					label: "Profession",
					placeHolder: "Entrer la profession",
					value: customerResponse?.profession || "Ras ..." ,
					class: "col-md-6",
					isVisible: true
				},
				{
					controlName: "nationality",
					type:  FormControlEnum.TEXT,
					label: "Nationalité",
					placeHolder: "Nationalité",
					value: customerResponse?.nationality,
					class: "col-md-6",
					validators: [
						{
							name: ValidatorType.REQUIRED,
							message: "La nationalité est obligatoire",
						}
					],
					isVisible: true
				},
				{
					controlName: "address",
					type:  FormControlEnum.TEXT,
					label: "Addresse",
					placeHolder: "Entrer l'addresse",
					value: customerResponse?.address || undefined,
					class: "col-md-12",
					isVisible: true
				},
				{
					controlName: "isActive",
					type:  FormControlEnum.CHECKED,
					label: "Est actif ?",
					value: customerResponse?.isActive || true,
					class: "col-md-12",
					isVisible: customerResponse != null
				}
			]
		};
	}
}
