<div class="container mt-2">
    <div class="card">
        <div class="card-body border">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="mb-3">
                        <h5 class="card-title">
                            Liste des utilisateurs
                            <span class="text-muted fw-normal ms-2">( {{ userPage?.totalRows }})</span>
                        </h5>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="currentUser?.roles?.includes('SUPERADMIN')">
                    <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                            <a class="btn add-btn d-flex align-items-center text-white"
                               (click)="onOpenNewUserDialog()">
                                <mat-icon>add</mat-icon>
                                Ajouter
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="userPage?.content?.length">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="">
                            <div class="table-responsive">
                                <table class="table project-list-table table-nowrap align-middle table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Hotels</th>
                                            <th scope="col">Roles</th>
                                            <th scope="col" style="width: 200px;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of userPage.content; let index = index">
                                            <td>
                                                <img *ngIf="user?.profileImage?.data; else defaultProfileImage"
                                                     [src]="getImageData(user.profileImage.data)" alt="loading"
                                                     class="avatar-sm rounded-circle me-2"/>

                                                <ng-template #defaultProfileImage>
                                                    <img [src]="DEFAULT_USER_PROFILE_PICTURE" alt="loading"
                                                         class="avatar-sm rounded-circle me-2"/>
                                                </ng-template>
                                                {{ user.lastName + ' ' + user.firstName }}
                                            </td>

                                            <td>{{ user.email }}</td>

                                            <td>
                                                <span class="badge badge-soft-success mb-0"> {{ getHotelNames(user) }}</span>
                                            </td>

                                            <td>{{ getRoleNames(user) }}</td>

                                            <td>
                                                <ul class="list-inline mb-0">
                                                    <li class="list-inline-item">
                                                        <a data-bs-toggle="tooltip" data-bs-placement="top"
                                                           class="px-2 text-primary cursor-pointer"
                                                           (click)="onOpenEditDialog(user)">
                                                            <i class="bx bx-pencil font-size-18"></i>
                                                        </a>
                                                    </li>
                                                    <li class="list-inline-item">
                                                        <a data-bs-toggle="tooltip" data-bs-placement="top"
                                                           class="px-2 text-danger cursor-pointer">
                                                            <i class="bx bx-trash-alt font-size-18"></i>
                                                        </a>
                                                    </li>
                                                    <li class="list-inline-item dropdown">
                                                        <a class="text-muted dropdown-toggle font-size-18 px-2" role="button"
                                                           data-bs-toggle="dropdown" aria-haspopup="true">
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <a class="dropdown-item cursor-pointer d-flex justify-items-center gap-2"
                                                               (click)="changeAccountState(user)">
                                                                <mat-icon color="accent">
                                                                    {{ user.isActif ? 'block' : 'check_circle' }}
                                                                </mat-icon>
                                                                {{ user.isActif ? 'Désactiver' : 'Activer' }}
                                                            </a>

                                                            <a class="dropdown-item cursor-pointer"
                                                               *ngIf="false"
                                                               [matMenuTriggerFor]="hotelRoleMenu"
                                                               [matMenuTriggerData]="{hotels: hotelOptions}"
                                                               #hotelMenuTrigger="matMenuTrigger"
                                                               (click)="openHotelRoleMenu($event, user)">
                                                                Hotels
                                                            </a>

                                                            <hr>
                                                            <a class="dropdown-item cursor-pointer d-flex justify-items-center gap-2"
                                                               [matMenuTriggerFor]="roleMenu"
                                                               #roleMenuTrigger="matMenuTrigger"
                                                               [matMenuTriggerData]="{roles: roleOptions}"
                                                               (click)="openRoleMenu($event, user)">
                                                                <mat-icon color="primary">key</mat-icon>
                                                                Rôles
                                                            </a>

                                                            <mat-menu #roleMenu="matMenu" (closed)="onCancelRoleMenu()"
                                                                      [id]="user.userId">
                                                                <ng-template matMenuContent let-roles="roles">
                                                                    <mat-selection-list [(ngModel)]="selectedRoles">
                                                                        <mat-list-option *ngFor="let role of roles"
                                                                                         [value]="role.value"
                                                                                         (click)="$event.stopPropagation()">
                                                                            {{ role.display }}
                                                                        </mat-list-option>
                                                                    </mat-selection-list>

                                                                    <hr>
                                                                    <div class="d-flex justify-content-center align-items-center">
                                                                        <button mat-raised-button class="align-self-center primary"
                                                                                (click)="onUpdateRoles($event, index, user)">
                                                                            <mat-icon>check_circle</mat-icon>
                                                                            Valider
                                                                        </button>
                                                                    </div>
                                                                </ng-template>
                                                            </mat-menu>

                                                            <mat-menu #hotelRoleMenu="matMenu"
                                                                      (closed)="onCancelHotelMenu()"
                                                                      [overlapTrigger]="true">
                                                                <ng-template matMenuContent let-hotels="hotels">
                                                                    <mat-selection-list [(ngModel)]="selectedHotels">
                                                                        <mat-list-option *ngFor="let role of hotelOptions"
                                                                                         [value]="role.value"
                                                                                         (click)="$event.stopPropagation()">
                                                                            {{ role.display }}
                                                                        </mat-list-option>
                                                                    </mat-selection-list>

                                                                    <hr>
                                                                    <div class="d-flex justify-content-center align-items-center">
                                                                        <button mat-raised-button class="align-self-center w-full primary"
                                                                                (click)="onUpdateUserHotels($event, index, user)">
                                                                            <mat-icon>check_circle</mat-icon>
                                                                            Valider
                                                                        </button>
                                                                    </div>
                                                                </ng-template>
                                                            </mat-menu>

                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-0 align-items-center ">
                    <div class="col-sm-6">
                        <div>
                            <p class="mb-sm-0">Affichage de {{ userPage.page + 1 }} à {{ userPage.content.length }}
                                sur {{ userPage.totalRows }} entries</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="float-sm-end">
                            <mat-paginator [length]="userPage.totalRows"
                                           [pageSize]="userPage.page"
                                           [pageSizeOptions]="[10, 20, 30, 40]"
                                           (page)="handlePageEvent($event)"
                                           [showFirstLastButtons]="true">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>



