import { Component, OnInit, QueryList, ViewChild } from "@angular/core";
import { UserService } from "../../../../services/user.service";
import { FilterOption, PageableModel, UserApiResponse, UserProfileResponse } from "@app/model";
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { NewUserDialogComponent } from "../dialog/new-user-dialog/new-user-dialog.component";
import { DialogService } from "../../../../services/dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DEFAULT_USER_PROFILE_PICTURE, getPictureUrlFromByteArray } from "../../../../utils/file.utils";
import { RoleModel } from "../../../../model/role.model";
import { MatMenuTrigger } from "@angular/material/menu";
import { AuthService } from "@frontend/src/app/services/auth.service";
import { HotelService } from "@frontend/src/app/services/hotel.service";


@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrl: "./users.component.scss"
})
export class UsersComponent implements OnInit {
	get currentUser(): UserProfileResponse {
		return this._currentUser;
	}

	public userPage: PageableModel<UserApiResponse>;
	public roles: RoleModel[] = [];
	public roleOptions: FilterOption[] = [];
	public hotelOptions: FilterOption[] = [];
	private currentPage: number = 0;
	public selectedRoles: number[] = [];
	public selectedHotels: number[] = [];

    @ViewChild("roleMenuTrigger") roleMenuTrigger: MatMenuTrigger;
    @ViewChild("hotelMenuTrigger") hotelMenuTrigger: QueryList<MatMenuTrigger>;

    public readonly DEFAULT_USER_PROFILE_PICTURE = DEFAULT_USER_PROFILE_PICTURE;
    private _currentUser: UserProfileResponse;

    constructor(private userService: UserService,
                private authService: AuthService,
                private hotelService: HotelService,
                private dialogService: DialogService,
                private matDialog: MatDialog,
                private matSnackBar: MatSnackBar,
                private domSanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
    	this.userService.getPageableUsers()
    		.subscribe(_roomPage => {
    			this.currentPage = 0;
    			this.userPage = _roomPage;
    		});

    	this.authService.getCurrentUserFromApi()
    		.subscribe(_user => this._currentUser = _user);

    	this.userService.getRoleSubs().subscribe({
    		next: (roles) => {
    			this.roleOptions = roles.map(item => ({ value: item.roleId, display: item.name }));
    		}
    	});

    	this.hotelService.getHotelsSub().subscribe(_hotels => this.hotelOptions = _hotels.map(item => ({
    		display: item.name,
    		value: item.hotelId
    	})));
    }

    private goToPage(page: number) {
    	this.currentPage = page;
    	this.userService.getPageableUsers(page)
    		.subscribe(_userPage => this.userPage = _userPage);
    }

    public handlePageEvent($event: PageEvent) {
    	this.goToPage($event.pageIndex);
    }

    public getHotelNames(customer: UserApiResponse): string {
    	return customer.hotels.map(value => value.name).join(", ") || "";
    }

    public getRoleNames(customer: UserApiResponse): string {
    	return customer.roles.map((value) => value.name).join(", ") || "";
    }

    public onOpenNewUserDialog() {
    	this.matDialog.open(NewUserDialogComponent, {
    		width: "700px"
    	}).afterClosed().subscribe({
    		next: (response: UserApiResponse) => {
    			if (response) {
    				this.currentPage = 0;
    				this.goToPage(this.currentPage);
    			}
    		}
    	});
    }

    public onOpenEditDialog(user: UserApiResponse) {
    	this.matDialog.open(NewUserDialogComponent, {
    		data: user,
    		width: "700px"
    	}).afterClosed().subscribe((response: UserApiResponse) => {
    		if (response) {
    			this.userPage.content = this.userPage.content
    				.map(item => item.userId === user.userId ? user : item);
    		}
    	});
    }

    public changeAccountState(user: UserApiResponse) {
    	this.dialogService.openConfirmDialog(`Voulez-vous ${ user.isActif ? "Désactiver " : "Désactiver " } ce compte utilisateur : \n ${ user.email } ?`)
    		.afterClosed().subscribe((state: boolean) => {
    			if (state) {
    				this.userService.updateAccountState(user.userId, { state: !user.isActif }).subscribe({
    					next: () => {
    						this.showSuccessMessage("Statut du compte mis à jour");

    						user.isActif = !user.isActif;
    						this.userPage.content = this.userPage.content
    							.map(item => item.userId === user.userId ? user : item);
    					},
    					error: (err) => {
    						this.showError(err.error.message);
    					}
    				});
    			}
    		});
    }

    public getImageData(binary: number[]): SafeUrl {
    	return getPictureUrlFromByteArray(binary, this.domSanitizer);
    }

    private getSelectedRoleOptions(user: UserApiResponse): number[] {
    	return user.roles.map(item => item.roleId) || [];
    }

    public openRoleMenu($event: MouseEvent, user: UserApiResponse) {
    	this.selectedRoles = this.getSelectedRoleOptions(user);
    }

    public openHotelRoleMenu($event: MouseEvent, user: UserApiResponse) {
    	this.selectedHotels = user.hotels.map(item => item.hotelId) || [];
    }

    public onUpdateRoles($event: MouseEvent, index: number, user: UserApiResponse) {
    	this.userService.updateUserRoles(user.userId, this.selectedRoles)
    		.subscribe({
    			next: (_user: UserApiResponse) => {
    				if (_user) {
    					this.showSuccessMessage("Rôles mis à jour");
    					this.userPage.content[index].roles = _user.roles || [];
    					this.selectedRoles = [];
    				}
    			},
    			error: (err) => {
    				this.showError(err.error.message);
    				$event.stopPropagation();
    			}
    		});
    }

    public onUpdateUserHotels($event: MouseEvent, index: number, user: UserApiResponse) {
    	this.userService.updateUserOrgUnits(user.userId, this.selectedHotels)
    		.subscribe({
    			next: (_user: UserApiResponse) => {
    				if (_user) {
    					this.userPage.content[index].roles = _user.roles || [];
    					this.selectedRoles = [];
    					this.showSuccessMessage("Rôles mis à jour");
    				}
    			},
    			error: (err) => {
    				this.showError(err.error.message);
    				$event.stopPropagation();
    			}
    		});
    }

    public onCancelRoleMenu() {
    	this.selectedRoles = [];
    	this.roleMenuTrigger = null;
    }

    public onCancelHotelMenu() {
    	this.selectedHotels = [];
    	this.hotelMenuTrigger = null;
    }


    private showError(errorMessage: string) {
    	this.matSnackBar.open(errorMessage, undefined, {
    		duration: 3000,
    		panelClass: "snack-bar-error"
    	});
    }

    private showSuccessMessage(message: string) {
    	this.matSnackBar.open(message, undefined, {
    		duration: 3000,
    		panelClass: "snack-bar-success"
    	});
    }
}
