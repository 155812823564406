import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor, HttpResponse, HttpErrorResponse
} from "@angular/common/http";
import { BehaviorSubject, catchError, Observable, switchMap, throwError } from "rxjs";
import { Key } from "../enum/key.enum";
import { AuthService } from "../services/auth.service";
import { AuthResponseModel } from "@app/model";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private isTokenRefreshing: boolean = false;
	private refreshTokenSubject: BehaviorSubject<AuthResponseModel> = new BehaviorSubject<AuthResponseModel>(null);

	constructor(private authService: AuthService) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | Observable<HttpResponse<unknown>> {
		if (request.url.includes("verify") || request.url.includes("login") || request.url.includes("register")
            || request.url.includes("refresh") || request.url.includes("resetPassword")) {
			return next.handle(request);
		}
		return next.handle(this.addAuthorizationHeaderToken(request, localStorage.getItem(Key.TOKEN)))
			.pipe(
				catchError((error: HttpErrorResponse) => {
					if (error instanceof HttpErrorResponse && error.status === 401 && (error?.error?.message?.includes("expired"))) {
						return this.handleRefreshToken(request, next);
					} else {
						return throwError(() => error);
					}
				})
			);
	}

	// @ts-ignore
	private handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.isTokenRefreshing) {
			this.isTokenRefreshing = true;
			this.refreshTokenSubject.next(null);
			return this.authService.refreshToken$().pipe(
				switchMap((response) => {
					console.log("token refresh response", response);
					this.isTokenRefreshing = false;
					this.refreshTokenSubject.next(response);
					console.log("sending original request: ", request);
					return next.handle(this.addAuthorizationHeaderToken(request, response.accessToken));
				})
			);
		} else {
			this.refreshTokenSubject.pipe(
				switchMap((response) => {
					return next.handle(this.addAuthorizationHeaderToken(request, response.accessToken));
				})
			);
		}
	}

	private addAuthorizationHeaderToken(request: HttpRequest<unknown>, token: string): HttpRequest<any> {
		return request.clone({ setHeaders: { Authorization: `Bearer ${ token }` } });
	}
}
