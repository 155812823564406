import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { RoleModel } from "../model/role.model";
import {
	BinaryObject,
	PageableModel,
	UserApiResponse,
	UserProfileResponse,
	UserRequestModel
} from "@app/model";

@Injectable({
	providedIn: "root"
})
export class UserService {

	private readonly API_URL: string = environment.backendUrl;

	private roles: RoleModel[] = [];

	private roleSubs: ReplaySubject<RoleModel[]> = new ReplaySubject<RoleModel[]>(1);

	constructor(private httpClient: HttpClient) {
		this.getAllRoles();
	}

	public getPageableUsers(pageNumber: number = 0, pageSize: number = 10): Observable<PageableModel<UserApiResponse>> {
		return this.httpClient.get<PageableModel<UserApiResponse>>(this.API_URL + "/api/v1/users/all", {
			params: {
				pageNumber,
				pageSize
			}
		});
	}

	private getAllRoles() {
		this.httpClient.get<RoleModel[]>(this.API_URL + "/api/v1/users/all_roles")
			.subscribe(response => {
				this.roles = response;
				this.roleSubs.next(this.roles);
			});
	}

	public saveUser(user: UserRequestModel): Observable<UserApiResponse> {
		return this.httpClient.post<UserApiResponse>(this.API_URL + "/api/v1/users", user);
	}

	public updateUser(userId: number, user: UserRequestModel): Observable<UserApiResponse> {
		return this.httpClient.put<UserApiResponse>(this.API_URL + "/api/v1/users/" + userId, user);
	}

	public update$ = (userId: number, user: UserRequestModel) => <Observable<UserProfileResponse>>
        this.httpClient.patch<UserProfileResponse>(`${ this.API_URL }/api/v1/users/profile/${ userId }/update`, user);

	public updateUserRoles(userId: number, data: number[]): Observable<UserApiResponse> {
		return this.httpClient.put<UserApiResponse>(`${ this.API_URL }/api/v1/users/${ userId }/update_roles`, { data });
	}

	public updateUserOrgUnits(userId: number, data: number[]): Observable<UserApiResponse> {
		return this.httpClient.put<UserApiResponse>(`${ this.API_URL }/api/v1/users/${ userId }/update_orgunits`, { data });
	}

	public getRoleSubs(): Observable<RoleModel[]> {
		return this.roleSubs.asObservable();
	}

	public updateAccountState(userId: number, data: { state: boolean }): Observable<any> {
		return this.httpClient.put(`${ this.API_URL }/api/v1/users/${ userId }/update_state`, data);
	}

	public updateProfileImage$ = (userId: number, file: File): Observable<BinaryObject> => {
		const formData = new FormData();
		formData.append("file", file);
		return this.httpClient.put<BinaryObject>(`${ this.API_URL }/api/v1/users/${ userId }/image_upload`, formData);
	};
}
