import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { LoginPayload } from "../model/login.payload";
import { LoginResponse } from "../model/login.response";
import { catchError, Observable, of, ReplaySubject, tap } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { ChangePasswordPayload } from "../model/change-password.payload";
import { PasswordForgetPaylaod } from "../model/password-forget.paylaod";
import { AuthResponseModel, UserProfileResponse } from "@app/model";
import { Key } from "../enum/key.enum";
import { handleError } from "../utils/http.utils";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService {

	private readonly API_URL: string = environment.backendUrl;
	private _currentUserSub = new ReplaySubject<UserProfileResponse | null>(1);
	private _currentUser: UserProfileResponse | null = null;
	private isUserLoaded = false;

	private readonly jwtHelper = new JwtHelperService();

	constructor(private httpClient: HttpClient,
                private cookieService: CookieService) {
		this.checkAndRetrieveUser();
	}

	private checkAndRetrieveUser() {
		if (!this.isUserLoaded && this.isCookiePresent()) {
			this.getCurrentUserInfo();
		}
	}

	public getCurrentUserInfo() {
		this.getCurrentUserFromApi();
	}

	public getCurrentUserFromApi(): Observable<UserProfileResponse> {
		if (!this._currentUser)
			return this.httpClient.get<UserProfileResponse>(this.API_URL + "/api/v1/auth/users/me")
				.pipe(tap(response => {
					this._currentUser = response;
					this._currentUserSub.next(response);
					document.documentElement.style.setProperty("--primary-color", response.color);
					return response;
				}));
		else
			return of(this._currentUser);
	}

	public login(loginPayload: LoginPayload): Observable<LoginResponse> {
		return this.httpClient.post<LoginResponse>(`${ this.API_URL }/api/v1/auth/login`, loginPayload)
			.pipe(tap(response => {
				if (response.type === "USER_AUTH") {
					localStorage.setItem(Key.TOKEN, response.accessToken);
					localStorage.setItem(Key.REFRESH_TOKEN, response.refreshToken);
					this.getCurrentUserInfo();
				}
			}));
	}


	public logout() {
		this._currentUser = null;
		this._currentUserSub.next(null);
		this.cookieService.delete(environment.cookieName, "/");
		localStorage.removeItem(Key.TOKEN);
		localStorage.removeItem(Key.REFRESH_TOKEN);
		localStorage.removeItem(environment.hotelId);
		window.location.reload();
	}

	public isCookiePresent(): boolean {
		return !!localStorage.getItem(Key.TOKEN);
	}

	public getCurrentUserObs(): Observable<UserProfileResponse> {
		return this._currentUserSub.asObservable();
	}

	public updatePassword(payload: ChangePasswordPayload): Observable<any> {
		return this.httpClient.post(`${ this.API_URL }/api/v1/auth/password/update`, payload);
	}

	public resetPassword(passwordResetPayload: PasswordForgetPaylaod) {
		return this.httpClient.post(`${ this.API_URL }/api/v1/auth/password/forgot`, passwordResetPayload);
	}

	public refreshToken$ = () => <Observable<AuthResponseModel>>
        this.httpClient.post<AuthResponseModel>(`${ this.API_URL }/api/v1/auth/refresh`, {}, { headers: { "x-refresh": `Bearer ${ localStorage.getItem(Key.REFRESH_TOKEN) }` } })
        	.pipe(
        		tap((response) => {
        			localStorage.removeItem(Key.TOKEN);
        			localStorage.setItem(Key.TOKEN, response.accessToken);
        		}),
        		catchError((err) => {
        			this.logout();
        			return handleError(err);
        		})
        	);

	public isAuthenticated = (): boolean => {
		const token = localStorage.getItem(Key.TOKEN);
		if (!token)
			return false;

		return (!!this.jwtHelper.decodeToken<string>(token)
			&& !this.jwtHelper.isTokenExpired(localStorage.getItem(Key.TOKEN)));
	};


	/************************ Getter and Setter section ****************/
	get currentUser(): UserProfileResponse | null {
		return this._currentUser;
	}
}
