<div class="calendar-navigation">
    <app-calendar-header [viewDate]="viewDate"
                         [currentView]="currentView"
                         [isLoading]="isLoading"
                         (viewDateChange)="onUpdateDate($event)"
                         (currentViewChange)="onChangeView($event)">
    </app-calendar-header>
</div>

<div class="planning-container">

    <div class="sidebar">

        <div class="card text-white bg-primary mb-3">
            <div class="card-header d-flex justify-content-center"> RECETTE TOTAL</div>
            <div class="card-body d-flex justify-content-center">
                <h1 class="card-title card-value">{{ totalAmount | number }}</h1>
            </div>
        </div>

        <div class="card text-white bg-success mb-3">
            <div class="card-header d-flex justify-content-center">NOMBRE DE VISITEURS</div>
            <div class="card-body d-flex justify-content-center">
                <h1 class="card-title card-value">{{ distinctCustomers }}</h1>
            </div>
        </div>

        <div class="card text-white bg-info mb-3" *ngIf="distinctRooms">
            <div class="card-header d-flex justify-content-center">HÉBERGEMENTS OCCUPÉS</div>
            <div class="card-body d-flex justify-content-center">
                <p class="card-title card-value">{{ distinctRooms }}</p>
            </div>
        </div>

        <div class="card text-white bg-info mb-3" *ngIf="newCustomers?.length">
            <div class="card-header d-flex justify-content-center">NOUVEAUX VISITEURS</div>
            <div class="card-body d-flex justify-content-center">
                <p class="card-title card-value">{{ newCustomers.length }}</p>
            </div>
        </div>

    </div>

    <div class="data-container">

        <div class="data-box">
            <div class="card mb-3">
                <div class="card-header primary">Courbe des recettes</div>
                <div class="card-body" *ngIf="reservations?.length">
                    <div echarts [options]="lineChartOption" class="chart"></div>
                </div>
            </div>
        </div>

        <div class="data-box">
            <div class="card mb-3">
                <div class="card-header primary">Histogramme des recettes</div>
                <div class="card-body" *ngIf="reservations?.length">
                    <div echarts [options]="barChartOption" class="chart"></div>
                </div>
            </div>
        </div>

        <div class="data-box">
            <!--                <div class="card mb-3">-->
            <!--                    <div class="card-header primary">Pie Chart</div>-->
            <!--                    <div class="card-body" *ngIf="reservations?.length">-->
            <!--                        <div echarts [options]="pieChartOption" class="chart"></div>-->
            <!--                    </div>-->
            <!--                </div>-->

            <div class="card">
                <div class="card-header primary">Liste des nouveaux clients</div>
                <app-customer-table
                        [customers]="newCustomers">
                </app-customer-table>
            </div>
        </div>

        <div class="data-box">
            <div class="mt-1">
                <app-reservation-table [reservations]="reservations"
                                       [tableTitle]="reportTitle"
                                       [textWrapSize]="15"
                                       (exportEvent)="onExport()">
                </app-reservation-table>
            </div>
        </div>
    </div>

</div>