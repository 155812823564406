import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";
import { Observable, ReplaySubject, tap } from "rxjs";
import { ComputeHostingPlanModel } from "../model/compute-hosting-plan.model";
import { HostingResponseModel } from "@app/model";
import { BookingUpdateRequestModel } from "../model/booking.update.request.model";
import { NewBookingForm } from "../forms/booking.form";

@Injectable({
	providedIn: "root"
})
export class HostingService {
	private readonly API_URL: string = environment.backendUrl;
	private hostings: HostingResponseModel[] = [];
	private hostingSubs: ReplaySubject<HostingResponseModel[]> = new ReplaySubject<HostingResponseModel[]>(1);

	constructor(private httpClient: HttpClient) {
		this.getAllHosting();
	}

	private getAllHosting() {
		this.httpClient.get<HostingResponseModel[]>(this.API_URL + "/api/v1/hosting")
			.subscribe(response => {
				this.hostings = response;
				this.hostingSubs.next(this.hostings);
			});
	}

	public saveHosting(payload: NewBookingForm["value"]): Observable<HostingResponseModel> {
		return this.httpClient.post<HostingResponseModel>(this.API_URL + "/api/v1/hosting", payload)
			.pipe(tap(response => {
				this.hostings.push(response);
				this.hostingSubs.next(this.hostings);
			}));
	}

	public getHostingById(hostingId: number): Observable<HostingResponseModel> {
		return this.httpClient.get<HostingResponseModel>(`${ this.API_URL }/api/v1/hosting/${ hostingId }`);
	}

	public deleteHosting(reservationId: number): Observable<any> {
		return this.httpClient.delete(`${ this.API_URL }/api/v1/hosting/${ reservationId }`);
	}

	public confirmBooking(reservationId: number, payload: BookingUpdateRequestModel): Observable<HostingResponseModel> {
		return this.httpClient.put<HostingResponseModel>(`${ this.API_URL }/api/v1/hosting/${ reservationId }/confirm`, payload);
	}

	public cancelBooking(reservationId: number): Observable<any> {
		return this.httpClient.put(`${ this.API_URL }/api/v1/hosting/${ reservationId }/cancel`, {});
	}

	public getHostingSubs(): Observable<HostingResponseModel[]> {
		return this.hostingSubs.asObservable();
	}

	public computeHostingPlan(payload: ComputeHostingPlanModel): Observable<ComputeHostingPlanModel> {
		return this.httpClient.post<ComputeHostingPlanModel>(this.API_URL + "/api/v1/hosting/compute_plan", payload);
	}
}
