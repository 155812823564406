import { NgModule } from "@angular/core";
import { AuthRoutingModule } from "./auth-routing.module";
import { SharedModule } from "../../shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { SelectHotelDialogComponent } from "./dialog/select-hotel-dialog/select-hotel-dialog.component";
import { ResetPasswordDialogComponent } from "./dialog/reset-password-dialog/reset-password-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { HttpClientModule } from "@angular/common/http";
import { AppMaterialModule } from "../../app-material.module";


@NgModule({
	declarations: [
		LoginComponent,
		SelectHotelDialogComponent,
		ResetPasswordDialogComponent
	],
	imports: [
		SharedModule,
		AuthRoutingModule,
		MatInputModule,
		MatDialogModule,
		MatButtonModule,
		HttpClientModule,
		AppMaterialModule
	]
})
export class AuthModule {
}
