import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HostingResponseModel } from "@app/model";
import { nameFormatter } from "@app/util";

@Component({
	selector: "app-reservation-table",
	templateUrl: "./reservation-table.component.html",
	styleUrl: "./reservation-table.component.scss",
})
export class ReservationTableComponent {

	public readonly nameFormatter = nameFormatter;

	private _reservations: HostingResponseModel[];

    @Output()
    	exportEvent = new EventEmitter<void>();

    @Input()
    public tableTitle: string;

    @Input({ required: true })
    set reservations(data: HostingResponseModel[]) {
    	this._reservations = data?.sort((a, b) => {
    		return new Date(a.beginingDate).getTime() - new Date(b.beginingDate).getTime();
    	});
    }

    @Input()
    public textWrapSize = 25;

    get reservations(): HostingResponseModel[] {
    	return this._reservations;
    }

    public handleExport() {
    	this.exportEvent.emit();
    }
}
