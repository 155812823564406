import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LogementRequest } from "../model/logement.request";
import {
	LogementApiResponse,
	LogementTypeEnum,
	PageableModel,
	RoomPictureApiResponse
} from "@app/model";

@Injectable({
	providedIn: "root"
})
export class RoomService {

	private readonly API_URL: string = environment.backendUrl;

	constructor(private httpClient: HttpClient) {
	}

	public getPageableRooms(pageNumber: number = 0, pageSize: number = 12,
		type?: LogementTypeEnum | undefined,
		level_id?: number | undefined,
		code?: string): Observable<PageableModel<LogementApiResponse>> {
		let param = new HttpParams();
		param = param.set("pageNumber", pageNumber);
		param = param.set("pageSize", pageSize);

		if (type)
			param = param.set("type", type);

		if (level_id)
			param = param.set("level_id", Number(level_id));

		if (code)
			param = param.set("code", code);

		return this.httpClient.get<PageableModel<LogementApiResponse>>(`${ this.API_URL }/api/v1/logement/pageable`, {
			params: param
		});
	}

	public getAllFreeLogement(): Observable<LogementApiResponse[]> {
		return this.httpClient.get<LogementApiResponse[]>(`${ this.API_URL }/api/v1/logements/frees`);
	}

	public getAllFreesLogement(start: string, end: string, typeLogement: string): Observable<LogementApiResponse[]> {
		const params = new HttpParams().set("start", start).set("end", end).set("logement_type", typeLogement);
		return this.httpClient.get<LogementApiResponse[]>(`${ this.API_URL }/api/v1/logements/search_frees`, { params });
	}

	public getRoomById(roomId: number): Observable<LogementApiResponse> {
		return this.httpClient.get<LogementApiResponse>(`${ this.API_URL }/api/v1/logement/${ roomId }`);
	}

	public getRoomPictures(roomId: number): Observable<RoomPictureApiResponse[]> {
		return this.httpClient.get<RoomPictureApiResponse[]>(`${ this.API_URL }/api/v1/logement/${ roomId }/pictures`);
	}

	/******************************* Post Section *************************************************/

	public saveLogement(logementRequestPayload: LogementRequest): Observable<LogementApiResponse> {
		return this.httpClient.post<LogementApiResponse>(this.API_URL + "/api/v1/logement", logementRequestPayload);
	}

	/*********************************** Put Section ****************************************************/
	public updateLogement(logementId: number, payload: LogementRequest): Observable<LogementApiResponse> {
		return this.httpClient.put<LogementApiResponse>(`${ this.API_URL }/api/v1/logement/${ logementId }`, payload);
	}

	public uploadRoomImage(roomId: number, file: File, type: "cover" | "picture"): Observable<RoomPictureApiResponse> {
		const formData = new FormData();
		formData.append("file", file);
		return this.httpClient.put<RoomPictureApiResponse>(`${ this.API_URL }/api/v1/logement/${ roomId }/add_picture`, formData, {
			params: { type }
		});
	}

	/******************************************** Delete Section **********************************/

	public deleteLogement(logementId: number) {
		return this.httpClient.delete(`${ this.API_URL }/api/v1/logement/${ logementId }`);
	}

	public deleteRoomPicture(pictureId: string) {
		return this.httpClient.delete(`${ this.API_URL }/api/v1/logement/picture/${ pictureId }`, { responseType: "text" });
	}
}
