import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FeatureComponent } from "./feature.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ReservationComponent } from "./pages/reservation/reservation.component";
import { AuthenticationGuard } from "../../guard/auth.guard";
import { CustomersComponent } from "./pages/customers/customers.component";
import { ReservationDetailComponent } from "./pages/reservation/reservation-detail/reservation-detail.component";
import { RoomsComponent } from "./pages/rooms/rooms.component";
import { RoomDetailComponent } from "./pages/room-detail/room-detail.component";
import { HotelConfigurationComponent } from "./pages/hotel-configuration/hotel-configuration.component";
import { UsersComponent } from "./pages/users/users.component";
import { UserDetailsComponent } from "./pages/users/user-details/user-details.component";
import {
	ReservationCalendarComponent
} from "@frontend/src/app/components/feature/pages/reservation-calendar/reservation-calendar.component";

const featureRoutes: Routes = [
	{
		path: "", component: FeatureComponent, children: [
			{ path: "", component: ReservationCalendarComponent, canActivate: [AuthenticationGuard] },
			{
				path: "dashboard",
				component: DashboardComponent,
				canActivate: [AuthenticationGuard],
				data: { roles: ["ADMIN", "SUPERADMIN"] }
			},
			{ path: "planning", component: ReservationCalendarComponent, canActivate: [AuthenticationGuard] },
			{ path: "reservation", component: ReservationComponent, canActivate: [AuthenticationGuard] },
			{ path: "reservation/:id", component: ReservationDetailComponent, canActivate: [AuthenticationGuard] },
			{ path: "customers", component: CustomersComponent, canActivate: [AuthenticationGuard] },
			{ path: "rooms", component: RoomsComponent, canActivate: [AuthenticationGuard] },
			{ path: "rooms/:id", component: RoomDetailComponent, canActivate: [AuthenticationGuard] },
			{
				path: "hotel-config",
				component: HotelConfigurationComponent,
				canActivate: [AuthenticationGuard],
				data: { roles: ["ADMIN", "SUPERADMIN"] }
			},
			{
				path: "users",
				component: UsersComponent,
				canActivate: [AuthenticationGuard],
				data: { roles: ["ADMIN", "SUPERADMIN"] }
			},
			{ path: "user-profile", component: UserDetailsComponent, canActivate: [AuthenticationGuard] },
		]
	}
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forChild(featureRoutes)
	],
	exports: [RouterModule]
})
export class FeatureRoutingModule {
}
