export function util(): string {
	return "util";
}

export function nameFormatter(firstName: string, lastName: string): string {
	return lastName.toUpperCase()
		.concat(" " + firstName.split(" ").map(item=> capitalizeString(item)).join(" "));
}

function capitalizeString(value: string) {
	return `${value.charAt(0).toUpperCase()}${value.substring(1).toLowerCase()}`;
}

export function toTitleCase(str: string): string {
	return str.replace(
		/\w\S*/g,
		txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
	);
}