<div class="dialog-title">
    <div class="title">
        <h1 mat-dialog-title *ngIf="data; else userTitleTemplate"> Modifier un utilisateur </h1>
        <ng-template #userTitleTemplate>
            <h1 mat-dialog-title> Ajouter un utilisateur </h1>
        </ng-template>
    </div>

    <div class="close">
        <button [mat-dialog-close]="false" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="onSaveUser()">

    <div mat-dialog-content>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Nom</mat-label>
                <input matInput id="lastname" [type]="'text'"
                       formControlName="lastName"
                       placeholder="Entrer le nom">

                <mat-hint class="text-danger"
                          *ngIf="f.lastName?.touched && !f.lastName?.valid">
                    Le nom est obligatoire
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Prénom</mat-label>
                <input matInput id="firstname" [type]="'text'"
                       formControlName="firstName"
                       placeholder="Enter le prénom">

                <mat-hint class="text-danger"
                          *ngIf="f.firstName?.touched && !f.firstName?.valid">
                    Le prénom est obligatoire
                </mat-hint>
            </mat-form-field>

        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Nom d'utilisateur</mat-label>
                <input matInput id="username" [type]="'text'"
                       formControlName="username"
                       placeholder="Entrer le nom d'utilisateur">

                <mat-hint class="text-danger"
                          *ngIf="f.username?.touched && !f.username?.valid">
                    Veuillez saisir un nom d'utilisateur
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput id="email" [type]="'email'"
                       placeholder="Entrer l'email" formControlName="email">

                <mat-hint class="text-danger"
                          *ngIf="f.email?.touched && f.email?.hasError('required')">
                    L'email est obligatoire
                </mat-hint>
                <mat-hint class="text-danger"
                          *ngIf="f.email?.touched && f.email?.hasError('email')">
                    Veuillez saisir un email correct
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="row" *ngIf="!data">
            <div class="col-md-6">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Mot de passe</mat-label>
                        <input matInput id="password" [type]="hide ? 'password' : 'text'"
                               placeholder="Minimum 6 caractères"
                               formControlName="password">
                        <button mat-icon-button matSuffix type="button"
                                (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-hint class="text-danger"
                                  *ngIf="f.password?.touched && f.password?.hasError('required')">
                            Mot de passe obligatoire
                        </mat-hint>

                        <mat-hint class="text-danger"
                                  *ngIf="f.password?.touched && f.password?.hasError('minlength')">
                            Le mot de passe a au minimum 6 caractères
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Confirmation password</mat-label>
                        <input matInput id="confirm-password" [type]="hide ? 'password' : 'text'"
                               placeholder="Minimum 6 caractères"
                               formControlName="passwordConfirmation">
                        <button mat-icon-button matSuffix type="button"
                                (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-hint class="text-danger"
                                  *ngIf="f.passwordConfirmation?.touched && f.passwordConfirmation?.hasError('required')">
                            Confirmer le mot de passe
                        </mat-hint>

                        <mat-hint class="text-danger"
                                  *ngIf="f.passwordConfirmation?.touched && f.passwordConfirmation?.hasError('minlength')">
                            Le mot de passe a au minimum 6 caractères
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Roles</mat-label>
                        <mat-select formControlName="roles" multiple>
                            <mat-select-trigger>
                                {{ getRoleName(f.roles.value?.[0]) || '' }}
                                <span *ngIf="(f.roles.value?.length || 0) > 1" class="mat-trigger-selection">
                                    (+{{ (f.roles.value?.length || 0) - 1 }} {{ f.roles.value?.length === 2 ? 'autre' : 'autres' }})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let role of roles" [value]="role.roleId">
                                {{ role.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Hotels</mat-label>
                        <mat-select formControlName="hotels" multiple>
                            <mat-select-trigger>
                                {{ f?.hotels?.value?.[0] ? (getHotelName(f.hotels?.value?.[0])) : '' }}
                                <span *ngIf="(f.hotels?.value?.length || 0) > 1" class="mat-trigger-selection">
                            (+{{ (f.hotels.value?.length || 0) - 1 }} {{ f.hotels.value?.length === 2 ? 'autre' : 'autres' }}
                                    )
                            </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let hotel of hotels" [value]="hotel.hotelId">
                                {{ hotel.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="form-actions" align="center">
        <button type="reset" mat-raised-button color="warn"
                [disabled]="true">
            <mat-icon>restart_alt</mat-icon>
            Réinitialiser
        </button>

        <button mat-raised-button class="save-btn"
                [disabled]="userForm.invalid" (click)="onSaveUser()">
            <mat-icon>save</mat-icon>
            Enregistrer
        </button>
    </div>
</form>