<div class="container mt-2" *ngIf="hotel && reservation">
    <button mat-raised-button color="primary" (click)="exportAsPDF()">
        <mat-icon>print</mat-icon>
        Imprimer
    </button>
    <div class="row mt-1">
        <div class="col-lg-12">
            <div class="card" id="invoice">
                <div class="card-body">
                    <div class="invoice-title">
                        <h4 class="float-end font-size-15">Facture : {{ reservation.billNumber }}</h4>
                        <div class="mb-2">
                            <h2 class="text-muted">{{ hotel.name }}</h2>
                        </div>
                        <div class="text-muted">
                            <p class="d-flex align-items-center gap-2" *ngIf="hotel?.address">
                                {{ hotel.address }}
                            </p>
                            <p class="d-flex align-items-center gap-2"> {{ hotel.email }}</p>
                            <p class="d-flex align-items-center gap-2"> {{ hotel.contact }}</p>
                        </div>
                    </div>

                    <hr class="my-2">

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="text-muted">
                                <h5 class="font-size-16 mb-3">Client :</h5>
                                <h5 class="font-size-15 mb-2">
                                    {{ (reservation.customer.lastName | uppercase) + ' ' + (reservation.customer.firstName | uppercase) }}
                                </h5>
                                <p class="mb-1" *ngIf="reservation.customer?.address">
                                    {{ reservation.customer.address }}
                                </p>
                                <p class="mb-1"> {{ reservation.customer.email }} </p>
                                <p> {{ reservation.customer.contact }}</p>
                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-sm-6">
                            <div class="text-muted text-sm-end">
                                <div class="mt-2">
                                    <h5 class="font-size-15 mb-1">Date d'émission</h5>
                                    <p>{{ reservation.confirmationDate | date : 'dd MMM, yyyy' }}</p>
                                </div>
                                <div class="mt-2">
                                    <h5 class="font-size-15 mb-1">Réservation N °</h5>
                                    <p> {{ reservation.reservationCode }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <div class="py-1">
                        <h5 class="font-size-15">Détail de la facture</h5>

                        <div class="table-responsive">
                            <table class="table align-middle table-nowrap table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="width: 70px;">No.</th>
                                        <th>Chambre / Logement</th>
                                        <th>Prix</th>
                                        <th>
                                            {{ reservation.occupationMode === "SIESTE" ? "Sieste" : "Nuitées" }}
                                        </th>
                                        <th class="text-end" style="width: 120px;">Total</th>
                                    </tr>
                                </thead>
                                <!-- end thead -->
                                <tbody>
                                    <tr>
                                        <th scope="row">01</th>
                                        <td>
                                            <div>
                                                <h5 class="text-truncate font-bold mb-1">
                                                    {{ reservation.logement.code }} / {{ reservation.logement.name }}
                                                </h5>
                                                <p class="text-muted mb-0">
                                                    De {{ reservation.beginingDate | date : 'dd MMM, yyyy, hh:mm:ss' }}
                                                    À {{ reservation.endingDate | date : 'dd MMM, yyyy, hh:mm:ss' }}
                                                </p>
                                            </div>
                                        </td>
                                        <td> {{ reservation.unitPrice | number }}</td>
                                        <td> {{ reservation.numberOfDays }}</td>
                                        <td class="text-end">
                                            {{ (reservation.unitPrice * reservation.numberOfDays) | number }}
                                        </td>
                                    </tr>

                                    <!-- end tr -->
                                    <tr>
                                        <th scope="row" colspan="4" class="text-end">Sous Total</th>
                                        <td class="text-end"> {{ reservation.amount | number }}</td>
                                    </tr>

                                    <!-- end tr -->
                                    <tr *ngIf="reservation.discountAmount">
                                        <th scope="row" colspan="4" class="border-0 text-end">
                                            Remise :
                                        </th>
                                        <td class="border-0 text-end">
                                            - {{ reservation.discountAmount | number }}
                                        </td>
                                    </tr>

                                    <tr *ngIf=" reservation.vtaAmount > 0">
                                        <th scope="row" colspan="4" class="border-0 text-end">
                                            TVA
                                        </th>
                                        <td class="border-0 text-end">
                                            {{ reservation.vtaAmount | number }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th scope="row" colspan="4" class="border-0 text-end">Total</th>
                                        <td class="border-0 text-end">
                                            <h4 class="m-0 fw-semibold"> {{ reservation.totalAmount | number }}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr class="my-2">

                    <div class="row d-flex flex-row">
                        <div class="col-md-6 d-flex flex-column ">
                            <h3>Signature du passager</h3>
                            <h3>{{ (reservation.customer.lastName | uppercase) +' '+ (reservation.customer.firstName | titlecase)  }}</h3>
                        </div>

                        <div class="col-md-6 d-flex flex-column align-items-end">
                            <h3>Le Réceptionniste</h3>

                            <h3 *ngIf="reservation?.user;">
                                {{ (reservation.user.lastName | uppercase) +' '+ (reservation.user.firstName | titlecase)  }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
</div>