import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject, tap } from "rxjs";
import { CustomerRequest } from "../model/customer.request";
import { CustomerResponse, PageableModel } from "@app/model";

@Injectable({
	providedIn: "root"
})
export class CustomerService {

	private readonly API_URL: string = environment.backendUrl;
	private customers!: CustomerResponse[];
	private customersSub = new ReplaySubject<CustomerResponse[]>(1);

	constructor(private httpClient: HttpClient) {
		this.getAllCustomers();
	}

	private getAllCustomers() {
		this.httpClient.get<CustomerResponse[]>(this.API_URL + "/api/v1/customer")
			.subscribe(response => {
				this.customers = response;
				this.customersSub.next(this.customers);
			});
	}

	public getPageableCustomers(pageNumber: number = 0, pageSize: number = 12, searchText?: string): Observable<PageableModel<CustomerResponse>> {
		let params = new HttpParams();
		params = params.set("pageNumber", pageNumber).set("pageSize", pageSize);
        
		if (searchText) {
			params = params.set("label", searchText);
		}

		return this.httpClient.get<PageableModel<CustomerResponse>>(this.API_URL + "/api/v1/customer/pageable", {
			params
		});
	}

	public createCustomer(payload: CustomerRequest): Observable<CustomerResponse> {
		return this.httpClient.post<CustomerResponse>(this.API_URL + "/api/v1/customer", payload)
			.pipe(tap(response => {
				this.customers.push(response);
				this.customersSub.next(this.customers);
			}));
	}

	public updateCustomer(customerId: number, payload: CustomerRequest): Observable<CustomerResponse> {
		return this.httpClient.put<CustomerResponse>(this.API_URL + "/api/v1/customer/" + customerId, payload)
			.pipe(tap(response => {
				const index = this.customers.findIndex(item => item.customerId === customerId);
				this.customers[index] = response;
				this.customersSub.next(this.customers);
			}));
	}

	public deleteCustomer(customerId: number): Observable<object> {
		return this.httpClient.delete(this.API_URL + "/api/v1/customer/" + customerId)
			.pipe(tap(() => {
				const index = this.customers.findIndex(item => item.customerId === customerId);
				this.customers.splice(index, 1);
				this.customersSub.next(this.customers);
			}));
	}

	public getCustomersSub(): Observable<CustomerResponse[]> {
		return this.customersSub.asObservable();
	}
}
