import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "../../../../../services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BookingStatusEnum, HostingResponseModel, UserProfileResponse } from "@app/model";
import { BookingUpdateRequestModel } from "../../../../../model/booking.update.request.model";
import { HostingService } from "../../../../../services/hosting.service";
import * as moment from "moment";
import { DialogService } from "../../../../../services/dialog.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-edit-booking-dialog",
	templateUrl: "./edit-booking-dialog.component.html",
	styleUrls: ["./edit-booking-dialog.component.scss"]
})
export class EditBookingDialogComponent implements OnInit {

	public currentUser!: UserProfileResponse;
	public booking: HostingResponseModel;
	private subscriptions: Subscription [] = [];
	public readonly BookingStatus: BookingStatusEnum;

	constructor(@Inject(MAT_DIALOG_DATA) public data: { id: number },
                private authService: AuthService,
                private hostingService: HostingService,
                private matDialogRef: MatDialogRef<EditBookingDialogComponent>,
                private dialogService: DialogService,
                private router: Router) {
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.authService.getCurrentUserObs()
				.subscribe(_user => this.currentUser = _user),
			this.hostingService.getHostingById(this.data.id)
				.subscribe(booking => this.booking = booking)
		);
	}

	public onValidateBooking() {
		this.dialogService.openConfirmDialog("Voulez-vous valider la reservation N° : ")
			.afterClosed().subscribe(status => {
				if (status) {
					const payload: BookingUpdateRequestModel = {
						beginingDate: this.booking.beginingDate.toString(),
						endingDate: this.booking.endingDate.toString(),
						description: this.booking.description
					};
					this.hostingService.confirmBooking(this.data.id, payload).subscribe(response => {
						this.booking = response;
						this.matDialogRef.close(true);
					});
				}
			});
	}

	public onCancelBooking() {
		this.dialogService.openConfirmDialog("Voulez-vous annuler la reservation N° : ")
			.afterClosed().subscribe(status => {
				if (status) {
					this.hostingService.cancelBooking(this.data.id).subscribe(() => {
						this.matDialogRef.close(true);
					});
				}
			});
	}

	public onDeleteReservation() {
		this.dialogService.openConfirmDialog("Voulez-vous supprimer la reservation N° : ")
			.afterClosed().subscribe(status => {
				if (status) {
					this.hostingService.deleteHosting(this.data.id).subscribe(() => {
						this.matDialogRef.close(true);
					});
				}
			});
	}

	goToReservationDetail() {
		this.matDialogRef.close(false);
		this.router.navigate(["/feature/reservation", this.booking.hostingId]);
	}

	public canValidate(): boolean {
		return (this.booking.status === BookingStatusEnum.PENDING && moment(new Date()).isBefore(this.booking.endingDate));
	}

	public canCancel(): boolean {
		return ((this.booking.status !== BookingStatusEnum.CANCELLED) && moment(new Date()).isBefore(this.booking.endingDate));
	}

	public canDelete(): boolean {
		return !(moment(new Date()).isAfter(this.booking.endingDate) && this.booking.status !== BookingStatusEnum.CONFIRMED);
	}

	public durationInHours() {
		return Math.ceil(moment(this.booking.endingDate).diff(this.booking.beginingDate, "hours", true));
	}
}
