import { Component, OnInit } from "@angular/core";
import { HotelService } from "../../../../services/hotel.service";
import { HotelResponse, UserProfileResponse } from "@app/model";
import { NgForm } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../../../services/auth.service";

@Component({
	selector: "app-hotel-configuration",
	templateUrl: "./hotel-configuration.component.html",
	styleUrl: "./hotel-configuration.component.scss"
})
export class HotelConfigurationComponent implements OnInit {
	public hotel: HotelResponse;
	private _currentUser: UserProfileResponse;
	public constructor(private hotelService: HotelService,
                       private authService: AuthService,
                       private matSnackBar: MatSnackBar) {
	}

	ngOnInit(): void {

		this.authService.getCurrentUserFromApi()
			.subscribe(_user => this._currentUser = _user);

		this.hotelService.getSessionHotel()
			.subscribe(_hotel => this.hotel = _hotel);

	}

	public onUpdateData(hotelUpdateForm: NgForm) {
		this.hotelService.updateSessionHotel(hotelUpdateForm.value)
			.subscribe({
				next: (_hotel) => {
					this.hotel = _hotel;
					this.matSnackBar.open("Informations de configuration mis à jour", undefined, {
						duration: 3000
					});
				}
			});
	}

	get currentUser(): UserProfileResponse {
		return this._currentUser;
	}
}
