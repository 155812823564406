import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "Textwrap"
})

export class TextwrapPipe implements PipeTransform {
	transform(value: string, size: number = 25): string | null {

		if (!value) return null;

		if (value.length > size) {
			return `${value.substring(0, size - 1)} ...`;
		}
		return value;
	}
}