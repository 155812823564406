import { BookingStatusEnum, CalendarViewEnum, HostingResponseModel } from "@app/model";
import { toTitleCase } from "../util";

export function formatTitleForDashboard(firstName: string, lastName: string, logementCode: string): string {
	return (lastName || "").toUpperCase() + " " + (firstName || "").toUpperCase() + "\n"
		.concat("\n\n")
		.concat(logementCode);
}

export function getBookingStatusColor(status: HostingResponseModel["status"]): string {
	switch (status) {
		case BookingStatusEnum.CONFIRMED:
			return "booking booking-accepted";
		case BookingStatusEnum.PENDING:
			return "booking booking-pending";
		default:
			return "booking booking-cancelled";
	}
}

export function formatCalendarViewTitle(date: Date, currentView: CalendarViewEnum): string {
	const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
	const optionsMonth: Intl.DateTimeFormatOptions = { year: "numeric", month: "long" };

	switch (currentView) {
		case CalendarViewEnum.Month:
			return toTitleCase(date.toLocaleDateString("fr-FR", optionsMonth));

		case CalendarViewEnum.Day:
			return  date.toLocaleDateString("fr-FR", options);

		case CalendarViewEnum.Week: {
			// Get the current day of the week (0-6) with 0 being Sunday
			const dayOfWeek = date.getDay();

			// Calculate the date for the start of the week (Monday)
			const startOfWeek = new Date(date);
			startOfWeek.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

			// Calculate the date for the end of the week (Sunday)
			const endOfWeek = new Date(startOfWeek);
			endOfWeek.setDate(startOfWeek.getDate() + 6);

			const formattedStart = startOfWeek.toLocaleDateString("fr-FR", options);
			const formattedEnd = endOfWeek.toLocaleDateString("fr-FR", options);

			return `${formattedStart} AU ${formattedEnd}`;
		}

		default:
			throw new Error("Invalid currentView value");
	}
}
