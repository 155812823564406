<ng-container *ngIf="room">
    <div class="room-container">

        <div class="room-section">

            <div class="cover">
                <img *ngIf="isPictureContainsCover(); else defaultCover" class="cover-image"
                     [src]="getPicture(cover.data)" height="100%" width="100%" alt="loading">

                <ng-template #defaultCover>
                    <img *ngIf="false" [src]="DEFAULT_ROOM_COVER_IMAGE"
                         height="100%" width="100%" alt="loading">
                </ng-template>

                <button mat-icon-button class="action-btn cover-picture-btn"
                        (click)="uploadType='cover'; fileInput.click()">
                    <mat-icon>image</mat-icon>
                </button>

                <button *ngIf="isPictureContainsCover()" mat-icon-button class="action-btn room-picture-btn"
                        (click)="uploadType='picture'; fileInput.click();">
                    <mat-icon>add_a_photo</mat-icon>
                </button>

                <div *ngIf="pictures?.length" class="picture-container ">
                    <ng-container *ngFor="let picture of pictures">
                        <div class="item" *ngIf="!picture.isCover">
                            <button mat-icon-button color="red" class="picture-action-btn">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-icon-button color="red" class="picture-action-btn"
                                    (click)="onDeletePicture(picture?.pictureId)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <img [src]="getPicture(picture.data)" alt="loading">
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="form-container">
                <div class="card mt-2">
                    <div class="card-header">Détails de la chambre</div>
                    <div class="card-body">
                        <form #roomUpdateForm="ngForm"
                              novalidate
                              (ngSubmit)="roomUpdateForm.form.valid && onUpdateRoom(roomUpdateForm)">
                            <div class="row gx-3 mb-3">
                                <div class="col-md-6">
                                    <label class="mb-1" for="name">Nom</label>
                                    <input class="form-control" id="name"
                                           type="text"
                                           name="name" [ngModel]="room.name"
                                           #name="ngModel"
                                           required
                                           [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && name.errors }"/>

                                    <div *ngIf="roomUpdateForm.submitted && name.errors"
                                         class="invalid-feedback">
                                        <div> Le nom est obligatoire</div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="mb-1" for="code">Type</label>
                                    <select class="form-select" name="typeLogement"
                                            [ngModel]="room.typeLogement"
                                            #typeLogement="ngModel"
                                            required
                                            [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && typeLogement.errors }">
                                        <option selected> -- Sélectionner --</option>
                                        <option value="CHAMBRE">Chambre</option>
                                        <option value="SUITE">SUITE</option>
                                        <option value="SALLE_DE_FETE">Salle de fête</option>
                                    </select>
                                    <div *ngIf="roomUpdateForm.submitted && typeLogement.errors"
                                         class="invalid-feedback">
                                        <div> Le type est obligatoire</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="mb-1" for="code">Niveau</label>
                                <select class="form-select" name="niveauId" [ngModel]="room.niveau.id"
                                        #niveauId="ngModel"
                                        required
                                        [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && niveauId.errors }">
                                    <option selected> -- Sélectionner --</option>
                                    <option *ngFor="let level of levels" [value]="level.id">
                                        {{ level.name }}
                                    </option>
                                </select>
                                <div *ngIf="roomUpdateForm.submitted && niveauId.errors"
                                     class="invalid-feedback">
                                    <div> Le niveau est obligatoire</div>
                                </div>
                            </div>

                            <div class="row gx-3 mb-3">
                                <div class="col-md-6">
                                    <label class="mb-1" for="code">Code</label>
                                    <input class="form-control" id="code" type="text"
                                           placeholder="Entrer le code" name="code" [disabled]="true"
                                           [ngModel]="room.code"
                                           #code="ngModel"
                                           required
                                           [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && code.errors }"/>
                                    <div *ngIf="roomUpdateForm.submitted && code.errors"
                                         class="invalid-feedback">
                                        <div> Le code est obligatoire</div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="mb-1" for="default-price">Prix</label>
                                    <input class="form-control" id="default-price" type="number"
                                           name="defaultPrice" [ngModel]="room.defaultPrice"
                                           #defaultPrice="ngModel"
                                           required
                                           min="5000"
                                           [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && defaultPrice.errors }"/>

                                    <div *ngIf="roomUpdateForm.submitted && defaultPrice.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="defaultPrice.errors['required']">Le prix est obligatoire</div>
                                        <div *ngIf="defaultPrice.errors['min']">Le montant minimum est 5000</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row gx-3 mb-3">
                                <div class="col-md-6">
                                    <label class="mb-1" for="nap-amount">Prix de sieste</label>
                                    <input class="form-control" id="nap-amount" type="number"
                                           placeholder="Montant de la sieste" name="napAmount"
                                           [ngModel]="room.napAmount"
                                           #napAmount="ngModel"
                                           required
                                           min="5000"
                                           [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && napAmount.errors }"/>
                                    <div *ngIf="roomUpdateForm.submitted && napAmount.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="napAmount.errors['min']">Le montant minimum est 5000</div>
                                        <div *ngIf="napAmount.errors['required']"> Le montant de sieste est obligatoire </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="mb-1" for="nap-time">Nb d'heure de sieste</label>
                                    <input class="form-control" id="nap-time" type="number"
                                           name="napTime" [ngModel]="room.napTime"
                                           #napTime="ngModel"
                                           required
                                           min="2"
                                           max="24"
                                           [ngClass]="{ 'is-invalid' : roomUpdateForm.submitted && napTime.errors }"/>

                                    <div *ngIf="roomUpdateForm.submitted && napTime.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="napTime.errors['min']">
                                            <div> Le nombre d'heure de sieste doit être est >= 1 </div>
                                        </div>
                                        <div *ngIf="napTime.errors['max']">
                                            <div> Le nombre d'heure de sieste doit être est <= 24 </div>
                                        </div>
                                        <div *ngIf="napTime.errors['required']">
                                            <div> Le nombre d'heure de sieste est obligatoire</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="mb-1" for="description">Description</label>
                                <textarea class="form-control" id="description"
                                          name="description" [ngModel]="room.description">
                                        </textarea>
                            </div>

                            <div class="row gx-3 mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="wifi-available"
                                               name="wifiAvailable" [ngModel]="room.wifiAvailable">
                                        <label class="form-check-label" for="wifi-available">
                                            Wifi Disponible ?
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                               id="bathroom-available" name="bathroomAvailable"
                                               [ngModel]="room.bathroomAvailable">
                                        <label class="form-check-label" for="bathroom-available">
                                            Douche disponible ?
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row gx-3 mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="kitchen-available"
                                               name="kitchenAvailable" [ngModel]="room.kitchenAvailable">
                                        <label class="form-check-label" for="wifi-available">
                                            Cuisine Disponible ?
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="mb-1" for="number-of-place">Nombre de personnes</label>
                                    <input class="form-control" id="number-of-place" type="number"
                                           name="numberOfPersons"
                                           placeholder="Nombre de personnes"
                                           [ngModel]="room.numberOfPersons">
                                </div>
                            </div>

                            <button mat-raised-button class="save-btn" type="submit"
                                    *ngIf="currentUser?.roles?.includes('ADMIN') || currentUser?.roles?.includes('SUPERADMIN')">
                                <mat-icon>save</mat-icon>
                                Enregistrer
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <div class="planning">

            <div class="calendar">

                <app-calendar [roomId]="room.logementId"
                              [onCalendarEventClick]="onCalendarEventClick"
                              [refreshSubject]="refreshSubject"
                              [calendarSettings]="calendarSettings"
                              (filterChange)="onUpdateFilter($event)"
                              (currentViewChange)="onUpdateView($event)"
                              (viewDateChange)="onUpdateDate($event)"
                              (dayClick)="onNewBooking($event)">
                </app-calendar>
            </div>

            <div class="report">
                <ng-container *ngIf="reservations?.length">
                    <app-reservation-table
                            [reservations]="reservations"
                            [tableTitle]="reportTitle"
                            [textWrapSize]="30"
                            (exportEvent)="onExport()">
                    </app-reservation-table>
                </ng-container>
            </div>
        </div>
    </div>

    <form enctype="multipart/form-data" style="display:none;">
        <input type="file" #fileInput name="image" id="image"
               (change)="uploadRoomPicture(fileInput.files[0])"
               placeholder="file" ngModel accept="image/*"/>
    </form>

</ng-container>