import { Component, Inject } from "@angular/core";
import { ComputeHostingPlanModel } from "../../../../../model/compute-hosting-plan.model";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-compute-hosting",
	templateUrl: "./compute-hosting.component.html",
	styleUrl: "./compute-hosting.component.scss"
})
export class ComputeHostingComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public computeResponseModel: ComputeHostingPlanModel) {
	}

}
