import { Component, Inject, OnInit } from "@angular/core";
import { CustomerService } from "../../../../services/customer.service";
import { firstValueFrom } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { NewBookingForm } from "../../../../forms/booking.form";
import { MetaFormGroup } from "../../../../utils/form.utils";
import { RoomService } from "../../../../services/room.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
	SelectFreeRoomDialogComponent
} from "../../../shared/components/select-free-room-dialog/select-free-room-dialog.component";

import * as moment from "moment/moment";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
	BookingResponseModel,
	CustomerResponse,
	DiscountTypeEnum,
	HostingResponseModel,
	HotelResponse,
	LogementApiResponse, Nullable,
	OccupationModeEnum,
	OccupationTypeEnum
} from "@app/model";
import { HostingService } from "../../../../services/hosting.service";
import { ComputeHostingComponent } from "../dialog/compute-hosting/compute-hosting.component";
import { ActivatedRoute, Router } from "@angular/router";
import { NewCustomerDialogComponent } from "../dialog/new-customer-dialog/new-customer-dialog.component";
import { HotelService } from "../../../../services/hotel.service";
import { createDateWithNowTime } from "../../../../utils/date.util";
import { MatSelectChange } from "@angular/material/select";
import { DEFAULT_ROOM_COVER_IMAGE, getPictureUrlFromByteArray } from "../../../../utils/file.utils";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
	selector: "app-reservation",
	templateUrl: "./reservation.component.html",
	styleUrl: "./reservation.component.scss"
})
export class ReservationComponent implements OnInit {

	public customers: CustomerResponse[] = [];
	public customer: CustomerResponse;

	public bookingForm!: NewBookingForm;
	public rooms: LogementApiResponse[] = [];
	public selectedLogement!: LogementApiResponse;
	private hotel: HotelResponse;

	public readonly DEFAULT_ROOM_COVER_IMAGE = DEFAULT_ROOM_COVER_IMAGE;

	public canSelectRoom = true;

	constructor(private customerService: CustomerService,
                private roomService: RoomService,
                @Inject(MAT_DIALOG_DATA) public data: BookingResponseModel,
                private matDialog: MatDialog,
                private matSnackBar: MatSnackBar,
                private hostingService: HostingService,
                private router: Router,
                private hotelService: HotelService,
                private activatedRoute: ActivatedRoute,
                private domSanitizer: DomSanitizer) {
	}

	async ngOnInit(): Promise<void> {

		[this.hotel, this.rooms, this.customers] = await Promise.all(
			[
				await firstValueFrom(this.hotelService.getSessionHotel()),
				await firstValueFrom(this.roomService.getAllFreeLogement()),
				await firstValueFrom(this.customerService.getCustomersSub())
			]
		);

		this.bookingForm = this.getBookingForm();

		this.activatedRoute.queryParamMap.subscribe(param => {
			if (param.get("room_id")) {
				this.selectedLogement = this.rooms.find(item => item.logementId === Number(param.get("room_id")))!;
				if (this.selectedLogement) {
					this.canSelectRoom = false;
					this.setFormWithRoom(this.selectedLogement);

					const date = createDateWithNowTime(param.get("date")!);
					this.setBookingFormDate(date, moment(date).add(1, "days").toDate());

					this.computeBookingTime();
					this.updateFormData();
				}
			}
		});

		this.handleFormControlEvents();
	}

	private getBookingForm(hosting?: HostingResponseModel): NewBookingForm {

		const _startDate = new Date();
		const _endDate = moment(_startDate).add(1, "days").toDate();

		return new MetaFormGroup(
			{
				customerId: new FormControl<Nullable<number>>(hosting?.customer?.customerId, {
					nonNullable: true,
					validators: [Validators.required]
				}),
				logementId: new FormControl<Nullable<number>>(hosting?.logement?.logementId, {
					nonNullable: true,
					validators: [Validators.required]
				}),
				amount: new FormControl<Nullable<number>>(0, [Validators.required, Validators.min(1)]),
				vtaRate: new FormControl<number>({
					value: Number(this.hotel?.defaultVtaRate) || 0,
					disabled: true
				}, {
					nonNullable: true
				}),
				vtaAmount: new FormControl<number>(0),
				totalAmount: new FormControl<number>(0, [Validators.required, Validators.min(1)]),
				discountAmount: new FormControl<number>(Number(this.hotel?.defaultDiscountAmount) || 0),
				discountRate: new FormControl<number>(Number(this.hotel?.defaultDiscountRate) || 0),
				beginingDate: new FormControl<Date | string>(_startDate, [Validators.required]),
				endingDate: new FormControl<Date | string>(_endDate, [Validators.required]),
				description: new FormControl<string>("Ras ..."),
				discount: new FormControl<boolean>(this.hotel?.defaultDiscountRate > 0 || this.hotel?.defaultDiscountAmount > 0 || false),
				discountType: new FormControl<DiscountTypeEnum>(DiscountTypeEnum.NO_RATE),
				numberOfDays: new FormControl<number>({
					value: 1,
					disabled: true
				}, [Validators.required, Validators.min(1)]),
				unitPrice: new FormControl<Nullable<number>>({
					value: this.selectedLogement?.defaultPrice || 0,
					disabled: true
				}, [Validators.required, Validators.min(1)]),
				occupationType: new FormControl<OccupationTypeEnum>(OccupationTypeEnum.DIRECT),
				occupationMode: new FormControl<OccupationModeEnum>(OccupationModeEnum.NORMAL)
			}, {
				id: hosting?.hostingId,
				savedValue: hosting,
				isCreated: !!hosting,
				isEdit: !hosting,
				isLoading: false
			}
		);
	}

	get f() {
		return this.bookingForm.controls;
	}

	public openSelectDialog() {
		if (!this.isDatesValid()) {
			this.notifyError("Les dates sont incorrectes");
			return;
		}
		const dialogRef = this.matDialog.open(SelectFreeRoomDialogComponent, {
			data: {
				beginingDate: this.f.beginingDate.value,
				endingDate: this.f.endingDate.value,
				mode: this.f.occupationMode.value
			},
			disableClose: true
		});

		dialogRef.afterClosed().subscribe((data: {
            selectedLogement: LogementApiResponse,
            logements: LogementApiResponse[],
            startDate: string,
            endDate: string
        }) => {
			if (data) {
				this.setFormWithRoom(data.selectedLogement);
				this.setBookingFormDate(new Date(data.startDate), new Date(data.endDate));
				this.computeBookingTime();
				this.rooms = data.logements;
				this.selectedLogement = data.selectedLogement;
				this.updateFormData();
			}
		});
	}

	public updateFormData() {
		this.f.amount.setValue(this.f.unitPrice.value! * this.f.numberOfDays.value);
		if (this.f.vtaRate.value > 0)
			this.f.vtaAmount.setValue(((this.f.amount.value! * this.f.vtaRate.value) / 100));

		this.f.totalAmount.setValue(this.f.amount.value! + this.f.vtaAmount.value);
		if (this.f.discount.value) {
			if (this.f.discountType.value === DiscountTypeEnum.RATE)
				this.f.discountAmount.setValue((Number(this.f.totalAmount.value) * Number(this.f.discountRate.value)) / 100);
		}

		this.f.totalAmount.setValue(Number(this.f.totalAmount.value) - Number(this.f.discountAmount.value));
	}

	private handleFormControlEvents() {
		this.f.discount.valueChanges.subscribe(() => {
			if (this.selectedLogement) {
				this.f.discountAmount.setValue(Number(this.hotel.defaultDiscountAmount));
				this.f.discountRate.setValue(Number(this.hotel.defaultDiscountRate));
				this.updateFormData();
			}
		});
	}

	public handleDiscountEventUpdate($event: MatCheckboxChange) {
		if (!$event.checked) {
			this.f.discountType.setValue(DiscountTypeEnum.NO_RATE);
			this.f.discountRate.setValue(Number(0));
			this.f.discountAmount.setValue(Number(0));

			if (this.selectedLogement)
				this.updateFormData();
		}
	}

	public onSaveBooking() {
		const payload = this.bookingForm.getRawValue();

		if (!this.isDatesValid()) {
			this.notifyError("Les dates sont incorrectes");
			return;
		}

		payload.endingDate = moment(payload.endingDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		payload.beginingDate = moment(payload.beginingDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

		this.matDialog.open(ComputeHostingComponent, { data: { ...payload }, disableClose: true })
			.afterClosed().subscribe((state: boolean) => {
				if (state)
					this.hostingService.saveHosting(payload).subscribe({
						next: (response) => {
							if (response.hostingId)
								this.router.navigate(["/feature/reservation", response.hostingId]);
						},
						error: (err) => {
							this.notifyError(err.error.message);
						}
					});
			});
	}

	public onOpenCustomerDialog() {
		this.matDialog.open(NewCustomerDialogComponent, {
			width: "700px",
			maxHeight: "90vh"
		}).afterClosed().subscribe((data: CustomerResponse) => {
			if (data) {
				this.customer = data;
				this.f.customerId.setValue(data.customerId);
				this.customers.push(data);
			}
		});
	}

	private setFormWithRoom(logement: LogementApiResponse) {
		this.f.logementId.setValue(logement.logementId);
		const amount = this.f.occupationMode.value === OccupationModeEnum.NORMAL
			? Number(logement.defaultPrice) : Number(logement.napAmount);
		this.f.unitPrice.setValue(amount);
	}

	private computeBookingTime() {
		if (this.isDatesValid()) {
			if (this.f.occupationMode.value === OccupationModeEnum.SIESTE) {
				const duration =
                    Math.ceil(moment(this.f.endingDate.value).diff(moment(this.f.beginingDate.value), "hours", true));
				this.f.numberOfDays.setValue(Math.ceil(duration / (this.selectedLogement?.napTime || 2)));
			} else {
				this.f.numberOfDays.setValue(
					Math.ceil(moment(this.f.endingDate.value).diff(moment(this.f.beginingDate.value), "days", true))
				);
			}
		} else {
			this.f.numberOfDays.setValue(0);
		}
	}

	private setBookingFormDate(startDate: Date | string, endDate: Date | string) {
		this.f.beginingDate.setValue(startDate);
		this.f.endingDate.setValue(endDate);
	}

	public onUpdateOccupationMode($event: MatSelectChange) {
		if ($event.value === OccupationModeEnum.SIESTE) {
			const end = moment(this.f.beginingDate.value).add(this.selectedLogement?.napTime || 2, "hours");
			this.setBookingFormDate(this.f.beginingDate.value, end.toDate());
		} else {
			const end = moment(this.f.beginingDate.value).add(1, "days");
			this.setBookingFormDate(this.f.beginingDate.value, end.toDate());
		}
		if (this.selectedLogement) {
			this.setFormWithRoom(this.selectedLogement);
			this.computeBookingTime();
			this.updateFormData();
		}
	}

	public getPicture(binary: number[]): SafeUrl {
		return getPictureUrlFromByteArray(binary, this.domSanitizer);
	}

	public updateDateCompute() {
		if (this.isDatesValid()) {
			if (this.selectedLogement) {
				this.computeBookingTime();
				this.updateFormData();
			}
		}
	}

	private isDatesValid(): boolean {
		return moment(this.f.endingDate.value).isAfter(this.f.beginingDate.value);
	}

	public canValidateForm() {
		return !this.isDatesValid();
	}

	private notifyError(errorMessage: string, duration?: number) {
		this.matSnackBar.open(errorMessage, undefined, {
			duration: duration || 5000,
			panelClass: "snack-bar-error"
		});
	}
}
