<ng-container *ngIf="bookingForm">
    <div class="container mt-2" [formGroup]="bookingForm">

        <div class="card border">
            <div class="card-body">

                <div class="row">
                    <div class="col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label> Client</mat-label>
                            <mat-select formControlName="customerId">
                                <mat-option *ngFor="let client of customers"
                                            [value]="client.customerId">
                                    {{ client.lastName }} {{ client.firstName || '' }}
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="f.customerId.touched && !f.customerId?.valid"
                                      class="text-danger">Veuillez sélectionner le client
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="col-md-1">
                        <button mat-mini-fab class="primary"
                                (click)="onOpenCustomerDialog()">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label> Type</mat-label>
                            <mat-select formControlName="occupationType">
                                <mat-option [value]="'DIRECT'"> Occupation Directe</mat-option>
                                <mat-option [value]="'RESERVATION'"> Reservation</mat-option>
                            </mat-select>
                            <mat-hint *ngIf="f.occupationType?.touched && !f.occupationType?.valid"
                                      class="text-danger">
                                Veuillez sélectionner le mode
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div [ngClass]="{'col-md-12' : f.occupationMode.value === 'NORMAL', 'col-md-6' : f.occupationMode.value === 'SIESTE'}">
                        <mat-form-field appearance="outline">
                            <mat-label> Mode</mat-label>
                            <mat-select formControlName="occupationMode"
                                        (selectionChange)="onUpdateOccupationMode($event)">
                                <mat-option [value]="'NORMAL'"> Nuitée </mat-option>
                                <mat-option [value]="'SIESTE'"
                                            [disabled]="selectedLogement?.typeLogement === 'SALLE_DE_FETE'">
                                    SIESTE
                                </mat-option>
                            </mat-select>
                            <mat-hint *ngIf="f.occupationMode?.touched && !f.occupationMode?.valid"
                                      class="text-danger">Veuillez sélectionner le type
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="f.occupationMode.value === 'SIESTE' && f.logementId?.value">
                        <div class="col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Prix de sieste</mat-label>
                                <input matInput [ngModel]="selectedLogement.napAmount"
                                       [disabled]="true"
                                       [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre d'heures</mat-label>
                                <input matInput [ngModel]="selectedLogement.napTime"
                                       [disabled]="true"
                                       [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label> Début à</mat-label>
                            <input matInput formControlName="beginingDate"
                                   [min]="f.beginingDate?.value"
                                   [ngxMatDatetimePicker]="pickerFrom"
                                   (dateChange)="updateDateCompute()">
                            <mat-datepicker-toggle matSuffix [for]="$any(pickerFrom)"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #pickerFrom></ngx-mat-datetime-picker>
                        </mat-form-field>
                    </div>

                    <div [ngClass]="canSelectRoom ? 'col-md-5' : 'col-md-6'">
                        <mat-form-field appearance="outline">
                            <mat-label> Fin à</mat-label>
                            <input matInput formControlName="endingDate"
                                   [min]="f.beginingDate?.value"
                                   [ngxMatDatetimePicker]="pickerTo"
                                   (dateChange)="updateDateCompute()">
                            <mat-datepicker-toggle matSuffix [for]="$any(pickerTo)"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #pickerTo></ngx-mat-datetime-picker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-1" *ngIf="canSelectRoom">
                        <button mat-mini-fab class="primary"
                                [disabled]="!f.customerId?.value"
                                (click)="openSelectDialog()">
                            <mat-icon>night_shelter</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Prix de nuitée</mat-label>
                            <input matInput type="number" formControlName="unitPrice">
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Taux tva</mat-label>
                            <input matInput type="number" formControlName="vtaRate">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-checkbox [formControlName]="'discount'"
                                      (change)="handleDiscountEventUpdate($event)">
                            Réduction ?
                        </mat-checkbox>
                    </div>

                    <div class="col-md-6" *ngIf="f.discount.value">
                        <mat-form-field appearance="outline">
                            <mat-label>Option de réduction</mat-label>
                            <mat-select formControlName="discountType">
                                <mat-option [value]="'NO_RATE'"> -- Sélection --</mat-option>
                                <mat-option [value]="'AMOUNT'"> MONTANT</mat-option>
                                <mat-option [value]="'RATE'"> TAUX</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6" *ngIf="f?.discount?.value && f.discountType?.value === 'RATE'">
                        <mat-form-field appearance="outline">
                            <mat-label> Taux de la réduction</mat-label>
                            <input matInput type="number" formControlName="discountRate" (change)="updateFormData()">
                        </mat-form-field>
                    </div>

                    <div class="col-md-6" *ngIf="f.discount?.value && f.discountType?.value === 'AMOUNT'">
                        <mat-form-field appearance="outline">
                            <mat-label> Montant de la réduction</mat-label>
                            <input matInput type="number" formControlName="discountAmount"
                                   [disabled]="f.discountType?.value !== 'AMOUNT'" (change)="updateFormData()">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Observations</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row mt-2" *ngIf="f.logementId?.value">
            <div class="col-xl-8">
                <div class="card border shadow-none">
                    <div class="card-body">

                        <div class="d-flex align-items-start border-bottom pb-3">
                            <div class="me-4">
                                <img *ngIf="selectedLogement?.coverPicture?.data; else defaultCoverPicture"
                                     [src]="getPicture(selectedLogement.coverPicture.data)"
                                     height="100" width="150" alt="loading"
                                     class="avatar-lg rounded">

                                <ng-template #defaultCoverPicture>
                                    <img [src]="DEFAULT_ROOM_COVER_IMAGE"
                                         height="100" width="150" alt="loading"
                                         class="avatar-lg rounded">
                                </ng-template>
                            </div>
                            <div class="flex-grow-1 align-self-center overflow-hidden">
                                <div>
                                    <h5 class="text-truncate font-size-18">
                                        <a class="text-dark"> {{ selectedLogement.name }}</a>
                                    </h5>
                                    <p class="mb-0 mt-1">
                                        Code : <span class="fw-medium"> {{ selectedLogement.code }}</span>
                                    </p>

                                    <p class="mb-0 mt-1">
                                        Niveau : <span class="fw-medium"> {{ selectedLogement.niveau.name }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mt-3">
                                        <p class="text-muted mb-2">Prix Unitaire</p>
                                        <h5 class="mb-0 mt-2">
                                        <span class="text-muted me-2">
                                            {{ (f.unitPrice.value) | number }}
                                        </span>
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="mt-3">
                                        <p class="text-muted mb-2">
                                            Nombre de {{ f.occupationMode.value === "SIESTE" ? 'sieste' : 'nuitée' }}
                                        </p>

                                        <div class="d-inline-flex">
                                            <input class="form-control form-control-sm w-xl"
                                                   type="number" formControlName="numberOfDays">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mt-3">
                                        <p class="text-muted mb-2">Total</p>
                                        <h5>{{ (f.unitPrice.value * f.numberOfDays.value) | number }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-sm-6">
                        <div class="text-sm-end mt-2 mt-sm-0 d-flex align-self-start">
                            <button mat-raised-button class="save-btn"
                                    [disabled]="bookingForm.invalid"
                                    (click)="onSaveBooking()">
                                <mat-icon>save</mat-icon>
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4">
                <div class="mt-5 mt-lg-0">
                    <div class="card border shadow-none">
                        <div class="card-header bg-transparent border-bottom py-3 px-4">
                            <h5 class="font-size-16 mb-0"> Détails <span class="float-end">#MN0124</span></h5>
                        </div>
                        <div class="card-body p-4 pt-2">

                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td>Sous - Total :</td>
                                        <td class="text-end">
                                            {{ (f.amount.value | number : '0.00') || 0 }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>TVA :</td>
                                        <td class="text-end">
                                            {{ (f.vtaAmount?.value | number: '0.00') || 0 }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Reduction :</td>
                                        <td class="text-end">
                                            - {{ (f.discountAmount?.value | number: '0.00') || 0 }}
                                        </td>
                                    </tr>

                                    <tr class="bg-light">
                                        <th>Montant Total :</th>
                                        <td class="text-end">
                                            <span class="fw-bold">
                                                {{ f.totalAmount?.value | number : '0.00' }}
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-container>
