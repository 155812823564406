export const storeDataSession = (key: string, value: any) => {
	localStorage.setItem(key, value);
};

export const getSessionData = (key: string) => {
	return localStorage.getItem(key);
};

export const removeSessionData = (key: string) => {
	localStorage.removeItem(key);
};

export const clearSessionData = () => localStorage.clear();
