import { Component, Input, OnInit } from "@angular/core";
import { RoomService } from "../../../../services/room.service";
import {
	CalendarViewEnum,
	HostingResponseModel,
	LogementApiResponse, ReservationFilter,
	RoomPictureApiResponse,
	UserProfileResponse
} from "@app/model";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom, Subject } from "rxjs";
import { EditBookingDialogComponent } from "../dialog/edit-booking-dialog/edit-booking-dialog.component";
import { LevelService } from "../../../../services/level.service";
import { LevelResponse } from "../../../../model/level.response";
import { NgForm } from "@angular/forms";
import { DialogService } from "../../../../services/dialog.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DEFAULT_ROOM_COVER_IMAGE, getPictureUrlFromByteArray } from "../../../../utils/file.utils";
import { createDateWithNowTime } from "../../../../utils/date.util";
import { AuthService } from "../../../../services/auth.service";
import * as moment from "moment";
import { PlanningService } from "@frontend/src/app/services/planning.service";
import { getDefaultCalendarSettings } from "@frontend/src/app/utils/calendar.util";
import { ExportService } from "@frontend/src/app/services/export.service";
import { saveAs } from "file-saver";
import { CalendarView } from "angular-calendar";
import { formatCalendarViewTitle } from "@app/util";


@Component({
	selector: "app-room-detail",
	templateUrl: "./room-detail.component.html",
	styleUrl: "./room-detail.component.scss"
})

export class RoomDetailComponent implements OnInit {

	get reservations(): HostingResponseModel[] {
		return this._reservations;
	}

	get currentUser(): UserProfileResponse {
		return this._currentUser;
	}

	private _reservations: HostingResponseModel[];
	private _filter: ReservationFilter;

	public reportTitle: string;

	@Input({ required: true })
	set reservations(data: HostingResponseModel[]) {
		this._reservations = data;
	}

	private _viewDate: Date = new Date();

	public room: LogementApiResponse;
	public refreshSubject: Subject<any> = new Subject();
	public levels: LevelResponse[];
	public pictures: RoomPictureApiResponse[] = [];
	public uploadType: "cover" | "picture";

	public readonly DEFAULT_ROOM_COVER_IMAGE = DEFAULT_ROOM_COVER_IMAGE;

	private _currentUser: UserProfileResponse;

	private _currentView: CalendarView = CalendarView.Week;
	public calendarSettings = getDefaultCalendarSettings(1);

	public constructor(private roomService: RoomService,
					   private authService: AuthService,
					   private levelService: LevelService,
					   private dialogService: DialogService,
					   private matSnackBar: MatSnackBar,
					   private domSanitizer: DomSanitizer,
					   private router: Router,
					   private activatedRoute: ActivatedRoute,
					   private matDialog: MatDialog,
					   private planningService: PlanningService,
					   private exportService: ExportService) {
	}

	ngOnInit(): void {
		const roomId = this.activatedRoute.snapshot.paramMap.get("id")!;
		this.roomService.getRoomById(+roomId)
			.subscribe(_room => this.room = _room);

		this.levelService.getLevelsSubs()
			.subscribe(_levels => this.levels = _levels);

		this.roomService.getRoomPictures(+roomId).subscribe((_pictures) => {
			this.pictures = _pictures;
		});

		this.authService.getCurrentUserFromApi()
			.subscribe(_user => this._currentUser = _user);
	}

	public onCalendarEventClick = (event: any) => {
		this.matDialog.open(EditBookingDialogComponent, {
			data: {
				id: event.event.id,
			}
		})
			.afterClosed()
			.subscribe((result) => {
				if (result) (this.refreshSubject.next(true));
			});
	};

	public onNewBooking($event: { id: string | number; date: Date }) {
		this.router.navigate(["/feature/reservation"], {
			queryParams: {
				room_id: $event.id,
				date: moment(createDateWithNowTime($event.date)).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
			}
		});
	}

	public onUpdateRoom(roomUpdateForm: NgForm) {
		this.dialogService.openConfirmDialog("Voulez-vous modifier les informations de cette chambre ?")
			.afterClosed().subscribe((state: boolean) => {
			if (state) {
				this.roomService.updateLogement(this.room.logementId, roomUpdateForm.value)
					.subscribe({
						next: (_room: LogementApiResponse) => {
							this.room = _room;
							this.notifySuccess("Informations modifiées avec succès.");
						},
						error: (err) => {
							this.notifyError(err.error.message);
						}
					});
			}
		});
	}

	public uploadRoomPicture(file: File) {
		this.roomService.uploadRoomImage(this.room.logementId, file, this.uploadType)
			.subscribe({
				next: (response: RoomPictureApiResponse) => {
					if (!this.pictures.length || this.uploadType === "picture") {
						this.pictures.push(response);
					} else {
						const index = this.pictures.findIndex(item => item.roomId === this.room.logementId);
						if (index)
							this.pictures[index] = response;
						else
							this.pictures.push(response);
					}
				}
			});
	}

	public isPictureContainsCover(): boolean {
		return this.pictures.length && !!this.pictures.find(item => item.isCover);
	}

	public get cover(): RoomPictureApiResponse {
		return this.pictures.find(item => item.isCover);
	}

	public getPicture(binary: number[]): SafeUrl {
		return getPictureUrlFromByteArray(binary, this.domSanitizer);
	}

	public onDeletePicture(pictureId: string) {
		this.dialogService.openConfirmDialog("Voulez-vous supprimer cette photo ?")
			.afterClosed().subscribe((state: boolean) => {
			if (state) {
				this.roomService.deleteRoomPicture(pictureId).subscribe({
					next: () => {
						this.pictures = this.pictures.filter(item => item.pictureId !== pictureId);
						this.notifySuccess("Photo supprimée avec succès.");
					},
					error: (err) => {
						this.notifyError(err.error.message);
					}
				});
			}
		});
	}

	private notifyError(errorMessage: string, duration?: number) {
		this.matSnackBar.open(errorMessage, undefined, {
			panelClass: "snack-bar-error",
			duration: duration || 5000
		});
	}

	private notifySuccess(message: string) {
		this.matSnackBar.open(message, "Ok", {
			panelClass: "snack-bar-success",
			duration: 3000
		});
	}

	public async onExport() {
		const fileName = `historique_chambre_${this.room.code.toLowerCase()}.xlsx`;
		saveAs(await firstValueFrom(this.exportService.export(this._filter)), fileName);
	}

	private updateReportTitle() {
		this.reportTitle = `HISTORIQUE DE LA CHAMBRE ${this.room.code} - ${formatCalendarViewTitle(this._viewDate, this._currentView as string as CalendarViewEnum).toUpperCase()}`;
	}

	public onUpdateView(view: CalendarView) {
		this._currentView = view;
	}

	public onUpdateDate(date: Date) {
		this._viewDate = date;
	}

	public onUpdateFilter(event: { filter: ReservationFilter; currentView: CalendarView, viewDate: Date }) {
		this._filter = event.filter;
		this._currentView = event.currentView;
		this._viewDate = event.viewDate;

		console.error("filter change", event);
		this.updateReportTitle();
		this.planningService.getReservations(event.filter).subscribe({
			next: (_reservations) => {

				this._reservations = _reservations;
			}
		});
	}
}
