import { CalendarView } from "angular-calendar";
import { Nullable } from "@app/model";

export function dateToHoursSeparatorMinutesString(date: Date, separator = ":"): string | undefined {
	try {
		date = new Date(date);
	} catch (e) {
		return null;
	}
	if (!(date instanceof Date)) return null;
	return hoursMinutesToHoursSeparatorMinutesString(date.getHours(), date.getMinutes(), separator);
}

export const weekdayOptions: Intl.DateTimeFormatOptions = { weekday: "long" };
export const dateWithoutHourOptions: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };

export function millisecondsToHoursSeparatorMinutesString(milliseconds: number, separator = ":"): string | undefined {
	if (!milliseconds || isNaN(Number(milliseconds))) return undefined;

	if (milliseconds < 1)
		return "00" + separator + "00";

	const minutes = Math.floor(milliseconds / 60000);
	const hours = Math.floor(minutes / 60);
	return hoursMinutesToHoursSeparatorMinutesString(hours, minutes % 60, separator);
}

export function hoursMinutesToHoursSeparatorMinutesString(hours: number, minutes: number, separator = ":"): string {
	return `${hours < 10 ? ("0" + hours) : hours}${separator}${minutes < 10 ? ("0" + minutes) : minutes}`;
}

export function dateToFrenchString(date: Nullable<Date | string>): string | undefined {
	if (!date || new Date(date).toString() === "Invalid Date") return undefined;
	const weekday = toTitleCase(new Date(date).toLocaleDateString("fr-FR", weekdayOptions));
	return weekday + " " + new Date(date).toLocaleDateString("fr-FR", dateWithoutHourOptions);
}

function getWeekCalendarDate(date: Date) {
	const startOfWeek = new Date(date);
	const endOfWeek = new Date(date);
	startOfWeek.setDate(date.getDate() - date.getDay() + 1);
	endOfWeek.setDate(date.getDate() + (7 - date.getDay()));
	const startDate = startOfWeek.toLocaleDateString("fr-FR", { day: "numeric", month: "long" });
	const endDate = endOfWeek.toLocaleDateString("fr-FR", { day: "numeric", month: "long" });

	return `${startDate} - ${endDate}`;
}

function toTitleCase(str: string): string {
	return str.replace(
		/\w\S*/g,
		txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	);
}

export function getCalendarDate(view: CalendarView, date: Date) {
	switch (view) {
		case CalendarView.Day:
			return dateToFrenchString(date);
		case CalendarView.Week:
			return getWeekCalendarDate(date);
		case CalendarView.Month:
			return toTitleCase(date.toLocaleDateString("fr-FR", { month: "long", year: "numeric" }));
		default:
			return "Période non valide";
	}
}

export function createDateWithNowTime(date: Date | string) {
	const now = new Date();
	const newDate = new Date(date);
	newDate.setHours(now.getHours());
	newDate.setMinutes(now.getMinutes());
	return newDate;
}