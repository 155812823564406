import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import {
	CalendarDayViewBeforeRenderEvent,
	CalendarEvent,
	CalendarMonthViewBeforeRenderEvent,
	CalendarView,
	CalendarViewPeriod,
	CalendarWeekViewBeforeRenderEvent
} from "angular-calendar";
import { Subject } from "rxjs";
import { CalendarDataModel, CalendarSettings, ReservationFilter } from "@app/model";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isSameDay, isSameMonth } from "date-fns";
import { PlanningService } from "@frontend/src/app/services/planning.service";
import { AuthService } from "@frontend/src/app/services/auth.service";
import { millisecondsToHoursSeparatorMinutesString } from "@frontend/src/app/utils/date.util";

@Component({
	selector: "app-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss", "./calendar-styles-override.scss"],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CalendarComponent implements OnInit, OnDestroy {

	public isLoading = true;
	public readonly CalendarViewEnum = CalendarView;
	public currentView: CalendarView = CalendarView.Week;

	@Input({ required: true })
	public calendarSettings: CalendarSettings;

	public viewDate: Date = new Date();

	@Input()
	public refreshSubject = new Subject<void>();

	public calendarReservations: CalendarDataModel[] = [];

	public activeDayIsOpen = true;
	period!: CalendarViewPeriod;

	private _roomId!: number;

	@Input()
	set roomId(id: number) {
		this._roomId = id;
		this.onFilterChange();
	}

	@Input()
		onCalendarEventClick?: (event: any) => void;

	@Output()
		dayClick = new EventEmitter<{ id: string | number, date: Date }>();

	@Output()
		filterChange = new EventEmitter<{ filter: ReservationFilter, currentView: CalendarView, viewDate: Date }>();

	@Output()
		currentViewChange = new EventEmitter<CalendarView>();

	@Output()
		viewDateChange = new EventEmitter<Date>();

	millisecondsToHoursSeparatorMinutes = millisecondsToHoursSeparatorMinutesString;

	constructor(private elementRef: ElementRef,
				private planningService: PlanningService,
				private authService: AuthService,
				private matSnackBar: MatSnackBar) {
	}

	beforeViewRender(event: CalendarDayViewBeforeRenderEvent | CalendarWeekViewBeforeRenderEvent | CalendarMonthViewBeforeRenderEvent) {
		if (!this.period
			|| this.period.start.getTime() !== event.period.start.getTime()
			|| this.period.end.getTime() !== event.period.end.getTime()) {

			this.isLoading = true;
			this.period = event.period;

			this.filterChange.emit({ filter: this.getRequestFilter(), currentView: this.currentView, viewDate: this.viewDate });

			this.loadCalendarData();
		}
	}

	ngOnInit() {
		this.authService.getCurrentUserFromApi()
			.subscribe(user => {
				this.currentView = user.calendarDefaultView as string as CalendarView;
			});

		this.refreshSubject.subscribe(() => {
			this.loadCalendarData();
		});
	}

	onFilterChange() {
		this.loadCalendarData();
	}

	scrollToFirstElement() {
		const minHour = Math.min(...this.calendarReservations.map(cg => cg.start.getHours()));
		const eventsContainer = this.elementRef.nativeElement.querySelector(".cal-time-events");
		if (eventsContainer)
			eventsContainer.scrollTop = this.calendarSettings.hourSegmentHeight * minHour;
	}

	public closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}

	public onDayClicked(event: { day: { date: Date }; sourceEvent: MouseEvent }) {
		if (this._roomId) {
			if (!moment(event.day.date).isBefore(new Date(), "day")) {
				this.dayClick.emit({ id: this._roomId, date: event.day.date });
				return;
			}

			this.matSnackBar.open("Les reservations ne peuvent pas se faire à une date antérieur", undefined, {
				panelClass: "snack-bar-success",
				duration: 3000
			});
		}
	}

	private loadCalendarData() {
		if (!this.period) return;
		this.isLoading = true;
		this.planningService.getReservationsCalendar(this.getRequestFilter()).subscribe({
			next: (data) => {
				this.calendarReservations = data;
				this.scrollToFirstElement();
				this.isLoading = false;
			},
			error: () => this.isLoading = false
		});
	}

	public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
				this.activeDayIsOpen = false;
			} else {
				this.activeDayIsOpen = true;
			}
			this.viewDate = date;
		}
	}

	public onChangeView(view: CalendarView) {
		this.currentView = view;
		this.currentViewChange.emit(view);
	}

	private getRequestFilter(): ReservationFilter {
		let filter: ReservationFilter = {
			start: this.period.start,
			end: this.period.end,
		};

		if (this._roomId) {
			filter = { ...filter, roomId: this._roomId };
		}

		return filter;
	}

	ngOnDestroy() {
		this.refreshSubject.unsubscribe();
	}
}
