import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../components/shared/components/confirm-dialog/confirm-dialog.component";

@Injectable()
export class DialogService {

	constructor(private dialog: MatDialog) {
	}

	openConfirmDialog(msg: string): MatDialogRef<any> {
		return this.dialog.open(ConfirmDialogComponent, {
			width: "450px",
			panelClass: "confirm-dialogs-dialogs-container",
			disableClose: true,
			data: {
				message: msg
			}
		});
	}
}