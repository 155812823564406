import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { LevelResponse } from "../model/level.response";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class LevelService {

	private readonly API_URL: string = environment.backendUrl;
	private levels!: LevelResponse[];
	private levelsSub = new ReplaySubject<LevelResponse[]>(1);

	constructor(private httpClient: HttpClient) {
		this.findAllLevels();
	}

	private findAllLevels() {
		this.httpClient.get<LevelResponse[]>(this.API_URL + "/api/v1/level")
			.subscribe(response => {
				this.levels = response;
				this.levelsSub.next(this.levels);
			});
	}

	public getLevelsSubs(): Observable<LevelResponse[]> {
		return this.levelsSub.asObservable();
	}
}
