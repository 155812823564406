import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, tap } from "rxjs";
import { HotelResponse } from "@app/model";
import { HotelForm } from "../forms/hotel.form";
import { Key } from "../enum/key.enum";

@Injectable({
	providedIn: "root"
})
export class HotelService {
	private readonly API_URL: string = environment.backendUrl;
	private _hotels!: HotelResponse[];
	private hotelsSub = new ReplaySubject<HotelResponse[]>(1);
	private sessionHotelSub = new ReplaySubject<HotelResponse | null>(1);

	constructor(private httpClient: HttpClient) {
		if (this.isCookiePresent()) {
			this.getAllHotels();
			this.getSessionHotel();
		} else
			this.sessionHotelSub.next(null);
	}

	private getAllHotels() {
		this.httpClient.get<HotelResponse[]>(`${ this.API_URL }/api/v1/hotel`)
			.subscribe(_hotels => {
				this._hotels = _hotels;
				this.hotelsSub.next(_hotels);
			});
	}

	public saveHotel(hotelPayload: HotelForm["value"]): Observable<HotelResponse> {
		return this.httpClient.post<HotelResponse>(`${ this.API_URL }/api/v1/hotel`, hotelPayload)
			.pipe(tap(_hotel => {
				this._hotels.push(_hotel);
				this.hotelsSub.next(this._hotels);
			}));
	}

	public updateHotel(hotelId: number, hotelPayload: HotelForm["value"]): Observable<HotelResponse> {
		const paylaod = { ...hotelPayload };
		return this.httpClient.put<HotelResponse>(`${ this.API_URL }/api/v1/hotel`, paylaod)
			.pipe(tap(response => {
				const index = this._hotels.findIndex(item => item.hotelId === hotelId);
				this._hotels[index] = response;
				this.hotelsSub.next(this._hotels);
			}));
	}

	public updateSessionHotel(hotelPayload: any): Observable<HotelResponse> {
		return this.httpClient.put<HotelResponse>(`${ this.API_URL }/api/v1/hotel/session/update`, hotelPayload)
			.pipe(tap(_hotel => {
				const index = this._hotels.findIndex(item => item.hotelId === _hotel.hotelId);
				this._hotels[index] = _hotel;
				this.hotelsSub.next(this._hotels);
				this.sessionHotelSub.next(_hotel);
			}));
	}

	public getSessionHotel(): Observable<HotelResponse> {
		return this.httpClient.get<HotelResponse>(`${ this.API_URL }/api/v1/hotel/session`)
			.pipe(tap(_hotel => {
				this.sessionHotelSub.next(_hotel);
				return _hotel;
			}));
	}

	public getHotelsSub(): Observable<HotelResponse[]> {
		return this.hotelsSub.asObservable();
	}

	private isCookiePresent(): boolean {
		return !!localStorage.getItem(Key.TOKEN);
	}
}
