import { Component, EventEmitter, Output } from "@angular/core";
import { CalendarView } from "angular-calendar";
import { AuthService } from "@frontend/src/app/services/auth.service";
import { computeDate, getDefaultCalendarSettings } from "@frontend/src/app/utils/calendar.util";
import { CalendarViewEnum, HostingResponseModel, ReservationFilter } from "@app/model";
import { PlanningService } from "@frontend/src/app/services/planning.service";
import { EChartsOption } from "echarts";
import { generateDashboardColors, groupCharDataByDate } from "@frontend/src/app/utils/dashboard.util";
import { firstValueFrom } from "rxjs";
import { ExportService } from "@frontend/src/app/services/export.service";
import { saveAs } from "file-saver";
import { extractDistinctCustomer, formatCalendarViewTitle, isDateBetweenOther } from "@app/util";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent {
	get reportTitle(): string {
		return this._reportTitle;
	}
	get newCustomers(): HostingResponseModel["customer"][] {
		return this._newCustomers;
	}

	get distinctRooms(): number {
		return this._distinctRooms;
	}
	get distinctCustomers(): number {
		return this._distinctCustomers;
	}
	get totalAmount(): number {
		return this._totalAmount;
	}

	private _reportTitle: string;

	public isLoading = false;
	public readonly CalendarViewEnum = CalendarView;
	public currentView: CalendarView = CalendarView.Week;

	public viewDate: Date = new Date();

	public lineChartOption: EChartsOption = {};
	public barChartOption: EChartsOption | undefined;
	public pieChartOption: EChartsOption | undefined;

	private _reservations: HostingResponseModel[];

	private _newCustomers: HostingResponseModel["customer"][];

	private _totalAmount: number = 0;

	private _distinctCustomers: number = 0;
	private _distinctRooms: number = 0;

	private _startDate: Date;
	private _endDate: Date;

	public calendarSettings = getDefaultCalendarSettings();

	@Output()
		currentViewChange = new EventEmitter<CalendarView>();

	constructor(private authService: AuthService, private planningService: PlanningService, private exportService: ExportService) {
		this.authService.getCurrentUserFromApi()
			.subscribe(user => {
				this.currentView = user.calendarDefaultView as string as CalendarView;
			});
	}

	public async onChangeView(view: CalendarView) {
		this.currentView = view;
		this.updateStartAndEndDate();
		await this.renderCharts();
	}

	public async onUpdateDate(date: Date) {
		this.viewDate = date;
		this.updateStartAndEndDate();
		await this.renderCharts();
	}

	private updateStartAndEndDate() {
		const computeDates = computeDate(this.viewDate, this.currentView);
		this._startDate = computeDates.startDate;
		this._endDate = computeDates.endDate;
	}

	private async fetchData(): Promise<HostingResponseModel[]> {
		const filter: ReservationFilter = {
			start: this._startDate,
			end: this._endDate,
		};

		return await firstValueFrom(this.planningService.getReservations(filter));
	}

	private async renderCharts() {
		this.isLoading = true;
		this._reservations = await this.fetchData();
		this._reservations.filter(item=> item.status !== "CANCELLED");

		const customers = this._reservations.map(item=> item.customer)
			.filter(item=> isDateBetweenOther(item.createdAt, this._startDate, this._endDate));

		this._newCustomers = extractDistinctCustomer(customers);

		const dataByDate = groupCharDataByDate(this._reservations);
		this.initLineChar(dataByDate.dates, dataByDate.amounts);
		this.initPieCharData(dataByDate.dates, dataByDate.amounts);
		this.initBarChar(dataByDate.dates, dataByDate.amounts);

		this.createReportTitle();

		this._totalAmount = this.calculateTotalAmount(this._reservations);
		this._distinctCustomers = this.calculateDistinctCustomers(this._reservations);
		this._distinctRooms = this.calculateDistinctRooms(this._reservations);
		this.isLoading = false;
	}

	private initLineChar(categories: string[], values: number[]) {
		this.lineChartOption  = {
			xAxis: {
				type: "category",
				data: categories,
			},
			yAxis: {
				type: "value",
			},
			series: [
				{
					data: values,
					type: "line",
				},
			],
		};
	}

	private initPieCharData(categories: string[], values: number[]) {

		const textColor = "black";
		const backGroundColor = "white";

		const dataColors = generateDashboardColors(categories.length);
		const otherColors = generateDashboardColors(2);

		this.pieChartOption = {
			backgroundColor: backGroundColor,
			color: dataColors,
			tooltip: {
				trigger: "item",
				formatter: "{a} <br/>{b} : {c} ({d}%)",
			},
			legend: {
				orient: "vertical",
				left: "left",
				data: [...categories],
				textStyle: {
					color: textColor,
				},
			},
			series: [
				{
					name: "Jour",
					type: "pie",
					radius: "80%",
					center: ["50%", "50%"],
					data: [
						...categories.map((item, index) => ({ value: values[index], name: item  }))
					],
					itemStyle: {
						// @ts-ignore
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: otherColors[0],
						},
					},
					label: {
						// @ts-ignore
						normal: {
							textStyle: {
								color: textColor,
							},
						},
					},
				},
			],
		};
	}

	private initBarChar(categories: string[], data: number[]) {

		const backGroundColor = "white";
		const textColor = "black";
		const colors = generateDashboardColors(3);

		this.barChartOption = {
			backgroundColor: backGroundColor,
			color: [colors[2]],
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
			},
			grid: {
				left: "3%",
				right: "4%",
				bottom: "3%",
				containLabel: true,
			},
			xAxis: [
				{
					type: "category",
					data: categories,
					axisTick: {
						alignWithLabel: true,
					},
					axisLine: {
						lineStyle: {
							color: colors[0],
						},
					},
					axisLabel: {
						// @ts-ignore
						textStyle: {
							color: textColor,
						},
					},
				},
			],
			yAxis: [
				{
					type: "value",
					axisLine: {
						lineStyle: {
							color: colors[0],
						},
					},
					splitLine: {
						lineStyle: {
							color: colors[0],
						},
					},
					axisLabel: {
						// @ts-ignore
						textStyle: {
							color: textColor,
						},
					},
				},
			],
			series: [
				{
					name: "Score",
					type: "bar",
					barWidth: "60%",
					data: [...data],
				},
			],
		};
	}

	private calculateTotalAmount(data: HostingResponseModel[]): number {
		return data.reduce((prev, curr) => prev + curr.totalAmount, 0);
	}

	private calculateDistinctCustomers(data: HostingResponseModel[]): number {
		return new Set(data.map(item => item.customer.customerId)).size;
	}

	private calculateDistinctRooms(data: HostingResponseModel[]): number {
		return new Set(data.map(item => item.logement.logementId)).size;
	}

	get reservations() : HostingResponseModel[] {
		return this._reservations;
	}

	public async onExport() {
		const fileName = `${this._reportTitle.replace(" ", "_").toLowerCase()}.xlsx`;
		saveAs(await firstValueFrom(this.exportService.export({ start: this._startDate, end: this._endDate, filterView: this.currentView as string as CalendarViewEnum })), fileName);
	}

	private createReportTitle() {
		switch (this.currentView) {
			case CalendarView.Month:
				this._reportTitle = `RAPPORT MENSUEL - ${formatCalendarViewTitle(this.viewDate, this.currentView as string as CalendarViewEnum).toUpperCase()}`;
				break ;
			case CalendarView.Day:
				this._reportTitle = `RAPPORT DU ${formatCalendarViewTitle(this.viewDate, this.currentView as string as CalendarViewEnum).toUpperCase()}`;
				break;
			case CalendarView.Week:
				this._reportTitle = `RAPPORT PERIODIQUE DU ${formatCalendarViewTitle(this.viewDate, this.currentView as string as CalendarViewEnum).toUpperCase()}`;
				break;
			default:
				this._reportTitle = "";
		}
	}



}

