import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialogRef } from "@angular/material/dialog";
import { ErrorStateMatcher } from "@angular/material/core";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	selector: "app-reset-password-dialog",
	templateUrl: "./reset-password-dialog.component.html",
	styleUrl: "./reset-password-dialog.component.css"
})
export class ResetPasswordDialogComponent implements OnInit {

	passwordResetForm!: FormGroup;
	isLoading = false;
	matcher = new MyErrorStateMatcher();

	constructor(private authService: AuthService,
                public formBuilder: FormBuilder,
                private matSnackBar: MatSnackBar,
                private matDialogRef: MatDialogRef<any>) {
	}

	ngOnInit(): void {
		this.passwordResetForm = this.formBuilder.group({
			email: ["", [Validators.required, Validators.email]]
		});
	}

	resetPassword() {
		this.isLoading = true;
		this.authService.resetPassword(this.passwordResetForm.value).subscribe({
			next: ()=> {
				this.matSnackBar.open("Un nouveau mot de passe a été envoyé à cette adresse.", "Ok", {
					panelClass: "snack-bar-success",
					duration: 3000
				});
				this.matDialogRef.close();
			},
			error: (err) => {
				this.matSnackBar.open(err.error, undefined, { panelClass: "snack-bar-error", duration: 3000 });
				this.matDialogRef.close();
			}
		});
	}
}
