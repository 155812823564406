import { BookingBaseModel } from "./booking.base.model";

export interface BookingResponseModel extends BookingBaseModel {
    bookingId: string;
    confirmationDate: Date;
    status : BookingStatusEnum;
}

export enum BookingStatusEnum {
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    CANCELLED = "CANCELLED"
}