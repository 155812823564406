import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { WeeklyCalendarComponent } from "./components/calendar/weekly-calendar/weekly-calendar.component";
import { MonthlyCalendarComponent } from "./components/calendar/monthly-calendar/monthly-calendar.component";
import { DailyCalendarComponent } from "./components/calendar/daily-calendar/daily-calendar.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CustomDateFormatter } from "./components/calendar/custom-date-formatter.provider";
import { SelectFreeRoomDialogComponent } from "./components/select-free-room-dialog/select-free-room-dialog.component";
import { AppMaterialModule } from "../../app-material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BillComponent } from "./components/bill/bill.component";
import { RoomCardComponent } from "./components/room-card/room-card.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { DialogService } from "../../services/dialog.service";
import { CalendarComponent } from "./components/widget/calendar/calendar.component";
import { SpinnerComponent } from "./components/widget/spinner/spinner.component";
import { MultiSelectComponent } from "./components/widget/multi-select/multi-select.component";
import { MatRippleModule } from "@angular/material/core";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { DynamicFormComponent } from "@frontend/src/app/components/shared/components/dynamic-form/dynamic-form.component";
import { MatchPasswordDirective } from "@frontend/src/app/components/shared/directives/match-password.directive";
import {
	CalendarHeaderComponent
} from "@frontend/src/app/components/shared/components/widget/calendar/calendar-header/calendar-header.component";



@NgModule({
	declarations: [
		NavbarComponent,
		FooterComponent,
		WeeklyCalendarComponent,
		MonthlyCalendarComponent,
		DailyCalendarComponent,
		SelectFreeRoomDialogComponent,
		BillComponent,
		RoomCardComponent,
		ConfirmDialogComponent,
		CalendarComponent,
		SpinnerComponent,
		MultiSelectComponent,
		DynamicFormComponent,
		MatchPasswordDirective,
		CalendarHeaderComponent
	],
	imports: [
		CommonModule,
		RouterLink,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		AppMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		MatRippleModule,
		NgxMatDatetimePickerModule,
		RouterLinkActive,
	],
	exports: [
		NavbarComponent,
		FooterComponent,
		WeeklyCalendarComponent,
		MonthlyCalendarComponent,
		DailyCalendarComponent,
		CalendarModule,
		SelectFreeRoomDialogComponent,
		BillComponent,
		RoomCardComponent,
		CalendarComponent,
		SpinnerComponent,
		MultiSelectComponent,
		DynamicFormComponent,
		MatchPasswordDirective,
		CalendarHeaderComponent
	],
	providers: [
		CustomDateFormatter,
		DialogService
	]
})
export class SharedModule {
}
