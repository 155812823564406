import { Injectable } from "@angular/core";

import { map, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {
	CalendarDataModel,
	HostingResponseModel,
	ReservationFilter
} from "@app/model";
import { formatBookingForCalendar } from "@frontend/src/app/services/util";

@Injectable({
	providedIn: "root"
})

export class PlanningService {

	private readonly API_URL = environment.backendUrl;

	constructor(private httpClient: HttpClient) {

	}

	public getReservationsCalendar(filter: ReservationFilter): Observable<CalendarDataModel[]> {
		return this.getReservations(filter)
			.pipe(map(data => data.map(formatBookingForCalendar)));
	}

	public getReservations(filter: ReservationFilter): Observable<HostingResponseModel[]> {
		return this.httpClient.get<HostingResponseModel[]>(`${ this.API_URL }/api/v1/calendar/by_dates/filter`, {
			params: {
				...filter,
				start: filter.start.toString(),
				end: filter.end.toString()
			}
		});
	}
}
