<div class="navbar-container">
    <header *ngIf="currentUser" >
        <!-- Fixed navbar -->
        <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div class="container-fluid">
                <a class="navbar-brand" routerLink="/feature" *ngIf="currentHotel">
                    {{ currentHotel.name | uppercase }}
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav me-auto mb-2 mb-md-0">
                        <li class="nav-item" *ngIf="currentUser.roles.includes('ADMIN') || currentUser.roles.includes('SUPERADMIN')">
                            <a class="nav-link" routerLink="/feature/dashboard" routerLinkActive="active">
                                Dashboard
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/feature/planning" routerLinkActive="active">
                                Planning
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link"
                               routerLink="/feature/reservation" routerLinkActive="active">
                                Réservation
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="false">
                            <a class="nav-link"
                               routerLink="/feature/restaurant" routerLinkActive="active">Restauration</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link clickable"
                               routerLink="/feature/customers" routerLinkActive="active">Clients</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link clickable"
                               routerLink="/feature/rooms" routerLinkActive="active">Chambres</a>
                        </li>

                        <li class="nav-item"
                            *ngIf="currentUser.roles.includes('ADMIN') || currentUser.roles.includes('SUPERADMIN')">
                            <a class="nav-link" routerLink="/feature/users" routerLinkActive="active">Utilisateurs</a>
                        </li>
                    </ul>

                    <div>
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <img *ngIf="!!profileImage; else defaultProfileImage"
                                         [src]="profileImage" width="30" height="30" class="avatar rounded-circle"
                                         alt="loading">
                                    {{ currentUser.lastName }} {{ currentUser.firstName }}<b class="caret"></b>
                                    <ng-template #defaultProfileImage>
                                        <img [src]="DEFAULT_USER_PROFILE_PICTURE"
                                             width="30" height="30" class="avatar rounded-circle" alt="Avatar">
                                    </ng-template>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <a class="dropdown-item d-flex align-items-center gap-2"
                                           routerLink="/feature/user-profile">
                                            <mat-icon>account_circle</mat-icon>
                                            Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item d-flex align-items-center gap-2"
                                           routerLink="/feature/hotel-config"
                                           *ngIf="currentUser.roles.includes('ADMIN') || currentUser.roles.includes('SUPERADMIN')">
                                            <mat-icon>settings</mat-icon>
                                            Hotel Config
                                        </a>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li>
                                        <a class="dropdown-item d-flex align-items-center gap-2 clickable"
                                           (click)="onLogout()">
                                            <mat-icon>logout</mat-icon>
                                            Déconnexion
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</div>