import { Directive, Input } from "@angular/core";
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validators } from "@angular/forms";
import Validation from "@frontend/src/app/utils/validator-password";

@Directive({
	selector: "[fidoInterWorkspaceMatchPassword]",
	providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }]
})
export class MatchPasswordDirective implements Validators {

    @Input("fidoInterWorkspaceMatchPassword") matchPassword: string[] = [];

    constructor() {
    }

    validate(formGroup: FormGroup): ValidationErrors | null {
    	return Validation.match(this.matchPassword[0], this.matchPassword[1])(formGroup);
    }
}
