<div class="container mt-2">
    <div class="row align-items-center">
        <div class="col-md-3">
            <div class="mb-3">
                <h5 class="card-title">Liste des clients
                    <span class="text-muted fw-normal ms-2">({{ customerPage?.totalRows }})</span>
                </h5>
            </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center align-content-center gap-2">
            <div class="form-group">
                <input class="form-control" [(ngModel)]="searchText">
            </div>
            <button mat-icon-button class="primary-color"
                    (click)="onSearch()">
                <mat-icon>search</mat-icon>
            </button>
        </div>

        <div class="col-md-3">
            <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div>
                    <a class="btn add-btn d-flex align-items-center text-white"
                       (click)="onOpenNewCustomerDialog()">
                        <mat-icon>add</mat-icon>
                        Ajouter
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="customerPage?.content?.length">
        <div class="row mt-3">
            <div class="col-xl-3 col-sm-6" *ngFor="let customer of customerPage.content">
                <div class="card">
                    <div class="card-body">
                        <div class="dropdown float-end">
                            <a class="text-muted dropdown-toggle font-size-16" href="#" role="button"
                               data-bs-toggle="dropdown" aria-haspopup="true">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a class="dropdown-item clickable d-flex align-items-center gap-2"
                                   (click)="onOpenCustomerEditDialog(customer)">
                                    <mat-icon class="primary-color">edit</mat-icon>
                                    Modifier</a>
                                <hr>
                                <a class="dropdown-item clickable d-flex align-items-center gap-2"
                                   *ngIf="currentUser?.roles?.includes('ADMIN') || currentUser?.roles?.includes('SUPERADMIN')">
                                    <mat-icon class="text-danger">delete</mat-icon>
                                    Supprimer
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div>
                                <img [src]="customer.imageUrl"
                                     alt="loading" class="avatar-md rounded-circle img-thumbnail"
                                     height="60" width="60"/>
                            </div>
                            <div class="flex-1 ms-3">
                                <h5 class="font-size-16 mb-1">
                                    <a class="text-dark clickable" (click)="$event.stopPropagation()">
                                        {{ customer.lastName + ' ' + customer.firstName }}
                                    </a>
                                </h5>
                            </div>
                        </div>
                        <div class="mt-3 pt-1">
                            <p class="text-muted mb-0 d-flex gap-2">
                                <mat-icon class="pe-2 font-size-15 align-middle primary-color">
                                    phone_in_talk
                                </mat-icon>
                                {{ customer.contact }}
                            </p>

                            <p class="text-muted mb-0 mt-2 d-flex gap-2">
                                <mat-icon class="primary-color pe-2 font-size-15 align-middle">
                                    mail
                                </mat-icon>
                                {{ customer?.email || '-' }}
                            </p>

                            <p class="text-muted mb-0 mt-2 d-flex gap-2">
                                <mat-icon class="primary-color font-size-15 align-middle pe-2">
                                    fingerprint
                                </mat-icon>
                                {{ customer?.cni || 'CNI non renseigné' }}
                            </p>

                            <p class="text-muted mb-0 mt-2 d-flex gap-2">
                                <mat-icon class="primary-color font-size-15 align-middle pe-2">
                                    calendar_month
                                </mat-icon>
                                {{ customer?.birthDate ? (customer.birthDate  | date: 'dd/MM/yyyy') : 'Non renseigné' }}
                            </p>
                        </div>
                        <div class="d-flex mt-3 justify-content-between align-items-center">
                            <button type="button" class="btn btn-soft-primary btn-sm d-flex align-items-center gap-1">
                                <mat-icon class="primary-color align-middle me-1">person</mat-icon>
                                Profile
                            </button>
                            <button type="button" class="btn btn-primary btn-sm d-flex align-items-center gap-2">
                                <mat-icon>sms</mat-icon>
                                Contact
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-0 align-items-center pb-4">
            <div class="col-sm-6">
                <div>
                    <p class="mb-sm-0">Affichage de {{ customerPage.page + 1 }} à {{ customerPage.content?.length }}
                        sur {{ customerPage?.totalRows }}</p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="float-sm-end">
                    <mat-paginator [length]="customerPage.totalRows"
                                   [pageSize]="pageSize"
                                   [pageSizeOptions]="[12, 24, 36, 48]"
                                   (page)="handlePageEvent($event)"
                                   [showFirstLastButtons]="true">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>
</div>