import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-select-hotel-dialog",
	templateUrl: "./select-hotel-dialog.component.html",
	styleUrl: "./select-hotel-dialog.component.css"
})
export class SelectHotelDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public hotels: {hotelId: number, name: string}[]) {
	}

}
