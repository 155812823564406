<div class="dialog-title">
    <div class="title">
        <h1 mat-dialog-title> Sélection du logement </h1>
    </div>

    <div class="close">
        <button mat-dialog-close mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div class="row">
        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label> Début à</mat-label>
                <input matInput [formControl]="startDateControl"
                       [min]="startDateControl"
                       [ngxMatDatetimePicker]="pickerFrom"
                       (dateChange)="onResetData()">
                <mat-datepicker-toggle matSuffix [for]="$any(pickerFrom)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerFrom></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label> Fin à</mat-label>
                <input matInput [formControl]="endDateControl"
                       [min]="startDateControl"
                       [ngxMatDatetimePicker]="pickerTo"
                       (dateChange)="onResetData()">
                <mat-datepicker-toggle matSuffix [for]="$any(pickerTo)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerTo></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field appearance="outline">
                <mat-label> Type</mat-label>
                <mat-select [formControl]="loadgingType" (selectionChange)="onClearData()">
                    <mat-option [value]="LoadgingTypeEnum.CHAMBRE">Chambre</mat-option>
                    <mat-option [value]="LoadgingTypeEnum.SUITE">Suite</mat-option>
                    <mat-option [value]="LoadgingTypeEnum.SALLE_DE_FETE"
                                [disabled]="data.mode === 'SIESTE'">
                        Salle de fête
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="content">
        <div class="mt-2">
            <a mat-raised-button class="search-btn"
               [disabled]="!isDateValid()"
               (click)="search()">
                <mat-icon>search</mat-icon>
                Rechercher
            </a>
        </div>

        <div class="mt-2" *ngIf="logements">
            <ol class="list-group list-group-numbered element">
                <li class="list-group-item d-flex justify-content-between align-items-start element room-row"
                    [ngClass]="{'disabled': logement.typeLogement === 'SALLE_DE_FETE' && data.mode === 'SIESTE'}"
                    *ngFor="let logement of logements"
                    [mat-dialog-close]="{selectedLogement: logement, logements, startDate: startDateControl.value, endDate: endDateControl.value}">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">{{ logement.code }}</div>
                        <div class="fw-bold">{{ logement.name }}</div>
                        <div class="fw-bold">{{ logement.niveau.code }}</div>
                    </div>
                    <span class="badge bg-primary rounded-pill">{{ logement.defaultPrice | number:  '0.0' }}</span>
                    <span class="ml-3 badge bg-primary rounded-pill" *ngIf="logement.typeLogement !== 'SALLE_DE_FETE'">
                        {{ logement.napAmount | number:  '0.0' }}
                    </span>
                </li>
            </ol>
        </div>
    </div>
</div>