import { Component, OnInit } from "@angular/core";
import { CustomerService } from "../../../../services/customer.service";
import { CustomerResponse, PageableModel, UserProfileResponse } from "@app/model";
import { MatDialog } from "@angular/material/dialog";
import { NewCustomerDialogComponent } from "../dialog/new-customer-dialog/new-customer-dialog.component";
import { PageEvent } from "@angular/material/paginator";
import { AuthService } from "../../../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "app-customers",
	templateUrl: "./customers.component.html",
	styleUrl: "./customers.component.scss"
})

export class CustomersComponent implements OnInit {
	get isLoading(): boolean {
		return this._isLoading;
	}

	get currentUser(): UserProfileResponse {
		return this._currentUser;
	}

	public customerPage: PageableModel<CustomerResponse>;

	public currentPage = 0;
	public pageSize: number = 12;
	private _currentUser: UserProfileResponse;
	public searchText: string;
	private _isLoading: boolean;

	constructor(private customerService: CustomerService,
                private matDialog: MatDialog,
                private authService: AuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.authService.getCurrentUserFromApi()
			.subscribe(_user => this._currentUser = _user);

		this.activatedRoute.queryParamMap.subscribe(params=> {
			this.currentPage = +params.get("page") || 0;
			this.pageSize = +params.get("size") || 12;

			this.searchText = params.get("label");

			this.customerService.getPageableCustomers(this.currentPage, this.pageSize, this.searchText)
				.subscribe(_customerPage => {
					this.customerPage = _customerPage;
				});
		});
	}

	public goToPage(page: number) {
		this.currentPage = page;
		this.searchCustomers();
	}

	public onOpenNewCustomerDialog() {
		this.matDialog.open(NewCustomerDialogComponent, {
			width: "700px",
			maxHeight: "90vh"
		}).afterClosed().subscribe({
			next: () => {
				this.currentPage = 0;
				this.goToPage(this.currentPage);
			}
		});
	}

	public onOpenCustomerEditDialog(customer: CustomerResponse) {
		this.matDialog.open(NewCustomerDialogComponent, {
			data: customer,
			width: "700px",
			maxHeight: "90vh"
		}).afterClosed().subscribe((data: CustomerResponse) => {
			if (data) {
				this.customerPage.content = this.customerPage.content.map(item => {
					if (item.customerId === data.customerId)
						return data;
					else
						return item;
				});
			}
		});
	}


	public handlePageEvent($event: PageEvent) {
		if ($event.pageIndex !== this.currentPage) {
			this.goToPage($event.pageIndex);
		} else {
			const queryParams = { ...this.activatedRoute.snapshot.queryParams };
			queryParams["size"] = $event.pageSize;
			queryParams["page"] = 0;
			this.router.navigate([], { queryParams });
		}
	}

	public onSearch() {
		this.currentPage = 0;
		this.searchCustomers();
	}

	private searchCustomers() {
		this._isLoading = true;
		const queryParams = { ...this.activatedRoute.snapshot.queryParams };
		queryParams["page"] = this.currentPage;
		if (this.searchText)
			queryParams["label"] = this.searchText;
		else
			delete queryParams["label"];

		this.router.navigate([], { queryParams });
		this._isLoading = false;
	}
}