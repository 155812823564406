import { BookingStatusEnum, CalendarDataModel, HostingResponseModel } from "@app/model";
import { formatTitleForDashboard, getBookingStatusColor } from "@app/util";

export function formatBookingForCalendar(booking: HostingResponseModel): CalendarDataModel {
	return {
		id: booking.hostingId,
		title: formatTitleForDashboard(booking.customer?.firstName, booking.customer?.lastName, booking.logement?.code),
		start: new Date(booking.beginingDate),
		end: new Date(booking.endingDate),
		duration: new Date(booking.endingDate).getTime() - new Date(booking.beginingDate).getTime(),
		label: `${booking.customer.lastName.toUpperCase()} ${(booking.customer?.firstName || "").toUpperCase()}`,
		printDate: new Date(booking.beginingDate)
			.toLocaleDateString("fr-FR", {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit"
			}),
		cssClass: getBookingStatusColor(booking.status as BookingStatusEnum),
		allDay: false,
		color: {
			primary: "black",
			secondary: "gray"
		},
		customer: {
			customerId: booking.customer.customerId,
			firstName: booking.customer?.firstName || "",
			lastName: booking.customer?.lastName,
			contact: booking.customer?.contact || ""
		},
		logement: {
			logementId: booking.logement.logementId,
			code: booking.logement.code,
			name: booking.logement.name
		}
	};
}