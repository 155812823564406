<h2 mat-dialog-title>Réinitialisation du mot de passe</h2>
<form (ngSubmit)="resetPassword()" [formGroup]="passwordResetForm" class="example-form">
    <div mat-dialog-content>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"
                   placeholder="Ex. pat@example.com">
            <mat-error
                    *ngIf="passwordResetForm.get('email').hasError('email') && !passwordResetForm.get('email').hasError('required')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="passwordResetForm.get('email').hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button type="submit" class="float-end" [disabled]="!passwordResetForm.valid" mat-raised-button color="primary">
            Send
        </button>
    </div>
</form>