import { BinaryObject } from "./file.model";

export enum RoomTypeEnum {
    "CHAMBRE" = "CHAMBRE",
    "SUITE" = "SUITE",
    "SALLE_DE_FETE" = "SALLE_DE_FETE"
}

export type RoomPictureApiResponse = {
    pictureId?: string
    roomId?: number,
    isCover?: boolean
} & BinaryObject;
