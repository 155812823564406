export function setDayStartAndEnd(startDate: Date | string, endDate: Date | string): { start: Date, end: Date } {
	const start = new Date(startDate);
	const end = new Date(endDate);

	start.setHours(0, 0, 0, 0);
	end.setHours(23, 59, 59, 999);

	return { start, end };
}

export function isDateBetweenOther(requestDate: Date | string, start: Date | string, end: Date | string): boolean {
	const dateRange = setDayStartAndEnd(start, end);

	const date = new Date(requestDate);

	return date.getTime() >= dateRange.start.getTime() && date.getTime() <= dateRange.end.getTime();
}