import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

export const DEFAULT_USER_PROFILE_PICTURE = "https://upload.wikimedia.org/wikipedia/commons/6/67/User_Avatar.png";

export const DEFAULT_ROOM_COVER_IMAGE = "https://www.frontendfreecode.com/img/master-suite-01.jpg";

export function getPictureUrlFromBlob(blob: Blob, domSanitizer: DomSanitizer): SafeUrl {
	return domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
}

export function getPictureUrlFromByteArray(binary: number[], domSanitizer: DomSanitizer): SafeUrl {
	return domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(new Blob([new Uint8Array(binary).buffer])));
}