import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";
import { HotelResponse, UserProfileResponse } from "@app/model";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DEFAULT_USER_PROFILE_PICTURE, getPictureUrlFromByteArray } from "../../../../utils/file.utils";
import { HotelService } from "@frontend/src/app/services/hotel.service";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrl: "./navbar.component.scss",
})
export class NavbarComponent implements OnInit {
	public profileImage: SafeUrl;

	public currentUser: UserProfileResponse;
	public readonly DEFAULT_USER_PROFILE_PICTURE = DEFAULT_USER_PROFILE_PICTURE;
	private _currentHotel: HotelResponse;

	constructor(private authService: AuthService,
				private hotelService: HotelService,
                private domSanitizer: DomSanitizer) {
	}

	ngOnInit(): void {
		this.authService.getCurrentUserFromApi()
			.subscribe(_user => {
				this.currentUser = _user;
				if (this.currentUser.profileImage) {
					this.profileImage = getPictureUrlFromByteArray(_user.profileImage.data, this.domSanitizer);
				}
			});

		this.hotelService.getSessionHotel().subscribe(response=> this._currentHotel = response);
	}

	public onLogout() {
		this.authService.logout();
	}

	get currentHotel(): HotelResponse {
		return this._currentHotel;
	}
}
