import { CalendarDateFormatter, DateFormatterParams } from "angular-calendar";
import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { format, endOfWeek, startOfWeek } from "date-fns";
import * as fnsLocale from "date-fns/locale";

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
	// you can override any of the methods defined in the parent class

	public override dayViewHour({ date, locale }: DateFormatterParams): string {
		return formatDate(date, "HH:mm", locale);
	}

	public override weekViewHour({ date, locale }: DateFormatterParams): string {
		return this.dayViewHour({ date, locale });
	}

	public override weekViewTitle({ date, locale, weekStartsOn, excludeDays, daysInWeek, }): string {
		return `${format(startOfWeek(date), "dd MMMM", { locale: fnsLocale[locale] })} - ${format(endOfWeek(date), "dd MMMM", { locale: fnsLocale[locale] })}`;
	}
}
