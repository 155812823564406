import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { CustomHttpResponse } from "../model/state/custom-http-reponse";

export function handleError(error: HttpErrorResponse): Observable<never> {
	let errorMessage: string;
	if (error.error instanceof ErrorEvent) {
		errorMessage = `A client error occured - ${ error.error.message }`;
	} else {
		if (error.error.reason)
			errorMessage = error.error.reason;
		else
			errorMessage = `An error occured - Error status ${ error.status }`;
	}
	return throwError(() => errorMessage);
}

export function convertToCustomHttpResponse<T>(data: T, message: string, status?: string, statusCode?: number): CustomHttpResponse<T> {
	return {
		data,
		message,
		statusCode: statusCode ? statusCode : 200,
		status: status ? status : "OK",
		timestamp: new Date()
	};
}