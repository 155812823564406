import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "feature"
	},
	{
		path: "feature",
		loadChildren: () => import("./components/feature/feature-routing.module").then(m => m.FeatureRoutingModule)
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {

}