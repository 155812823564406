import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CalendarView, } from "angular-calendar";

@Component({
	selector: "app-calendar-header",
	templateUrl: "./calendar-header.component.html",
	styleUrls: [
		"../calendar-styles-override.scss" ,
		"./calendar-header.component.scss",
		"../calendar.component.scss"
	],
})

export class CalendarHeaderComponent {
	readonly CalendarViewEnum = CalendarView;

	@Input()
	public isLoading: boolean;

	@Input({ required: true })
	public currentView: CalendarView;

	private _viewDate: Date;

	@Output() viewDateChange = new EventEmitter<Date>();

	@Input()
	set viewDate(date: Date) {
		if (date !== this._viewDate) {
			this._viewDate = date;
			this.viewDateChange.emit(this._viewDate);
		}
	}

	get viewDate(): Date {
		return this._viewDate;
	}

	@Output()
	currentViewChange = new EventEmitter<CalendarView>();

	@Output()
	closeMonthView = new EventEmitter<void>();

	public setView(view: CalendarView) {
		this.currentView = view;
		this.currentViewChange.emit(view);
	}

	public closeOpenMonthViewDay() {
		this.closeMonthView.emit();
	}
}
