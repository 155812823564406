import { Nullable } from "@app/model";

export interface IForm {
    formTitle: string;
    saveBtnTitle: string;
    resetBtnTitle: string;
    formControls: IFormControl[];
}

export interface IFormControl {
    type: FormControlEnum;
    controlName: string;
    label: string;
    placeHolder?: Nullable<string>;
    value?: Nullable<string | number | Date | boolean>;
    class: string;
    validators?: IFormControlValidator[];
    options?: IOption[];
    isVisible?: boolean;
}

export enum FormControlEnum {
    SELECT = "select",
    TEXT = "text",
    RADIO = "radio",
    DATE = "date",
    NUMBER = "number",
    EMAIL = "email",
    CHECKED = "checked"
}

export interface IFormControlValidator {
    name: ValidatorType;
    required?: boolean;
    message: string;

    minLength?: number;
    maxLength?: number;
    pattern?: string;
}

export enum ValidatorType {
    MAX_LENGTH = "maxlength",
    MIN_LENGTH = "minlength",
    REQUIRED = "required",
    EMAIL = "email",
    PATTERN = "pattern"
}

interface IOption {
    id?: number | string;
    value?: string;
}
