import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "./components/auth/auth.module";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { FeatureModule } from "./components/feature/feature.module";

import localeFr from "@angular/common/locales/fr";
import { CoreModule } from "./core/core.module";
import {
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { AppMaterialModule } from "./app-material.module";
import { ChartsModule } from "@frontend/src/app/components/charts/charts.module";

registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		CoreModule,
		AppRoutingModule,
		AuthModule,
		FeatureModule,
		MatDatepickerModule,
		MatInputModule,
		AppMaterialModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMatNativeDateModule,
		ChartsModule
	],
	providers: [
		DatePipe
	],
	bootstrap: [AppComponent]
})

export class AppModule {

}