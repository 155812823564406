<div class="overflow-hidden card table-nowrap table-card">

    <div class="table-responsive">
        <table class="table mb-0">
            <thead class="small text-uppercase bg-body text-muted">
            <tr>
                <th>N°</th>
                <th>Date</th>
                <th>Nom(s) & Prénom(s)</th>
                <th>Contact</th>
                <th>Ville</th>
            </tr>
            </thead>
            <tbody>

            <tr class="align-middle" *ngFor="let item of customers; let index = index">

                <td>{{ index + 1 }} </td>

                <td>
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="h6 mb-0 lh-1">{{ item.createdAt | date: 'dd/MM/yyyy' }}</div>
                        </div>
                    </div>
                </td>

                <td>{{ nameFormatter(item.firstName, item.lastName)}} </td>

                <td>
                    <span class="d-inline-block align-middle"> {{ item.contact }}</span>
                </td>

                <td>
                    <span> {{ item.city }} </span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>