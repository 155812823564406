<section>
    <div class="container">
        <div class="row justify-content-center my-5">
            <div class="col-md-6" style="margin-top: 50px;">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center">
                            <h2>FIDO INTER HOTEL</h2>
                            <h5 class="card-title mt-4 mb-5">Account Login</h5>
                        </div>
                        <div *ngIf="(loginState$ | async).dataState === DataState.ERROR"
                             class="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            {{ (loginState$ | async).error }}
                            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-outline mb-4">
                                <label class="form-label" for="email">Addresse email</label>
                                <input id="email"
                                       type="email" class="form-control"
                                       formControlName="email" />
                            </div>
                            <div class="form-outline mb-4">
                                <label for="password" class="form-label">Mot de passe</label>
                                <input id="password"
                                       type="password"
                                       class="form-control"
                                       formControlName="password" />
                            </div>
                            <div class="d-flex justify-content-center mb-4">
                                <button [disabled]="(loginState$ | async).dataState === DataState.LOADING || loginForm.invalid || loginForm.pristine"
                                        type="submit" class="btn btn-primary d-flex align-items-center gap-2">
                                    <span *ngIf="(loginState$ | async).dataState === DataState.LOADING"
                                          class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-right: 5px;"></span>
                                    <span *ngIf="(loginState$ | async).dataState === DataState.LOADING">Loading...</span>
                                    <mat-icon *ngIf="!((loginState$ | async).dataState === DataState.LOADING)">login</mat-icon>
                                    <span *ngIf="!((loginState$ | async).dataState === DataState.LOADING)">Connexion</span>
                                </button>
                            </div>
                        </form>
                        <div class="row mb-4 d-flex">
                            <div class="col">
                                <a [ngStyle]="{'pointer-events': (loginState$ | async).dataState === DataState.LOADING ? 'none': ''}"
                                   routerLink="/resetpassword">Mot de passe oublié?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
