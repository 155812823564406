import { Component, Input } from "@angular/core";
import { HostingResponseModel } from "@app/model";
import { nameFormatter } from "@app/util";


@Component({
	selector: "app-customer-table",
	templateUrl: "./customer-table.component.html",
	styleUrl: "./customer-table.component.scss",
})
export class CustomerTableComponent {

	nameFormatter = nameFormatter;

	get customers(): HostingResponseModel["customer"][] {
		return this._customers;
	}

	private _customers: HostingResponseModel["customer"][];

  @Input({ required: true })
	set customers(customers: HostingResponseModel["customer"][]) {
		this._customers = customers;
	}
}
