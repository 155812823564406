import { Component, Input, OnInit } from "@angular/core";
import { HotelService } from "../../../../services/hotel.service";
import { HostingResponseModel, HotelResponse } from "@app/model";
import { jsPDF as pdf } from "jspdf";

@Component({
	selector: "app-bill",
	templateUrl: "./bill.component.html",
	styleUrl: "./bill.component.scss"
})
export class BillComponent implements OnInit {

	public hotel!: HotelResponse;

    @Input()
	public reservation: HostingResponseModel;

	constructor(private hotelService: HotelService) {
	}

	ngOnInit(): void {
    	this.hotelService.getSessionHotel()
    		.subscribe(_hotels => this.hotel = _hotels);
	}

	public exportAsPDF(): void {
    	const fileName = `FACTURE_${ this.reservation.billNumber }.pdf`;
    	const doc = new pdf();

    	doc.html(document.getElementById("invoice"), {
    		margin: 5, windowWidth: 1000, width: 200, callback: (invoice) => invoice.save(fileName)
    	});
	}
}
