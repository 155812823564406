<ng-container *ngIf="form">
    <div class="dialog-title">
        <div class="title">
            <h1 mat-dialog-title *ngIf="data; else titleTemplate">Modifier un client</h1>
        </div>

        <ng-template #titleTemplate>
            <div class="title">
                <h1 mat-dialog-title>Ajouter un client</h1>
            </div>
        </ng-template>

        <div class="close">
            <button [mat-dialog-close]="false" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <ng-container *ngIf="true">
        <app-dynamic-form
                [formData]="form"
                (submitEvent)="onSaveCustomer($event)">
        </app-dynamic-form>
    </ng-container>
</ng-container>