import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HotelResponse, Nullable, UserApiResponse } from "@app/model";

import { FormControl, Validators } from "@angular/forms";
import { MetaFormGroup } from "../../../../../utils/form.utils";
import { firstValueFrom } from "rxjs";
import { UserService } from "../../../../../services/user.service";
import { HotelService } from "../../../../../services/hotel.service";
import { RoleModel } from "../../../../../model/role.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getSessionData } from "../../../../../utils/storage.util";
import { environment } from "@frontend/src/environments/environment";
import { NewUserBaseForm, NewUserForm } from "@frontend/src/app/forms/user.form";


@Component({
	selector: "app-new-user-dialog",
	templateUrl: "./new-user-dialog.component.html",
	styleUrl: "./new-user-dialog.component.scss"
})
export class NewUserDialogComponent implements OnInit {

	public userForm: NewUserForm;
	public roles: RoleModel[];
	public hotels: HotelResponse[];
	public hide = true;

	public constructor(@Inject(MAT_DIALOG_DATA) public data: UserApiResponse,
                       private userService: UserService,
                       private hotelService: HotelService,
                       private matSnackBar: MatSnackBar,
                       private matDialogRef: MatDialogRef<NewUserDialogComponent>) {
	}


	async ngOnInit(): Promise<void> {
		this.roles = await firstValueFrom(this.userService.getRoleSubs());
		this.hotels = await firstValueFrom(this.hotelService.getHotelsSub());

		if (this.data)
			this.initForm(this.data);
		else
			this.initForm();
	}

	public initForm(user?: UserApiResponse) {
		this.userForm = new MetaFormGroup<NewUserBaseForm, {
            id?: number;
            savedValue: UserApiResponse;
            isEdit: boolean;
            isCreated: boolean
        }>(
        	{
        		username: new FormControl<Nullable<string>>(user?.username, { nonNullable: true, validators: Validators.required }),
        		lastName: new FormControl<Nullable<string>>(user?.lastName, { nonNullable: true, validators: Validators.required }),
        		firstName: new FormControl<Nullable<string>>(user?.firstName, {
        			nonNullable: true,
        			validators: Validators.required
        		}),
        		email: new FormControl<Nullable<string>>(user?.email, { nonNullable: true, validators: [Validators.required, Validators.email] }),
        		password: new FormControl<string>("", { validators: [Validators.required, Validators.minLength(6)] }),
        		passwordConfirmation: new FormControl<string>("", { validators: [Validators.required, Validators.minLength(6)] }),
        		roles: new FormControl<number[]>(user?.roles?.length ? user.roles.map(item => item.roleId) : this.roles.filter(item => item.name === "USER").map(item => item.roleId), {
        			nonNullable: true,
        			validators: Validators.required
        		}),
        		hotels: new FormControl<number[]>(user?.hotels?.map(item => item.hotelId) || [Number(getSessionData(environment.hotelId))], {
        			nonNullable: true,
        			validators: Validators.required
        		}),
        	}, {
        		id: user?.userId,
        		savedValue: user,
        		isCreated: !!user,
        		isEdit: !user,
        	}
        );

		if (this.data) {
			this.userForm.controls.password.removeValidators(Validators.required);
			this.userForm.controls.passwordConfirmation.removeValidators(Validators.required);
		}
	}

	get f() {
		return this.userForm.controls;
	}

	public onSaveUser() {

		if (this.f.password.value !== this.f.passwordConfirmation.value) {
			this.showError("Les deux mot de passe ne sont pas identiques");
			return;
		}

		if (!this.data)
			this.userService.saveUser(this.userForm.getRawValue())
				.subscribe({
					next: (response) => {
						this.showSuccessMessage("Utilisateur créé avec succès");
						this.matDialogRef.close(response);
					},
					error: (err) => {
						this.showError(err.error.message);
					}
				});
		else
			this.userService.updateUser(this.data.userId, this.userForm.getRawValue())
				.subscribe({
					next: (response) => {
						this.showSuccessMessage("Utilisateur modifié avec succès");
						this.matDialogRef.close(response);
					},
					error: (err) => {
						this.showError(err.error.message);
					}
				});
	}

	public getHotelName(id: number): string | null {
		const index = this.hotels.findIndex(item => item.hotelId === id);
		if (index !== -1)
			return this.hotels[index]?.name;
		return null;
	}

	public getRoleName(id: number): string | null {
		const index = this.roles.findIndex(item => item.roleId === id);
		if (index !== -1)
			return this.roles[index].name;
		return null;
	}

	private showError(errorMessage: string, duration?: number) {
		this.matSnackBar.open(errorMessage, undefined, {
			duration: duration || 5000,
			panelClass: "snack-bar-error"
		});
	}

	private showSuccessMessage(message: string, duration?: number) {
		this.matSnackBar.open(message, undefined, {
			duration: duration || 3000,
			panelClass: "snack-bar-success"
		});
	}
}
