import { CalendarView } from "angular-calendar";
import { CalendarSettings } from "@app/model";

export function computeDate(day: Date, view: CalendarView): { startDate: Date, endDate: Date } {
	const startDate = new Date(day);
	const endDate = new Date(day);

	switch (view) {
		case CalendarView.Day:
			break;

		case CalendarView.Week: {
			// For Week view, set startDate to the previous Monday and endDate to the following Sunday
			const dayOfWeek = day.getDay(); // 0 (Sun) to 6 (Sat)
			const startOffset = (dayOfWeek === 0 ? -6 : 1 - dayOfWeek); // Calculate days to subtract to get to Monday
			const endOffset = (dayOfWeek === 0 ? 0 : 7 - dayOfWeek); // Calculate days to add to get to Sunday

			startDate.setDate(day.getDate() + startOffset);
			endDate.setDate(day.getDate() + endOffset);
			break;
		}

		case CalendarView.Month: {
			// For Month view, set startDate to the first day of the month and endDate to the last day of the month
			startDate.setDate(1);
			endDate.setMonth(day.getMonth() + 1);
			endDate.setDate(0); // Setting date to 0 sets it to the last day of the previous month
			break;
		}

		default:
			throw new Error("Invalid view type");
	}

	return { startDate, endDate };
}

export function getDefaultCalendarSettings(hourSegment: number = 2): CalendarSettings {
	return  {
		hourSegments: hourSegment,
		hourSegmentHeight: 25,
		dayStartHour: 0,
		dayEndHour: 24,
		weekStartsOn: 1,
		locale: "fr",
		tooltipDelay: 300,
		excludeDays: [0, 7]
	};
}